import { Button, Card, Grid, Icon, Theme, Typography, withStyles, WithStyles, withTheme } from '@material-ui/core';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { IAddress } from '@pbl/pbl-react-core/lib/models/forms/types';
import { StepperSteps } from '@pbl/pbl-react-core/lib/models/user-registration/types';
import { RegistrationService } from '@pbl/pbl-react-core/lib/services/registration-service';
import styles from 'assets/jss/modules/user-registration/UserRegistrationScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import { nameValidationRegex, registrationFields, zipcodeValidationRegex } from 'config/registration';
import AccountReactivateDialog from 'modules/authentication/login/components/AccountReactivateDialog';
import AccountReactivateEmailSentDialog from 'modules/authentication/login/components/AccountReactivateEmailSentDialog';
import AlreadyLoginInvitation from 'modules/user-registration/components/AlreadyLoginInvitation';
import Confirmation from 'modules/user-registration/components/Confirmation';
import PersonalInformation from 'modules/user-registration/components/PersonalInformation';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { reactivateAccount } from 'redux/reducers/account-reactivation/actions';
import { getCities, getStates, resetCities, resetStates } from 'redux/reducers/address/actions';
import { clearLoading, clearMessages, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import {
  checkIfEmailInUse,
  registerPlayer,
  reset,
  resetAdditionalInformation,
  updateCaptchaVisibility,
  updateInviteCodeVisibility,
  updateStepper,
  updateUserRegistrationField
} from 'redux/reducers/user-registration/action';
import ReCaptcha from 'shared/components/input/ReCaptcha';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { AuthService } from 'utils/auth-service';
import { scrollToTheTop } from 'utils/htmlUtil';
import AccountCredentials from './components/AccountCredentials';
import RegistrationStepper from './components/RegistrationStepper';

type PropsConnected = ConnectedProps<typeof connector>;

interface IMatchParams {
  inviteCode?: string;
}

interface IState {
  tosChecked: boolean;
  address: IAddress;
  reactivationEmailDialog: boolean;
}

interface IProps extends RouteComponentProps<IMatchParams>, WithStyles<typeof styles>, ThemedComponentProps, PropsConnected {
  theme: Theme;
}

class UserRegistrationScreen extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      tosChecked: false,
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        countryCode: '',
        state: '',
        zipCode: '',
        apartmentNumber: ''
      },
      reactivationEmailDialog: false
    };
  }
  public componentDidMount = async () => {
    document.title = `${constants.PROJECT_NAME} - Register`;
    await this.resetState();

    const {
      match: { params }
    } = this.props;
    await this.props.isAuthenticated();
    if (params) {
      const inviteCode = params.inviteCode;
      if (inviteCode) {
        await this.props.updateUserRegistrationField('inviteCode', inviteCode);
        await this.props.updateInviteCodeVisibility(true);
      }
    }
  };

  public componentWillUnmount = async () => {
    await this.resetState();
  };

  public render(): React.ReactNode {
    const {
      classes,
      userRegistration: { showCaptcha },
      match: { params },
      isLoggedIn
    } = this.props;

    if (params) {
      const inviteCode = params.inviteCode;
      if (inviteCode && isLoggedIn) {
        return <AlreadyLoginInvitation />;
      }
    }

    return (
      <div className={classes.root}>
        <ScrollToTopOnMount />
        <div className={classes.container}>
          <Grid container={true} alignItems="center" justify="center">
            <Grid item={true} xs={11} md={8} lg={8} xl={7}>
              <Card raised={true} className={classes.registerCard}>
                <Grid container={true} alignItems="center" justify="center">
                  <Grid item={true} xs={12}>
                    <Typography align="center" component="h1" variant="h5" color="primary">
                      Register
                    </Typography>
                  </Grid>
                  {this.getStepper()}
                  {this.getStepBody()}
                  {this.getFooter()}
                  {showCaptcha ? <ReCaptcha action="REGISTER" onChange={this.onRecaptchaChange} /> : null}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  private getFooter = (): React.ReactNode => {
    const {
      theme,
      history,
      userRegistration: {
        stepper: { selected }
      }
    } = this.props;

    function navigateLogin() {
      history.push('/login');
    }

    switch (selected) {
      case 0:
        return (
          <Grid item={true} xs={12} container={true}>
            <Grid style={{ margin: theme.spacing() }} item={true} xs={12} container={true} alignItems="center" justify="center">
              {this.getNextButton()}
            </Grid>
            <Grid style={{ margin: theme.spacing() }} item={true} xs={12} container={true} alignItems="center" justify="center">
              <Grid item={true} xs="auto">
                <Typography color={'textSecondary'}>Already have an account?</Typography>
              </Grid>
              <Grid item={true} xs="auto">
                <Button color="primary" aria-label={'Sign In'} onClick={navigateLogin}>
                  Sign In
                  <Icon className={classNames('icon-sign-In')} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid item={true} xs={12} container={true}>
            {this.getNextButton()}
          </Grid>
        );
      default:
        return null;
    }
  };

  private getNextButton = () => {
    const {
      theme,
      userRegistration: {
        stepper: { selected },
        userRegistrationData: { isValidAccountCredentials, isValidPersonalInformation, phone, gender, firstName, lastName, address },
        loading
      },
      classes
    } = this.props;
    const { tosChecked } = this.state;
    const isNextEnabled =
      selected === 0
        ? isValidAccountCredentials && tosChecked && nameValidationRegex.test(lastName) && nameValidationRegex.test(firstName)
        : isValidPersonalInformation &&
          !loading &&
          phone?.length === 10 &&
          !!gender &&
          address?.zipCode.length === 5 &&
          zipcodeValidationRegex.test(address.zipCode);
    const onNext = selected === 0 ? this.validateAccountData.bind(this, 1) : this.registerPlayer.bind(this, 2);

    if (selected === 0) {
      return (
        <Button
          onClick={onNext}
          color="primary"
          size="large"
          disabled={!isNextEnabled}
          variant="contained"
          className={classes.button}
          aria-label={'Navigate to next step'}
        >
          Next
        </Button>
      );
    } else if (selected === 1) {
      return (
        <Grid item={true} xs={12} container={true} alignItems="center" justify="center">
          <Grid style={{ margin: theme.spacing() }} item={true} xs={12} md={6} container={true} alignItems="center" justify="center">
            <Button
              onClick={onNext}
              disabled={!isNextEnabled}
              color="primary"
              variant="contained"
              className={classes.button}
              aria-label={'Save this step and Register'}
            >
              Save & Register
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  public handleTosChecked = () => {
    const { tosChecked } = this.state;
    this.setState({ tosChecked: !tosChecked });
  };

  private getStepBody(): JSX.Element | undefined {
    const {
      updateUserRegistrationField: updateField,
      userRegistration: { stepper, userRegistrationData, showInviteCode },
      updateInviteCodeVisibility: updateCodeVisibility
    } = this.props;
    const accountCredentialsFields = registrationFields
      .filter(x => x.page === 0)
      .map(x => {
        x.value = userRegistrationData[x.key];
        return x;
      });
    const personalInformationFields = registrationFields
      .filter(x => x.page === 1)
      .map(x => {
        x.value = userRegistrationData[x.key];
        return x;
      });
    const hidden = { display: 'none' };
    const contentSlide1 = (
      <Grid item={true} xs={11} lg={10} container={true} style={stepper.selected !== 0 ? hidden : undefined}>
        <AccountCredentials
          updateInviteCodeVisibility={updateCodeVisibility}
          showInviteCode={showInviteCode}
          passwordErrors={userRegistrationData.passwordErrors}
          updateField={updateField}
          accountCredentialsFields={accountCredentialsFields}
          handleTosChecked={this.handleTosChecked}
          tosChecked={this.state.tosChecked}
          inviteCodeStatus={this.props.userRegistration.inviteCodeStatus}
        />
      </Grid>
    );

    const contentSlide2 = (
      <Grid item={true} xs={11} lg={10} container={true}>
        <PersonalInformation
          handleAddressSelect={this.handleAddressSelect}
          onBack={this.onPersonalInformationBack}
          updateField={updateField}
          personalInformationFields={personalInformationFields}
          address={this.state.address}
          getStates={this.props.getStates}
          getCities={this.props.getCities}
          countries={this.props.address.countries}
          states={this.props.address.states}
          cities={this.props.address.cities}
          resetStates={this.props.resetStates}
          resetCities={this.props.resetCities}
          loadingCities={this.props.address.loading}
        />
      </Grid>
    );

    const contentSlide3 = (
      <Grid item={true} xs={11} lg={10} container={true} style={stepper.selected !== 2 ? hidden : undefined}>
        <Confirmation />
      </Grid>
    );
    return (
      <>
        {contentSlide1} {stepper.selected === 1 ? contentSlide2 : null} {contentSlide3}
      </>
    );
  }

  private getStepper = (): React.ReactNode => {
    const {
      userRegistration: {
        stepper: { selected },
        userRegistrationData
      },
      show
    } = this.props;
    return (
      <Grid item={true} xs={12} container={true} justify={'center'} alignItems={'center'}>
        <Grid item={true} xs={12} lg={10}>
          <RegistrationStepper onPress={this.changeStepper} activeStep={selected} />
          <AccountReactivateEmailSentDialog
            email={userRegistrationData.email}
            show={this.state.reactivationEmailDialog}
            okayCallback={this.okayCallback}
          />
          <AccountReactivateDialog
            email={userRegistrationData.email}
            show={show}
            successCalback={this.successCallback.bind(this, userRegistrationData.email)}
          />
        </Grid>
      </Grid>
    );
  };

  private resetState = async () => {
    await this.props.reset();
    await this.props.clearMessages();
    await this.props.resetStates();
    await this.props.resetCities();
  };

  private handleAddressSelect = (field: string, value: string) => {
    const { address } = this.state;
    if (address.hasOwnProperty(field)) {
      address[field] = value;
    }
    this.setState({ address });
    this.props.updateUserRegistrationField('address', address);
  };

  private async successCallback(email: string) {
    try {
      await AuthService.unSuspend(email);
      this.setState({ reactivationEmailDialog: true });
    } catch (e: any) {}
  }

  private okayCallback = async () => {
    this.setState({ reactivationEmailDialog: false });
  };

  private validateAccountData = async (position: StepperSteps) => {
    const {
      userRegistration: {
        stepper: { selected },
        userRegistrationData
      }
    } = this.props;

    // if user moving back to first step from second there is no need for validation.
    if (position === 0 && selected === 1) {
      this.props.updateStepper(position);
      return;
    }

    this.props.clearMessages();
    if (!!userRegistrationData.inviteCode) {
      const checkInviteCodeResponse = await RegistrationService.checkInviteCode(userRegistrationData.inviteCode);
      if (checkInviteCodeResponse !== true) {
        await this.props.showMessageBar({
          type: 'error',
          message: 'registration.invalidInviteCode'
        });
        scrollToTheTop();
        return;
      }
    }

    const { isValidAccountCredentials } = this.props.userRegistration.userRegistrationData;
    if (position > 0 && !isValidAccountCredentials) {
      return;
    }
    await this.props.checkIfEmailInUse(this.props.userRegistration.userRegistrationData);
    const { emailExists } = this.props.userRegistration;

    if (!emailExists) {
      await this.props.clearMessages();
      this.props.updateStepper(position);
    } else {
      try {
        await AuthService.isAccountActive(userRegistrationData.email);
      } catch (e: any) {
        if (e.payload.status === 400 && e.payload.errorKey === 'error.email.suspended') {
          this.props.reactivateAccount(true);
          return;
        }
      }
      await this.props.showMessageBar({
        type: 'error',
        message: 'registration.emailInUse'
      });
      scrollToTheTop();
    }
  };
  private onPersonalInformationBack = async (): Promise<void> => {
    await this.changeStepper(0);
  };
  private changeStepper = async (position: StepperSteps): Promise<void> => {
    const {
      userRegistration: {
        stepper: { selected }
      }
    } = this.props;

    // User can't go back after registration.
    if (selected === 2) {
      return;
    }

    // User can't click on step 3 to register, need to click bottom button.
    if (position > 1) {
      return;
    }
    await this.validateAccountData(position);
  };

  private registerPlayer = async (_1: StepperSteps) => {
    const {
      userRegistration: { showCaptcha },
      updateCaptchaVisibility: updateCaptcha
    } = this.props;
    if (showCaptcha) {
      await this.doRegister();
    } else {
      await this.props.toggleLoading({ spinning: true, label: 'Registering' });
      await updateCaptcha(true);
    }
  };

  private doRegister = async () => {
    const { isValidAccountCredentials } = this.props.userRegistration.userRegistrationData;
    const authenticationV2 = this.props.authorizedFlags.includes('AUTHENTICATION_V2');
    if (!isValidAccountCredentials) {
      await this.props.updateCaptchaVisibility(false);
      await this.props.clearMessages();
      await this.props.clearLoading();
      return;
    }
    await this.props.registerPlayer(this.props.userRegistration.userRegistrationData, authenticationV2);
    const { registrationSucceeded } = this.props.userRegistration;

    await this.props.updateCaptchaVisibility(false);
    await this.props.clearMessages();
    await this.props.clearLoading();

    if (registrationSucceeded) {
      this.props.updateStepper(2);
    } else {
      await this.props.showMessageBar({
        type: 'error',
        message: 'registration.failed'
      });
    }
  };

  private onRecaptchaChange = async (value: any) => {
    await this.props.updateUserRegistrationField('recaptcha', value);
    await this.doRegister();
  };
}

const mapStateToProps = ({
  userRegistration,
  authentication: { accessToken, account },
  address,
  accountReactivation: { show },
  feature: { authorizedFlags }
}: IRootState) => ({
  userRegistration,
  accessToken,
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  address,
  show,
  authorizedFlags
});

const mapDispatchToProps = {
  updateStepper,
  updateUserRegistrationField,
  registerPlayer,
  checkIfEmailInUse,
  reset,
  updateInviteCodeVisibility,
  resetAdditionalInformation,
  showMessageBar,
  clearMessages,
  updateCaptchaVisibility,
  clearLoading,
  toggleLoading,
  isAuthenticated,
  getCities,
  getStates,
  resetCities,
  resetStates,
  reactivateAccount
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(UserRegistrationScreen))));
