declare global {
  interface Window {
    _env_: Record<string, string | undefined>;
  }
}

class EnvironmentVariables {
  public static lookupString = (key: string, defaultValue?: string): string | undefined =>
    window?._env_?.[key] ?? process?.env?.[key] ?? defaultValue;

  public static lookupNumber = (key: string, radix = 10, defaultValue?: number): number | undefined => {
    const val = EnvironmentVariables.lookupString(key);
    if (val) {
      return parseInt(val, radix);
    }

    return defaultValue;
  };

  public static lookupFloat = (key: string, defaultValue?: number): number | undefined => {
    const val = EnvironmentVariables.lookupString(key);
    if (val) {
      return parseFloat(val);
    }

    return defaultValue;
  };

  public static lookupBoolean = (key: string, defaultValue?: boolean): boolean => {
    const val = EnvironmentVariables.lookupString(key);
    return val?.toLowerCase() === 'true' || Boolean(defaultValue);
  };

  public static lookupJson = <K = unknown>(key: string, defaultValue?: Record<string, K>): Record<string, K> | undefined => {
    const val = EnvironmentVariables.lookupString(key);
    if (val) {
      try {
        return JSON.parse(val);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    return defaultValue;
  };
}

export default EnvironmentVariables;
