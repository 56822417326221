import { Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import appleStoreIcon from 'assets/img/apple-store-badge.svg';
import googlePlayStoreIcon from 'assets/img/google-play-badge.png';
import image from 'assets/img/ks_logo_footer.png';
import styles from 'assets/jss/shared/components/footer/FooterStyles';
import constants from 'config/constants';
import mainMenu from 'config/menu';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SocialMenu from 'shared/components/footer/SocialMenu';
import MainMenu from './MainMenu';

interface IProps {
  isAuthenticated: boolean;
}

const useStyles = makeStyles(styles);

const Footer: React.FunctionComponent<IProps> = ({ isAuthenticated }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const menufooter = mainMenu.footerMenu;
  const menu = {
    footerMenu: menufooter.map(x => {
      x.isActive = x.route === location.pathname;
      return x;
    }),
    footerSocialMenu: mainMenu.footerSocialMenu.map(x => {
      x.isActive = x.route === location.pathname;
      return x;
    })
  };
  const renderMenuItems = (): React.ReactNode => {
    return <MainMenu isAuthenticated={isAuthenticated} mainMenu={menu.footerMenu} />;
  };

  const renderSocialMenuItems = (): React.ReactNode => {
    return <SocialMenu isAuthenticated={isAuthenticated} mainMenu={menu.footerSocialMenu} />;
  };

  return (
    <div className={classes.root}>
      <div className={classes.toolBar}>
        <Grid container={true} className={classes.parentContainer}>
          <div className={classes.staticFooter}>
            <Typography color={'textPrimary'} style={{ textAlign: 'center' }}>
              {t('footer.appDownloadText')}
            </Typography>
            <div style={{ textAlign: 'center' }}>
              <a href={constants.IOS_DOWNLOAD_URL} target="_blank" aria-label="Download on the Apple Store">
                <img alt={'Download on the Apple Store'} className={classes.applePlayStore} src={appleStoreIcon} />
              </a>
              <a href={constants.ANDROID_DOWNLOAD_URL} target="_blank" aria-label="Download from Google Play">
                <img alt={'Download from Google Play'} className={classes.googlePlayStore} src={googlePlayStoreIcon} />
              </a>
            </div>
          </div>
          <div className={classes.staticFooter}>
            <Grid item={true} lg={12} container={true}>
              <Typography variant="caption" align={'justify'} color={'textPrimary'} className={classes.bottomSpace}>
                {t('footer.disclaimer')}
              </Typography>
            </Grid>
          </div>
          <div className={classes.staticFooterItems}>
            <Grid item={true} container={true} lg={12}>
              <Typography variant="caption" align={'center'} color={'textPrimary'}>
                {t('footer.copyright', { year: new Date().getFullYear() })}
              </Typography>
            </Grid>
          </div>
          <Hidden mdDown={true}>
            <Grid item={true}>
              <a href={'https://www.kslottery.com'} target="_blank" aria-label="Kansas Lottery Website">
                <img alt={'Kansas Lottery Logo'} className={classes.logo} src={image} />
              </a>
            </Grid>
          </Hidden>
          <div className={classes.menuItem}>{renderMenuItems()}</div>
          <div className={classes.socialItem}>{renderSocialMenuItems()}</div>
          <Hidden lgUp={true}>
            <Grid item={true} container={true} xs={12} justify="center" alignItems="center">
              <a href={'https://www.kslottery.com'} target="_blank" aria-label="Kansas Lottery Website">
                <img alt={'Kansas Lottery Logo'} className={classes.logo} src={image} />
              </a>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
