import { Button, Card, CardActionArea, Grid, Typography, withStyles, WithStyles } from '@material-ui/core';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import { IAdvertisementContent } from 'modules/home/components/FeaturedSlider';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  item: IAdvertisementContent;
}

const FeaturedSlide: React.FunctionComponent<IProps> = ({ classes, item, history }) => {
  const onNavigate = () => {
    if (!item.url) return;
    if (item.target === 'EXTERNAL') {
      window.open(item.url, '_blank');
    } else {
      history.push(item.url);
      return;
    }
  };

  return (
    <div>
      <img
        aria-hidden="false"
        src={item.backgroundImage || constants.AD_BACKGROUND}
        alt={item.title}
        className={classNames('slick-image', classes.carouselImage)}
      />

      <div className={classNames(classes.carouselContent)}>
        <div className={classNames(classes.container, classes.carouselInnerContainer)}>
          <Grid container={true} justify="space-between" spacing={8} className={classes.carouselGrid}>
            <Grid item={true} xs={12} md={6} className={classNames(classes.carouselData)}>
              <div className={classNames(classes.carouselDescription)}>
                <Typography
                  component={'div'}
                  variant="h4"
                  style={{ color: item.textColor ? item.textColor : 'white' }}
                  className={classNames(classes.carouselText)}
                >
                  {item.title}
                </Typography>
                {!!item.btnText && (
                  <div className={classNames(classes.carouselDescriptionBtnView)}>
                    <Button onClick={onNavigate} title={item.title} variant="contained" size="large" color="secondary">
                      {item.btnText}
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item={true} xs={12} md={6} container={true} className={classNames(classes.carouselImageContainer)}>
              <Card className={classNames(classes.carouselCardImage)}>
                <CardActionArea>
                  <img
                    aria-label={item.title}
                    src={item.image}
                    alt={item.title}
                    onClick={onNavigate}
                    className={classNames(classes.carouselCardImage)}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withStyles(styles)(FeaturedSlide));
