import { IReward, RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import { Title } from '@pbl/pbl-react-web-components/lib/package';
import { Container } from '@pbl/pbl-react-web-components/lib/page-container';
import { TabScreen } from '@pbl/pbl-react-web-components/lib/tab-screen';
import constants from 'config/constants';
import { useRewardActiveList } from 'hooks/providers/useRewardActiveList';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { RewardSection } from './sections/RewardSection';

const RewardsScreen: React.FC = () => {
  useScrollToTop();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname, state: locationState } = useLocation();
  const { push: navigate, replace: historyReplace } = useHistory();
  const { rewardTabsVisibility } = useRewardActiveList();

  useEffect(() => {
    // @ts-ignore
    const locationStateMessage = locationState?.message;

    if (locationStateMessage) {
      dispatch(showMessageBar(locationStateMessage));

      // Clear route state after consuming the message.
      historyReplace({});
    }
  }, [dispatch, historyReplace, locationState]);

  const navigateToTicketEntry = useCallback(() => navigate('/ticket-entry', { from: pathname }), [navigate, pathname]);
  const onRewardSelect = useCallback(
    (reward: IReward) => navigate({ pathname: `/rewards/${reward.id}`, state: { category_name: reward.type } }),
    [navigate]
  );

  return (
    <Container
      pageTitle={`${constants.PROJECT_NAME} - Rewards`}
      title={
        <Title
          icon={'icon-KS-rewards'}
          title={'Rewards'}
          buttonText={t('games.enterTicket')}
          buttonTextTitle={t('games.ticketEntry')}
          titleColor={'textPrimary'}
          navigateToTicketEntry={navigateToTicketEntry}
        />
      }
    >
      <TabScreen
        tabs={constants.REWARD_TYPES.split(',')
          .filter(type => rewardTabsVisibility[type])
          .map(type => ({
            title: t(`rewards.tabs.${type}`),
            component: <RewardSection type={type as RewardType} onRewardSelect={onRewardSelect} />
          }))}
      />
    </Container>
  );
};

export default RewardsScreen;
