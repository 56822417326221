import { createStyles, Grid, Typography, WithStyles, withStyles, withTheme, WithTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { IAddress } from '@pbl/pbl-react-core/lib/models/forms/types';
import { AddressType, PhoneType } from '@pbl/pbl-react-core/lib/models/profile/types';
import { NoPurchaseNecessaryData } from '@pbl/pbl-react-core/lib/models/promotion/NoPurchaseNecessaryData';
import ArcadePopupButton from '@pbl/pbl-react-web-components/lib/arcade/ArcadePopupButton';
import DateFieldValidator from '@pbl/pbl-react-web-components/lib/field/DateFieldValidator';
import TextFieldValidator from '@pbl/pbl-react-web-components/lib/field/TextFieldValidator';
import { InformationDialog, TosCheckbox } from '@pbl/pbl-react-web-components/lib/package';
import constants from 'config/constants';
import { fieldHasError, NoPurchaseNecessaryDataFields, npnFields, setHasError } from 'config/noPurchaseNecessary';
import { phoneMask } from 'config/validation';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { conformToMask } from 'react-text-mask';
import { IRootState } from 'redux/reducers';
import { getCities, getStates, resetCities, resetStates } from 'redux/reducers/address/actions';
import AddressComponent from 'shared/components/input/Address';

type PropsConnected = ConnectedProps<typeof connector>;

interface INoPurchaseNecessaryProps extends WithStyles<typeof styles>, WithTranslation, PropsConnected, WithTheme {
  open: boolean;
  onClose: () => void;
  onFreeClaim: (recaptcha: string) => any;
}

interface INoPurchaseNecessaryState {
  tosChecked: boolean;
  sendUpdatesChecked: boolean;
  address: IAddress;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth?: Date;
  validForm: boolean;
  phone: string;
  recaptcha: string;
  verifiedCaptcha: boolean;
  loading: boolean;
  profileInfoMismatch: boolean;
  address1Error?: boolean;
  address2Error?: boolean;
  zipCodeError?: boolean;
  countryError?: boolean;
  stateError?: boolean;
  cityError?: boolean;

  [attr: string]: string | boolean | IAddress | Date | undefined;
}
const INITIAL_STATE: INoPurchaseNecessaryState = {
  tosChecked: false,
  sendUpdatesChecked: false,
  address: {
    address1: '',
    address2: '',
    city: '',
    country: '',
    countryCode: '',
    state: '',
    zipCode: '',
    apartmentNumber: ''
  },
  dateOfBirth: undefined,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  validForm: false,
  recaptcha: '',
  verifiedCaptcha: false,
  loading: false,
  profileInfoMismatch: false,
  address1Error: false,
  address2Error: false,
  zipCodeError: false,
  countryError: false,
  stateError: false,
  cityError: false
};
class NoPurchaseNecessary extends React.Component<INoPurchaseNecessaryProps, INoPurchaseNecessaryState> {
  constructor(props: INoPurchaseNecessaryProps) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        countryCode: '',
        state: '',
        zipCode: '',
        apartmentNumber: ''
      }
    };
  }
  private tosClicked = () => {
    this.setState({ tosChecked: !this.state.tosChecked });
    this.validateForm();
  };
  private sendUpdatesClicked = () => {
    this.setState({ sendUpdatesChecked: !this.state.sendUpdatesChecked });
    this.validateForm();
  };
  private updateField = (fieldName: NoPurchaseNecessaryDataFields, value: any) => {
    this.setState({ [fieldName]: value });
    // reset mismatched profile info error
    setHasError(fieldName, false);
  };
  private getGuestAccount = (): NoPurchaseNecessaryData => {
    const { firstName, lastName, dateOfBirth, address, phone, email, recaptcha } = this.state;
    const formattedPhone = phone ? phone.replace(/\D+/g, '') : '';
    return new NoPurchaseNecessaryData({ firstName, lastName, dateOfBirth, address, phone: formattedPhone, email, recaptcha });
  };
  private handleAddressSelect = (field: string, value: string) => {
    const { address } = this.state;

    // Clean mismatched profile info error
    const addressInfoMismatch = {
      cityError: false,
      countryError: false,
      stateError: false,
      address1Error: false,
      address2Error: false,
      zipCodeError: false
    };

    this.setState({ ...addressInfoMismatch, profileInfoMismatch: false });
    // if country or state changed then need to reset its dependent fields
    if (field === 'country') {
      address.state = '';
      address.city = '';
    }
    if (field === 'state') {
      address.city = '';
    }

    // assign the value to the field
    if (address.hasOwnProperty(field)) {
      address[field] = value;
    }

    const guest = this.getGuestAccount();
    this.setState({ address, validForm: guest.isValidData });
  };
  private validateForm = () => {
    const guest = this.getGuestAccount();
    this.setState({ validForm: guest.isValidData });
  };
  private renderField = (part: number) => {
    const { classes } = this.props;
    return npnFields
      .filter(x => x.part === part)
      .map(field => {
        const onFieldChange = (event: any) => {
          let value: string;
          if (field.type === 'dateOfBirth') {
            value = event;
          } else if (field.type === 'phone') {
            value = !event.target.value || event.target.value.trim().length < 1 ? undefined : event.target.value;
          } else {
            value = event.target.value;
          }
          this.updateField(field.key as NoPurchaseNecessaryDataFields, value);
          this.setState({ profileInfoMismatch: false });
          this.validateForm();
        };
        const formatDate = date => (date ? date.format('DD MMM YYYY') : '');
        let phoneMaskedValue = '';
        if (!!this.state.phone && this.state.phone.toString().length > 0) {
          phoneMaskedValue = conformToMask(this.state.phone, phoneMask, {}).conformedValue;
        }
        return (
          <Grid key={field.key} className={classes.fieldContainer} item={true} xs={12} md={6}>
            {field.type === 'dateOfBirth' ? (
              <DateFieldValidator
                key={field.key}
                id={field.key}
                label={field.label}
                aria-label={field.label}
                className={classes.field}
                // @ts-ignore
                value={this.state[field.key] ? this.state[field.key] : null}
                labelFunc={formatDate}
                onChange={onFieldChange}
                validationSchema={field.validationSchema}
                minDate={field.minValue}
                maxDate={field.maxValue}
                error={field.hasError}
              />
            ) : (
              <TextFieldValidator
                key={field.key}
                required={field.isRequired}
                id={field.key}
                label={field.label}
                aria-label={field.label}
                className={classes.field}
                value={field.type === 'phone' ? phoneMaskedValue : this.state[field.key]}
                onChange={onFieldChange}
                onBlur={this.validateForm}
                validationSchema={field.validationSchema}
                inputProps={field.inputProps}
                InputProps={field.InputProps}
                variant="filled"
                error={field.hasError}
                autoComplete={'new-password'}
              />
            )}
          </Grid>
        );
      });
  };
  private closeModal = () => {
    this.setState({
      ...INITIAL_STATE,
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        countryCode: '',
        state: '',
        zipCode: '',
        apartmentNumber: ''
      }
    });
    this.props.onClose();
  };
  private optionalFieldIsDifferent = (currentValue: string, expectedValue) => currentValue && expectedValue ? currentValue.toLowerCase() !== expectedValue.toLowerCase() : false;

  private hasProfileMismatch = async (): Promise<boolean> => {
    const { firstName, lastName, dateOfBirth, email, address, phone } = this.state;
    const { profile } = this.props;
    const profileAddress = profile.addresses?.filter(x => x.addressTypeId === AddressType.HOME)?.[0] || null;
    const profilePhone = profile.phones?.filter(p => p.phoneTypeId === PhoneType.MOBILE && (p.phone?.trim()?.length || 0) > 0)?.sort((x, y) => y.id - x.id)?.[0] || null;
    const phoneWithoutMask = phone ? phone.replace(/\D+/g, '') : '';

    const addressInfoMismatch = {
      address1Error: this.optionalFieldIsDifferent(address.address1, profileAddress?.address1),
      address2Error: this.optionalFieldIsDifferent(address.address2, profileAddress?.address2),
      cityError: this.optionalFieldIsDifferent(address.city, profileAddress?.city),
      countryError: this.optionalFieldIsDifferent(address.country, profileAddress?.country),
      stateError: this.optionalFieldIsDifferent(address.state, profileAddress?.state),
      zipCodeError: this.optionalFieldIsDifferent(address.zipCode, profileAddress?.zipCode)
    };
    const firstNameHasErrors = fieldHasError('firstName', firstName, profile.firstName);
    const lastNameHasErrors = fieldHasError('lastName', lastName, profile.lastName);
    const emailHasErrors = fieldHasError('email', email, profile.email);
    const dateOfBirthHasErrors = fieldHasError('dateOfBirth', dateOfBirth?.toISOString().slice(0, 10) || '', profile.birthDate);
    const phoneHasErrors = phoneWithoutMask && profilePhone ? fieldHasError('phone', phoneWithoutMask, profilePhone.phone) : false;

    const isProfileInfoMismatch = firstNameHasErrors
      || lastNameHasErrors
      || emailHasErrors
      || dateOfBirthHasErrors
      || phoneHasErrors
      || addressInfoMismatch.address1Error
      || addressInfoMismatch.address2Error
      || addressInfoMismatch.cityError
      || addressInfoMismatch.stateError
      || addressInfoMismatch.zipCodeError
      || addressInfoMismatch.countryError;

    this.setState({ ...addressInfoMismatch, profileInfoMismatch: isProfileInfoMismatch });
    return isProfileInfoMismatch;
  };

  private onSubmit = async () => {
    this.setState({ loading: true });
    const isProfileMismatch = await this.hasProfileMismatch();
    this.setState({ validForm: !isProfileMismatch });
   if (!isProfileMismatch && this.state.validForm) {
      await this.sendFreeClaim();
    } else {
      this.setState({ loading: false });
    }
  };
  private sendFreeClaim = async () => {
    if (this.state.validForm) {
      await this.props.onFreeClaim(this.state.recaptcha);
      await this.setState({ verifiedCaptcha: false });
      this.closeModal();
    }
  };
  private onRecaptchaChange = async (value: any) => {
    await this.setState({ recaptcha: value });
    await this.setState({ verifiedCaptcha: !!this.state.recaptcha ? true : false });
  };
  private openTos = () => {
    if (this.props.selectedReward && this.props.selectedReward.termsConditionsUrl) {
      window.open(this.props.selectedReward.termsConditionsUrl);
    }
  };
  public render(): React.ReactNode {
    const { open, t, classes } = this.props;
    const { profileInfoMismatch } = this.state;
    return (
      <InformationDialog open={open} onClose={this.closeModal} maxWidth={'md'} hideButton={true} disableBackdropClick={true}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Typography variant="h6" align={'center'} className={classes.colorBlack}>
              {t('npn.title')}
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" align={'center'} className={classes.colorBlack}>
              {t('npn.required')}
            </Typography>
          </Grid>
          {this.renderField(1)}
          <Grid item={true} xs={12}>
            <Typography variant="subtitle1" align={'center'} className={classes.colorBlack}>
              {t('npn.additionalInfo')}
            </Typography>
          </Grid>
          <AddressComponent
            address={this.state.address}
            handleTextFieldChange={this.handleAddressSelect}
            contentClass={classes.addressContent}
            fieldClass={classes.fieldClass}
            fieldAutocompleteClass={classes.fieldAutocompleteClass}
            fieldVariant={'filled'}
            getStates={this.props.getStates}
            getCities={this.props.getCities}
            countries={this.props.address.countries}
            states={this.props.address.states}
            cities={this.props.address.cities}
            resetCities={this.props.resetCities}
            resetStates={this.props.resetStates}
            loadingCities={this.props.address.loading}
            showMandatory={false}
            address1Error={this.state.address1Error}
            address2Error={this.state.address2Error}
            cityError={this.state.cityError}
            stateError={this.state.stateError}
            zipCodeError={this.state.zipCodeError}
            countryError={this.state.countryError}
          />
          {this.renderField(2)}
          <Grid item={true} xs={12}>
            <TosCheckbox
              tosChecked={this.state.sendUpdatesChecked}
              handleTosChecked={this.sendUpdatesClicked}
              text1={t('npn.sendUpdates')}
              textColor={this.props.theme.palette.grey['900']}
              linkColor={this.props.theme.palette.secondary.dark}
            />
            <TosCheckbox
              tosChecked={this.state.tosChecked}
              handleTosChecked={this.tosClicked}
              text1={t('npn.tos')}
              text2={t('npn.tosLink')}
              openTos={this.openTos}
              textColor={this.props.theme.palette.grey['900']}
              linkColor={this.props.theme.palette.secondary.dark}
            />
            <ReCAPTCHA sitekey={constants.GOOGLE_RECAPTCHA_SITE_KEY_V2} size="normal" onChange={this.onRecaptchaChange} />
          </Grid>
          { profileInfoMismatch && <Grid item={true} xs={12} className={classes.buttonContainer}>
            <Typography variant="body2" align={'center'} className={classes.profileInformationMismatch}>
              {t('npn.profileInformationMismatch')}
            </Typography>
          </Grid>}
          <Grid item={true} xs={12} className={classes.buttonContainer}>
            <ArcadePopupButton
              onSubmit={this.onSubmit}
              title={t('npn.submit')}
              isDisabled={!this.state.sendUpdatesChecked || !this.state.tosChecked || !this.state.validForm || !this.state.verifiedCaptcha}
            />
          </Grid>
        </Grid>
      </InformationDialog>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    fieldContainer: {
      padding: theme.spacing()
    },
    field: {
      width: '100%'
    },
    addressContent: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    fieldClass: {
      flexBasis: '45.5%',
      color: theme.palette.common.black,
      margin: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        flexBasis: '45.3%'
      },
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%'
      }
    },
    fieldAutocompleteClass: {
      flexBasis: '45.5%',
      margin: theme.spacing(2),
      padding: 0,
      [theme.breakpoints.up('md')]: {
        flexBasis: '45.3%'
      },
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%'
      }
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3)
    },
    colorBlack: {
      color: theme.palette.common.black
    },
    colorGrey: {
      color: theme.palette.grey['900']
    },
    profileInformationMismatch: {
      color: theme.palette.error.main
    }
  });

const mapStateToProps = ({ address, reward: { selectedReward }, authentication: { account } }: IRootState) => ({
  address,
  selectedReward,
  profile: account
});

const mapDispatchToProps = {
  getStates,
  getCities,
  resetCities,
  resetStates
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withTheme(withStyles(styles)(withTranslation()(NoPurchaseNecessary))));
