import { EarnList } from '@pbl/pbl-react-web-components/lib/earn/v2/EarnList';
import { Title } from '@pbl/pbl-react-web-components/lib/package';
import { Container } from '@pbl/pbl-react-web-components/lib/page-container';
import constants from 'config/constants';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useActivityData } from 'modules/earn/hooks/useActivityData';
import { useNavigateToActivity } from 'modules/earn/hooks/useNavigateToActivity';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';

const EarnScreen: React.FC = () => {
  useScrollToTop();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname, state: locationState } = useLocation();
  const { push: navigate, replace: historyReplace } = useHistory();
  const { navigateToActivity } = useNavigateToActivity();
  const { activities } = useActivityData();

  useEffect(() => {
    // @ts-ignore
    const locationStateMessage = locationState?.message;

    if (locationStateMessage) {
      dispatch(showMessageBar(locationStateMessage));

      // Clear route state after consuming the message.
      historyReplace({});
    }
  }, [dispatch, historyReplace, locationState]);

  const navigateToTicketEntry = useCallback(() => navigate('/ticket-entry', { from: pathname }), [navigate, pathname]);

  return (
    <Container
      pageTitle={`${constants.PROJECT_NAME} - Earn`}
      title={
        <Title
          icon={'icon-KS-earn'}
          title={'Earn'}
          buttonText={t('games.enterTicket')}
          buttonTextTitle={t('games.ticketEntry')}
          titleColor={'textPrimary'}
          navigateToTicketEntry={navigateToTicketEntry}
        />
      }
    >
      <EarnList onActivitySelect={navigateToActivity} activities={activities} />
    </Container>
  );
};

export default EarnScreen;
