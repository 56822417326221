import { Button, Icon, TextField, Typography, withStyles, WithStyles } from '@material-ui/core';
import styles from 'assets/jss/modules/resend-activation/ResendActivationFormStyle';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { resendActivationSchema } from 'modules/authentication/resend-activation/components/validations';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sendAccountActivationInstructions } from 'redux/reducers/authentication/actions';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface IResendActivationFormProps extends StateProps, DispatchProps, RouteComponentProps, WithStyles<typeof styles>, WithTranslation {}

interface IResendActivationFormState {
  email: string;
  formIsValid: boolean;
}

class ResendActivationForm extends React.Component<IResendActivationFormProps, IResendActivationFormState> {
  public state: IResendActivationFormState = {
    email: '',
    formIsValid: false
  };

  public render() {
    const { email, formIsValid } = this.state;
    const { classes, t } = this.props;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem sm={10} md={10}>
            <div className={classes.content}>
              <div className={classes.text}>
                <Typography color="textPrimary" variant="body2" className={classes.description}>
                  Enter the email address you used when you registered with us. We will send the activation instructions to your email
                  again.
                </Typography>
              </div>
              <TextField
                label="Email"
                aria-label="Enter email address"
                type="email"
                autoComplete="email"
                variant="filled"
                fullWidth={true}
                id="email"
                value={email}
                onChange={this.onEmailChange}
                autoFocus={true}
                required={true}
              />
              <Button
                className={classes.resendButton}
                size="large"
                variant="contained"
                fullWidth={true}
                disabled={!formIsValid}
                color="primary"
                onClick={this.onSubmit}
                aria-label="Resend activation button. Click this to resend email activation"
              >
                {t('registration.resendActivation')}
              </Button>
              <div className={classes.register}>
                <Typography color="textPrimary" variant="body2">
                  Need to create an account?
                </Typography>
                <Button onClick={this.onNavigate.bind(this, '/register')} aria-label="click here to go to registration page">
                  <Typography variant="body2" className={classes.primaryText}>
                    REGISTER HERE
                  </Typography>
                  <Icon color="primary">how_to_reg</Icon>
                </Button>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  private onSubmit = async () => {
    const { email } = this.state;
    this.props.sendAccountActivationInstructions(email);
  };

  private onNavigate = (page: string) => {
    this.props.history.push(page);
  };

  private onEmailChange = event => {
    const value = event.target.value;
    const formIsValid = resendActivationSchema.isValidSync({ email: value });
    this.setState({ formIsValid, email: value });
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  sendAccountActivationInstructions
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withStyles(styles)(ResendActivationForm))));
