import { Button, Icon, Tooltip, Typography, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IField } from '@pbl/pbl-react-core/lib/models/forms/types';
import { FieldRenderer } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/login/LoginFormStyle';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import {
  loginEmailSchema,
  loginPasswordSchema,
  loginRememberMeSchema,
  loginSchema
} from 'modules/authentication/login/components/validations';
import { IRootState } from 'redux/reducers';
import { login } from 'redux/reducers/authentication/actions';
import { AuthService } from 'utils/auth-service';
import AccountReactivateDialog from './AccountReactivateDialog';
import AccountReactivateEmailSentDialog from './AccountReactivateEmailSentDialog';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface ILoginFormProps extends StateProps, DispatchProps, RouteComponentProps, WithStyles<typeof styles> {
  successCallback: () => void;
  errorCallback?: () => void;
}

interface ILoginFormState {
  email: string;
  password: string;
  formIsValid: boolean;
  rememberMe: boolean;
  [attr: string]: string | boolean;
  reactivationEmailDialog: boolean;
}

class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {
  public state: ILoginFormState = {
    email: '',
    password: '',
    rememberMe: false,
    formIsValid: false,
    reactivationEmailDialog: false
  };

  public render() {
    const { email, password, formIsValid, rememberMe } = this.state;
    const { authenticating, classes, show } = this.props;

    const emailField: IField<string> = {
      type: 'email',
      key: 'email',
      label: 'Email',
      value: email,
      get placeholder(): string {
        return this.label;
      },
      isRequired: true,
      get fieldValue() {
        return this.value;
      },
      validationSchema: loginEmailSchema
    };

    const rememberMeField: IField<boolean> = {
      type: 'boolean',
      key: 'rememberMe',
      label: 'Remember Me',
      value: rememberMe,
      get placeholder(): string {
        return this.label;
      },
      isRequired: false,
      get fieldValue() {
        return this.value;
      },
      validationSchema: loginRememberMeSchema
    };
    const passwordField: IField<string> = {
      type: 'password',
      key: 'password',
      label: 'Password',
      get placeholder(): string {
        return this.label;
      },
      value: password,
      isRequired: true,
      showPassword: true,
      secureTextEntry: true,
      get fieldValue() {
        return this.value;
      },
      validationSchema: loginPasswordSchema
    };

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem sm={10} md={10}>
            <div className={classes.content}>
              <FieldRenderer
                field={emailField}
                onChange={this.onEmailChange}
                inputProps={{
                  autoComplete: `email`,
                  fullWidth: true,
                  onBlur: this.onBlur,
                  variant: 'filled'
                }}
              />

              <FieldRenderer
                field={passwordField}
                onChange={this.onPasswordChange}
                inputProps={{
                  'aria-live': `assertive`,
                  autoComplete: `password`,
                  fullWidth: true,
                  onBlur: this.onBlur,
                  variant: 'filled',
                  style: { marginTop: 10 },
                  onKeyDown: this.onKeyDown
                }}
              />
              <div className={classes.forgotContainer}>
                <div className={classes.remember}>
                  <FieldRenderer
                    field={rememberMeField}
                    onChange={this.toggleRememberMe}
                    inputProps={{
                      'aria-label': `Remember Me.`,
                      'aria-live': `assertive`,
                      onBlur: this.onBlur,
                      style: { marginTop: 10 }
                    }}
                  />
                </div>
                <div className={classes.forgot}>
                  <Tooltip title={'Forgot Password?'} disableFocusListener={true} aria-label={'Forgot Password?'}>
                    <Button variant={'text'} onClick={this.onNavigate.bind(this, '/forgot-password')} aria-label="Forgot Password?">
                      <Typography variant="body2" className={classes.primaryText} style={{ textDecoration: 'none', textTransform: 'none' }}>
                        Forgot Password?
                      </Typography>
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <Button
                size="large"
                variant="contained"
                fullWidth={true}
                disabled={authenticating || !formIsValid}
                color="primary"
                onClick={this.onSubmit}
                aria-label="Sign in"
              >
                SIGN In
              </Button>
              <AccountReactivateEmailSentDialog email={email} show={this.state.reactivationEmailDialog} okayCallback={this.okayCallback} />
              <AccountReactivateDialog email={email} show={show} successCalback={this.successCallback.bind(this, email)} />
              <Tooltip title={'Re-send Activation Email?'} disableFocusListener={true} aria-label={'Re-send Activation Email?'}>
                <Button
                  variant={'text'}
                  onClick={this.onNavigate.bind(this, '/activate-email')}
                  aria-label="Re-send Activation Email?"
                  style={{ marginTop: 24 }}
                >
                  <Typography variant="body2" className={classes.primaryText} style={{ textDecoration: 'none', textTransform: 'none' }}>
                    Re-send Activation Email?
                  </Typography>
                </Button>
              </Tooltip>

              <div className={classes.register}>
                <Typography color="textPrimary" variant="body2">
                  Need to create an account?
                </Typography>
                <Tooltip title={'Register Here'} disableFocusListener={true} aria-label={`Register Here`}>
                  <Button onClick={this.onNavigate.bind(this, '/register')} aria-label="Register Here">
                    <Typography variant="body2" className={classes.primaryText}>
                      REGISTER HERE
                    </Typography>
                    <Icon color="primary">how_to_reg</Icon>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  private onSubmit = async () => {
    const { successCallback } = this.props;
    const { email, password, rememberMe } = this.state;
    await this.props.login(email, password, rememberMe, successCallback);
  };

  private onKeyDown = async event => {
    if (event.key === 'Enter') {
      await this.onSubmit();
    }
  };

  private onNavigate = (page: string) => {
    this.props.history.push(page);
  };

  private onEmailChange = (value: any) => {
    this.setState({ email: value });
    this.onBlur();
  };

  private async successCallback(email: string) {
    try {
      await AuthService.unSuspend(email);
      this.setState({ reactivationEmailDialog: true });
    } catch (e) {}
  }

  private okayCallback = () => {
    this.setState({ reactivationEmailDialog: false });
  };
  private onPasswordChange = (value: any) => {
    this.setState({ password: value }, this.onBlur);
  };
  toggleRememberMe = value => {
    this.setState({ rememberMe: value });
  };

  toggleConfirmStateOfArizona = (confirmStateOfArizona: boolean) => {
    this.setState({ confirmStateOfArizona });
    const { email, password, rememberMe } = this.state;
    const formIsValid = loginSchema.isValidSync({ email, password, rememberMe, confirmStateOfArizona });
    this.setState({ formIsValid });
  };

  private onBlur = () => {
    const { email, password, confirmStateOfArizona, rememberMe } = this.state;
    const formIsValid = loginSchema.isValidSync({ email, password, rememberMe, confirmStateOfArizona });
    this.setState({ formIsValid });
  };
}

const mapStateToProps = ({ authentication: { authenticating, showSuspendMessage }, accountReactivation: { show } }: IRootState) => ({
  authenticating,
  showSuspendMessage,
  show
});

const mapDispatchToProps = {
  login
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(LoginForm)));
