import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/RootState';

export const useIsLoggedIn = () => {
  const { account, accessToken } = useSelector((state: RootState) => state.authentication);

  const isLoggedIn = useMemo(() => Boolean(accessToken?.length && account?.email), [account, accessToken]);

  return isLoggedIn;
};
