import { AccountActivationState } from '@pbl/pbl-react-core/lib/models/account-activation';
import { ReactivationState } from '@pbl/pbl-react-core/lib/models/account-reactivation';
import { ActivityStateType } from '@pbl/pbl-react-core/lib/models/activity/types';
import { AddressState } from '@pbl/pbl-react-core/lib/models/address';
import { AppState, CLEAR_APP_STATE } from '@pbl/pbl-react-core/lib/models/app';
import { ArcadesGameState } from '@pbl/pbl-react-core/lib/models/arcades/types';
import { AuthState } from '@pbl/pbl-react-core/lib/models/authentication';
import { IConfettiState } from '@pbl/pbl-react-core/lib/models/confetti';
import { DrawDetailState } from '@pbl/pbl-react-core/lib/models/draws/types';
import { IFeatureState } from '@pbl/pbl-react-core/lib/models/feature-flagging';
import { formMetadataState } from '@pbl/pbl-react-core/lib/models/form-metadata';
import { GamesState } from '@pbl/pbl-react-core/lib/models/games/types';
import { InformationOnlyTileState } from '@pbl/pbl-react-core/lib/models/information-only-tile/types';
import { KSLegacyStateType } from '@pbl/pbl-react-core/lib/models/kslegacy/types';
import { LedgerState } from '@pbl/pbl-react-core/lib/models/ledger/types';
import { LoyaltyState } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { PasswordChangeState } from '@pbl/pbl-react-core/lib/models/password';
import { PasswordResetState } from '@pbl/pbl-react-core/lib/models/password-reset';
import { PollState } from '@pbl/pbl-react-core/lib/models/poll/types';
import { PreferencesState } from '@pbl/pbl-react-core/lib/models/preferences';
import { IProfileState } from '@pbl/pbl-react-core/lib/models/profile/types';
import { QuickDrawState } from '@pbl/pbl-react-core/lib/models/quickdraw/types';
import { IQuizState } from '@pbl/pbl-react-core/lib/models/quiz/types';
import { RewardState } from '@pbl/pbl-react-core/lib/models/reward/types';
import { SpinnerState } from '@pbl/pbl-react-core/lib/models/spinner';
import { StoreState } from '@pbl/pbl-react-core/lib/models/store/types';
import { SuperBonusState } from '@pbl/pbl-react-core/lib/models/super-bonus/types';
import { SurveyState } from '@pbl/pbl-react-core/lib/models/survey/types';
import { TicketState } from '@pbl/pbl-react-core/lib/models/ticket/types';
import { UploadDetailsState } from '@pbl/pbl-react-core/lib/models/upload/types';
import { UserRegistrationState } from '@pbl/pbl-react-core/lib/models/user-registration/types';
import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import HistoryPaginationState, { IHistoryPaginationState } from 'redux/reducers/history/pagination/reducers';
import InteractiveGames, { InteractiveGamesState } from 'redux/reducers/interactive-games/reducers';
import { MediaState } from 'redux/reducers/media/reducers';
import AccountActivation from './account-activation/reducers';
import AccountReactivation from './account-reactivation/reducers';
import ActivityState from './activity/reducers';
import Address from './address/reducers';
import App from './app/reducers';
import ArcadeGame from './arcade/reducers';
import Authentication from './authentication/reducers';
import Confetti from './confetti/reducers';
import DrawDetail from './draws/draw-detail.reducer';
import Draws, { DrawsState } from './draws/reducers';
import Feature from './feature/reducers';
import FormMetadata from './forms-metadata/reducers';
import Games from './games/reducers';
import History, { HistoryState } from './history/reducers';
import InformationOnlyTile from './information-only-tile/reducers';
import Ledger from './ledger/reducers';
import Loyalty from './loyalty/reducers';
import Media from './media/reducers';
import PasswordReset from './password-reset/reducers';
import PasswordChange from './password/reducers';
import PastHistory from './past-history/reducers';
import Poll from './poll/reducers';
import Preferences from './preferences/reducers';
import Profile from './profile/reducers';
import QuickDraws from './quickdraw/reducers';
import Quiz from './quiz/reducers';
import Reward from './reward/reducers';
import Spinner from './spinner.reducer';
import Store from './store/reducers';
import SuperBonus from './super-bonus/reducers';
import Survey from './survey/reducers';
import Ticket from './ticket/reducers';
import UploadV2 from './upload/v2/reducers';
import UserRegistration from './user-registration/reducers';
import VirtualTourReducer from './virtual-tour/reducers';

export type GetStateMethod = () => IRootState;

export interface IDispatchAction {
  type: string;
  payload?: any;
}

export type DispatchMethod = (object: IDispatchAction) => void;

export interface IRootState {
  app: AppState;
  authentication: AuthState & PersistPartial;
  loyalty: LoyaltyState;
  ledger: LedgerState;
  reward: RewardState;
  survey: SurveyState;
  userRegistration: UserRegistrationState;
  games: GamesState;
  history: HistoryState;
  historyPagination: IHistoryPaginationState;
  ticket: TicketState;
  preferences: PreferencesState;
  spinner: SpinnerState;
  draws: DrawsState;
  drawDetail: DrawDetailState;
  profile: IProfileState;
  passwordChange: PasswordChangeState;
  virtualtour: any;
  accountActivation: AccountActivationState;
  accountReactivation: ReactivationState;
  passwordReset: PasswordResetState;
  media: MediaState;
  poll: PollState;
  quiz: IQuizState;
  formMetadata: formMetadataState;
  interactiveGames: InteractiveGamesState;
  address: AddressState;
  informationOnlyTile: InformationOnlyTileState;
  confetti: IConfettiState;
  feature: IFeatureState;
  arcades: ArcadesGameState;
  store: StoreState;
  quickDraws: QuickDrawState;
  activityState: ActivityStateType & PersistPartial;
  superBonus: SuperBonusState;
  upload: UploadDetailsState;
  pastHistory: KSLegacyStateType;
}

const appReducer: (state: IRootState, action: IDispatchAction) => IRootState = combineReducers<IRootState>({
  app: App,
  authentication: Authentication,
  loyalty: Loyalty,
  ledger: Ledger,
  survey: Survey,
  userRegistration: UserRegistration,
  games: Games,
  history: History,
  historyPagination: HistoryPaginationState,
  ticket: Ticket,
  preferences: Preferences,
  spinner: Spinner,
  draws: Draws,
  drawDetail: DrawDetail,
  profile: Profile,
  passwordChange: PasswordChange,
  accountActivation: AccountActivation,
  passwordReset: PasswordReset,
  media: Media,
  poll: Poll,
  quiz: Quiz,
  formMetadata: FormMetadata,
  interactiveGames: InteractiveGames,
  address: Address,
  informationOnlyTile: InformationOnlyTile,
  confetti: Confetti,
  feature: Feature,
  arcades: ArcadeGame,
  store: Store,
  quickDraws: QuickDraws,
  reward: Reward,
  activityState: ActivityState,
  virtualtour: VirtualTourReducer,
  accountReactivation: AccountReactivation,
  superBonus: SuperBonus,
  upload: UploadV2,
  pastHistory: PastHistory
});

export default (state: IRootState, action: IDispatchAction): IRootState => {
  if (action.type === CLEAR_APP_STATE) {
    Object.assign(state, {} as IRootState, state.address);
  }
  return appReducer(state, action);
};
