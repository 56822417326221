import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from 'assets/jss/modules/account-activation/AccountActivationScreenStyle';
import constants from 'config/constants';
import { IRootState } from 'redux/reducers';
import { passwordResetFinish, reset } from 'redux/reducers/password-reset/actions';
import { getUrlParameter } from 'utils/urlUtils';
import ResetPasswordForm from './components/PasswordResetForm';
import PasswordResetSuccess from './components/PasswordResetSuccess';

type PropsConnected = ConnectedProps<typeof connector>;

interface IPasswordResetScreenProps extends RouteComponentProps, PropsConnected, WithStyles<typeof styles> {}

class PasswordResetScreen extends React.Component<IPasswordResetScreenProps> {
  public componentDidMount(): void {
    document.title = `${constants.PROJECT_NAME} - Reset Password`;
    const key = getUrlParameter('key', this.props.location.search);
    if (!key) {
      this.props.history.push('/');
    }
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public render(): React.ReactNode {
    const { classes, success, init } = this.props;
    return (
      <div className={classes.screen}>
        <div className={classes.container}>
          <Grid container={true} justify="center" item={true} xs={12}>
            {init && <ResetPasswordForm onSubmit={this.onSubmit} />}
            {success && <PasswordResetSuccess />}
          </Grid>
        </div>
      </div>
    );
  }

  private onSubmit = (password: string) => () => {
    const key = getUrlParameter('key', this.props.location.search);
    this.props.passwordResetFinish(key, password);
  };
}

const mapStateToProps = ({ passwordReset: { success, init } }: IRootState) => ({
  success,
  init
});

const mapDispatchToProps = { passwordResetFinish, reset };

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(PasswordResetScreen)));
