import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';
import color from 'color';
import colors from 'assets/jss/colors';

export default (theme: Theme) =>
  createStyles({
    container: {
      ...container,
      display: 'flex',
      flexGrow: 1
    },
    root: {
      backgroundColor: color(theme.palette.primary.main)
        .alpha(0.37)
        .rgb()
        .string(),
      height: '100%'
    },
    rightItem: {
      marginLeft: theme.spacing()
    },
    registerCard: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
        paddingTop: theme.spacing(2)
      }
    },
    fieldContainer: {
      padding: theme.spacing()
    },
    fieldTerms: {
      paddingTop: 0,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start'
      }
    },
    termText: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'left'
      }
    },
    field: {
      width: '100%'
    },
    errorCheckbox: {
      padding: theme.spacing() / 2,
      cursor: 'default',
      '& span': {
        color: colors.themeColors.successColor.hex()
      }
    },
    confirmIcon: {
      fontSize: 80,
      color: theme.palette.primary.main
    },
    confirmRow: {
      padding: theme.spacing()
    },
    textButton: {
      textTransform: 'none'
    },
    button: {
      minWidth: '250px',
      minHeight: '50px'
    },
    stepper: {
      padding: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing()
    },
    addressContent: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing()
    }
  });
