import DeviceMeta, { PlatformType, WebDeviceInfo } from '@pbl/pbl-react-core/lib/device/DeviceMeta';
import { generateDeviceId } from '@pbl/pbl-react-core/lib/utils/DeviceMetaHelper';
import { BrowserInfo, detect, OperatingSystem } from 'detect-browser';
import EnvironmentVariables from 'utils/environment-variables';

const storageKey = `DeviceMeta.DeviceId`;
const storageRemoteKey = `DeviceMeta.DeviceKey`;

/**
 * @todo move to react-web-components.
 */
class DeviceUtil {

  public static getDeviceId = (): string => {
    const deviceId = localStorage.getItem(storageKey);

    if (deviceId != null && deviceId.trim().length > 5) {
        return deviceId;
      }
      try {
        const newDeviceId = generateDeviceId();
        localStorage.setItem(storageKey, newDeviceId);
        return newDeviceId;
      } catch (e) {
        throw e;
      }
  }

  public static resetDeviceId = (): void => {
    localStorage.deleteItem(storageKey);
  }

  public static getDeviceKey = (): string | undefined => {
    const deviceKey = localStorage.getItem(storageRemoteKey);
    if (deviceKey != null) {
      return deviceKey;
    }
    return undefined;
  }

  public static setDeviceKey = (deviceKey: string) => localStorage.setItem(storageRemoteKey, deviceKey);

  private static getPlatform = (os: OperatingSystem): PlatformType => {
    switch (os) {
      case 'Amazon OS':
      case 'iOS':
      case 'Android OS':
      case 'BlackBerry OS':
      case 'Windows Mobile':
        return 'WEB_MOBILE';
      default:
        return 'WEB';
    }
  }

  public static buildDeviceMeta = async (deviceKey: string | undefined) => {

    const browser = detect() as BrowserInfo;

    const deviceId = DeviceUtil.getDeviceId();
    const platform: PlatformType = DeviceUtil.getPlatform(browser.os as OperatingSystem);
    const osName = browser.os as string;
    const osVersion = `${browser?.version}`;
    const appVersion = EnvironmentVariables.lookupString(`REACT_APP_VERSION`) as string;
    const appBuild = EnvironmentVariables.lookupString(`REACT_APP_BUILD_VERSION`) as string;

    const mobileDeviceInfo = new WebDeviceInfo(
      browser?.name ?? '',
      browser?.version ?? '',
      navigator.userAgent
    );

    return new DeviceMeta(
      deviceId,
      platform,
      deviceKey,
      osName,
      osVersion,
      appVersion,
      appBuild,
      undefined,
      mobileDeviceInfo
    );
  }

}

export default DeviceUtil;
