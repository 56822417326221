import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'redux/reducers';
import { fetchUserPointsWithExpiry } from 'redux/reducers/ledger/actions';

export const usePointsBalanceData = () => {
  const dispatch = useDispatch();
  const { userBalance } = useSelector((state: IRootState) => state.ledger);

  const initData = useCallback(async (): Promise<void> => {
    await dispatch(fetchUserPointsWithExpiry());
  }, [dispatch]);

  useEffect(() => {
    initData();
  }, [initData]);

  return {
    userPoints: userBalance.balance
  };
};
