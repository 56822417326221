import { createMuiTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import colors from 'assets/jss/colors';

const defaultFont = {
  fontFamily: '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",sans-serif',
  letterSpacing: 0
};

export const defaultConfig = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 }
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48
      },
      '@media (min-width:600px)': { minHeight: 64 }
    }
  },
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
    '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
    '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
    '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
    '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
    '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
  ],
  direction: 'ltr',
  overrides: {
    MuiSelect: {
      iconOutlined: {
        color: 'rgba(0, 0, 0, 1)'
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgba(0, 0, 0, 0.82)'
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: 'rgba(255, 255, 255, 0.74)'
      }
    },
    MuiPickersDay: {
      dayDisabled: {
        color: 'rgba(12, 19, 26, 0.7)'
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(233, 236, 240, 1)',
        '&.Mui-focused': {
          backgroundColor: 'rgba(233, 236, 240, 1)'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(12, 19, 26, 0.7)'
      }
    }
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      standard: 300,
      short: 250,
      enteringScreen: 225,
      shorter: 200,
      leavingScreen: 195,
      shortest: 150,
      complex: 375
    }
  },
  typography: {
    fontSize: 14,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    fontFamily: 'Roboto',
    button: {
      fontFamily: 'Satoshi',
      lineHeight: '16px',
      fontSize: '14px',
      letterSpacing: 1.5,
      fontWeight: 600
    },
    h1: {
      fontFamily: 'SatoshiBold',
      lineHeight: '115px',
      fontSize: '96px',
      letterSpacing: -1.5,
      fontWeight: 'bold'
    },
    h2: {
      fontFamily: 'SatoshiBold',
      fontSize: '64px',
      letterSpacing: -1,
      lineHeight: '70px',
      fontWeight: 'bold'
    },
    h3: {
      fontFamily: 'SatoshiBold',
      lineHeight: '55px',
      fontSize: '50px',
      letterSpacing: 0,
      fontWeight: 'bold'
    },
    h4: {
      fontFamily: 'SatoshiBold',
      lineHeight: '40px',
      fontSize: '36px',
      letterSpacing: 0,
      fontWeight: 'bold'
    },
    h5: {
      fontFamily: 'SatoshiMedium',
      lineHeight: '28px',
      fontSize: '26px',
      letterSpacing: 0,
      fontWeight: 600
    },
    h6: {
      fontFamily: 'SatoshiMedium',
      lineHeight: '22px',
      fontSize: '20px',
      letterSpacing: 1,
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    subtitle1: {
      fontFamily: 'SatoshiBold',
      lineHeight: '20px',
      fontSize: '18px',
      letterSpacing: 0.5,
      fontWeight: 600
    },
    subtitle2: {
      fontFamily: 'SatoshiMedium',
      lineHeight: '16px',
      fontSize: '15px',
      letterSpacing: 0.3,
      fontWeight: 600
    },
    body1: {
      ...defaultFont,
      lineHeight: '24px',
      fontSize: '17px',
      letterSpacing: 0.5
    },
    body2: {
      ...defaultFont,
      lineHeight: '20px',
      fontSize: '15px',
      letterSpacing: 0.3
    },
    caption: {
      ...defaultFont,
      lineHeight: '18px',
      fontSize: '12px',
      letterSpacing: 0.3
    },
    overline: {
      ...defaultFont,
      lineHeight: '15px',
      fontSize: '12px',
      letterSpacing: 2,
      textTransform: 'uppercase'
    }
  },
  zIndex: {
    modal: 1300,
    snackbar: 1400,
    drawer: 1200,
    appBar: 1100,
    mobileStepper: 1000,
    tooltip: 1500
  },
  shape: { borderRadius: 5 },
  props: {},
  spacing: 8
};

// @ts-ignore
const options: ThemeOptions = {
  ...defaultConfig,
  overrides: {
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          color: 'rgba(12, 19, 26, 0.7)'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(12, 19, 26, 0.7)',
        '&.Mui-disabled': {
          color: 'rgba(12, 19, 26, 0.7)'
        }
      }
    },
    MuiChip: {
      root: {
        '&.Mui-focusVisible': {
          boxShadow: `0 0 0px 2px white, 0 0 0 6px ${colors.themeColors.boxShadowColor.hex()}`
        }
      }
    },
    MuiButtonBase: {
      root: {
        '&.Mui-focusVisible': {
          boxShadow: `0 0 0px 2px white, 0 0 0 6px ${colors.themeColors.boxShadowColor.hex()}`
        }
      }
    },
    // @ts-ignore
    MuiPickersDay: {
      dayDisabled: {
        color: 'rgba(12, 19, 26, 0.7)'
      }
    }
  },
  palette: {
    type: 'light',
    tonalOffset: 0.2,
    background: { paper: '#fff', default: '#F5F5F5' },
    contrastThreshold: 3,
    grey: {
      '50': '#FFFFFF',
      '100': '#F9FAFB',
      '200': '#F4F6F8',
      '300': '#DFE3E8',
      '400': '#C4CDD5',
      '500': '#919EAB',
      '600': '#637381',
      '700': '#454F5B',
      '800': '#212B36',
      '900': '#161C24',
      A700: '#616161',
      A100: '#d5d5d5',
      A400: '#303030',
      A200: 'rgba(215, 220, 224, 1)'
    },
    text: {
      primary: '#161C24',
      secondary: '#454F5B',
      disabled: '#919EAB',
      hint: 'rgba(28, 38, 51)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    secondary: {
      main: colors.themeColors.secondaryColor.hex()
    },
    common: { black: '#000000', white: '#ffffff' },
    error: {
      main: colors.themeColors.dangerColor.hex(),
      contrastText: colors.themeColors.black.hex()
    },
    action: {
      hoverOpacity: 0.08,
      hover: 'rgba(0, 0, 0, 0.08)',
      selected: 'rgba(0, 0, 0, 0.14)',
      disabledBackground: 'rgba(233, 236, 240, 1)',
      disabled: 'rgba(0, 0, 0, 0.82)',
      active: 'rgba(0, 0, 0, 0.54)'
    },
    primary: {
      main: colors.themeColors.primaryColor.hex(),
      light: '#7D98FE'
    },
    warning: {
      main: colors.themeColors.warningColor.hex(),
      contrastText: colors.themeColors.black.hex()
    },
    success: {
      main: colors.themeColors.successColor.hex(),
      contrastText: colors.themeColors.black.hex()
    }
  }
};

export const defaultTheme: Theme = createMuiTheme(options);
