import { MAX_SAFE_INTEGER } from '@pbl/pbl-react-core/lib/config/config';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IDraw } from '@pbl/pbl-react-core/lib/models/draws/types';
import { RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import { Hideable } from '@pbl/pbl-react-web-components/lib/hideable';
import { RewardDigitalDownloadModal } from '@pbl/pbl-react-web-components/lib/package';
import { Container } from '@pbl/pbl-react-web-components/lib/page-container';
import RedeemConfirmationModal from '@pbl/pbl-react-web-components/lib/reward-detail/RedeemConfirmationModal';
import RedeemModal from '@pbl/pbl-react-web-components/lib/reward-detail/RedeemModal';
import RedemptionDetail from '@pbl/pbl-react-web-components/lib/reward-detail/RedemptionDetail';
import CouponDetailV2 from '@pbl/pbl-react-web-components/lib/reward-detail/v2/CouponDetailV2';
import RewardCouponModal from '@pbl/pbl-react-web-components/lib/reward-detail/v2/RewardCouponModalV2';
import RewardDetail from '@pbl/pbl-react-web-components/lib/reward-detail/v2/RewardDetailV2';
import constants from 'config/constants';
import { useDrawData } from 'hooks/providers/useDrawData';
import { useRewardData } from 'hooks/providers/useRewardData';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useScrollToTop } from 'hooks/useScrollToTop';
import ArcadeGame from 'modules/arcade/ArcadeGame';
import { formatRewardDate } from 'modules/reward-detail/utils/rewardDatesUtil';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { clearMessages, showMessageBar } from 'redux/reducers/app/actions';
import { debitArcadeGameV2, endDigitalArcadeGameData, resetGame } from 'redux/reducers/arcade/actions';
import { fetchUserPoints } from 'redux/reducers/ledger/actions';
import { getBarcodeData } from 'redux/reducers/loyalty/actions';
import { redeemCupon, redeemReward } from 'redux/reducers/reward/actions';
import { isEnded, isEndingSoon } from 'utils/rewardUtil';
import { usePointsBalanceData } from '../../hooks/providers/usePointsBalanceData';

const RewardDetailScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { rewardId }: Record<string, string> = useParams();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const onNavigate = useCallback(() => navigate('/rewards', { from: pathname }), [navigate, pathname]);
  const navigateToRewards = useCallback((message?: IErrorParams) => navigate('/rewards', { message }), [navigate]);

  const showInvalidRewardError = useCallback(() => {
    navigateToRewards({
      message: t('rewards.rewardUnavailable'),
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToRewards, t]);

  const [selectedDraw, setSelectedDraw] = useState<IDraw>();
  const { reward: selectedReward, activeDraw, coupon, promotion, redemption } = useRewardData(rewardId, showInvalidRewardError);
  const { userPoints } = usePointsBalanceData();
  const { loading, barcodeData, rewardDigitalDownload } = useSelector((state: IRootState) => state.loyalty);
  const { sweepstakesGameConfig, digitalRevealTokenDetails } = useSelector((state: IRootState) => state.arcades);
  const { draws, drawHistoryRetrieved, drawPrizes, drawPrizesWithContent, drawPrizesWithWinners, drawEntries, latestDrawHistory } =
    useDrawData(selectedDraw?.id, selectedReward?.externalId);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [launchGame, setLaunchGame] = useState<boolean>(false);
  const [showCouponModal, setShowCouponModal] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [showDigitalDownloadModal, setShowDigitalDownloadModal] = useState<boolean>(false);
  const [redeemQuantity, setRedeemQuantity] = useState<number>(0);
  const [gameType, setGameType] = useState<string>('prize');

  const loggedIn = useIsLoggedIn();
  const isCouponType = selectedReward?.type === RewardType.COUPON;
  const isDrawType = selectedReward?.type === RewardType.DRAW;
  const isRedemptionType = selectedReward?.type === RewardType.EXTERNAL;
  const isGamePlayType = selectedReward?.type === RewardType.GAMEPLAY;
  useScrollToTop();

  useEffect(() => {
    dispatch(fetchUserPoints());
  }, [dispatch]);

  useScrollToTop();

  const showRedeemError = (e: any) => {
    const hasErrorKey = e.hasOwnProperty('payload') ? e.payload.hasOwnProperty('errorKey') : false;
    const defaultErrorMessage = e.hasOwnProperty('message') ? e.message : (e as string);
    const errorMessage = hasErrorKey ? t(e.payload.errorKey, defaultErrorMessage) : defaultErrorMessage;

    dispatch(
      showMessageBar({
        message: errorMessage,
        type: 'error',
        messageTimeout: 10000
      })
    );
  };

  const showRedeemSuccess = () => {
    if (isCouponType) {
      setShowCouponModal(true);
      return;
    }

    if (rewardDigitalDownload) {
      setShowDigitalDownloadModal(true);
      return;
    }

    setShowModalConfirmation(true);
  };

  const navigateWithRedeemSuccess = () => {
    onModalClose();
    navigateToRewards({
      message: t('rewards.redeemSuccess'),
      type: 'success'
    });
  };

  const closeModalConfirmationDialog = () => {
    setShowModalConfirmation(false);
    onNavigate();
  };

  const onRedeem = async () => {
    if (!loggedIn) {
      navigate('/login', { from: pathname });
      return;
    }

    dispatch(clearMessages());
    setShowModal(true);
    setRedeemQuantity(1);
  };

  const requestRedeem = async () => {
    if (!selectedReward) {
      return;
    }

    setShowModal(false);

    if (isCouponType) {
      const redeemData = await redeemCupon(selectedReward.key, showRedeemError);

      if (!redeemData) return;

      dispatch(getBarcodeData(selectedReward.key, redeemData.code, redeemData.code));
      showRedeemSuccess();

      return;
    }

    dispatch(redeemReward(selectedReward.id.toString(), redeemQuantity, showRedeemSuccess, showRedeemError));
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const selectDraw = (draw: IDraw): void => {
    setSelectedDraw(draw);
  };

  if (!selectedReward) {
    return null;
  }

  const drawStartDate = formatRewardDate('drawStartEndDateFormat', t('reward.tbd'), activeDraw?.start);
  const drawEndDate = formatRewardDate('drawStartEndDateFormat', t('reward.tbd'), activeDraw?.end);
  const rewardEndDate = formatRewardDate('drawStartEndDateFormat', t('reward.tbd'), selectedReward.end);
  const drawDate = formatRewardDate('drawDrawDateFormat', t('reward.tbd'), activeDraw?.draw);
  const numberOfWinners = drawPrizes ? drawPrizes.map(p => p.totalWinners).reduce((a, b) => a + +b, 0) : 0;
  const isRewardEnded = isEnded(selectedReward.end);
  const isRewardEndingSoon =
    selectedReward.type === RewardType.DRAW && !!promotion && isEndingSoon(promotion.endingSoon, promotion.entryEnd);
  const maxRedeemAvailable = selectedReward
    ? Math.floor(
        Math.min(
          userPoints / selectedReward.amount,
          selectedReward?.frequencyCap?.remaining || MAX_SAFE_INTEGER,
          (isCouponType && coupon?.remaining) || MAX_SAFE_INTEGER,
          (isRedemptionType && redemption?.remaining) || MAX_SAFE_INTEGER
        )
      )
    : 0;

  const onPlay = async () => {
    if (!loggedIn) {
      navigate('/login', { from: pathname });
      return;
    }
    setGameType('prize');
    dispatch(debitArcadeGameV2(selectedReward.amount, selectedReward.key));
    setLaunchGame(true);
  };

  const gameEnded = async () => {
    dispatch(resetGame());
    const digitalRevealToken = sweepstakesGameConfig?.prizes[0].digitalRevealToken;
    if (digitalRevealToken) {
      dispatch(endDigitalArcadeGameData(digitalRevealToken));
      dispatch(fetchUserPoints());
    }
    setLaunchGame(false);
    onNavigate();
  };

  const closeGame = (): void => {
    gameEnded();
  };

  const getSpinResultsFromExtraData = (extraData: string) => {
    try {
      if (!!extraData) {
        const obj = JSON.parse(extraData);
        if (!!obj.spinResults) {
          return JSON.stringify(obj.spinResults);
        }
      }
    } catch (e) {
      return undefined;
    }
  };
  let spinResults;
  const gamePoints =
    sweepstakesGameConfig && sweepstakesGameConfig.prizes && sweepstakesGameConfig.prizes.length > 0
      ? sweepstakesGameConfig.prizes[0].amount
      : 0;

  if (
    sweepstakesGameConfig &&
    sweepstakesGameConfig.prizes &&
    sweepstakesGameConfig.prizes.length > 0 &&
    !!sweepstakesGameConfig.prizes[0].extraData
  ) {
    spinResults = getSpinResultsFromExtraData(sweepstakesGameConfig.prizes[0].extraData);
  }

  return (
    <Container pageTitle={selectedReward?.title ?? t('rewards.rewardDetails')}>
      <RedeemConfirmationModal
        isVisible={showModalConfirmation}
        onClose={closeModalConfirmationDialog}
        maxWidth="sm"
        // @TODO new email
        email="winners@kslottery"
        title={isRedemptionType ? 'Reward Redeemed!' : undefined}
        text={isRedemptionType ? t('rewards.redemptionConfirmationMessage') : t('rewards.confirmationMessage')}
      />
      {isCouponType ? (
        <CouponDetailV2
          selectedReward={selectedReward}
          userPoints={userPoints}
          onRedeem={onRedeem}
          isLoggedIn={loggedIn}
          maxRedeemAvailable={maxRedeemAvailable}
          onNavigate={onNavigate}
          showSoldOutTag={constants.SHOW_COUPON_SOLD_OUT_BANNER}
          remainingCoupons={coupon?.remaining}
          availabilityEndDate={rewardEndDate}
          drawDate={drawDate}
          isEnded={isRewardEnded}
        />
      ) : isRedemptionType ? (
        <RedemptionDetail
          selectedReward={selectedReward}
          userPoints={userPoints}
          onRedeem={onRedeem}
          isLoggedIn={loggedIn}
          maxRedeemAvailable={maxRedeemAvailable}
          onNavigate={onNavigate}
          showSoldOutTag={constants.SHOW_COUPON_SOLD_OUT_BANNER}
          remainingRedemptions={redemption?.remaining || 0}
          availabilityEndDate={rewardEndDate}
          drawDate={drawDate}
          isEnded={isRewardEnded}
        />
      ) : isGamePlayType ? (
        <RedemptionDetail
          selectedReward={selectedReward}
          userPoints={userPoints}
          onRedeem={onPlay}
          isLoggedIn={loggedIn}
          maxRedeemAvailable={maxRedeemAvailable}
          onNavigate={onNavigate}
          showSoldOutTag={constants.SHOW_COUPON_SOLD_OUT_BANNER}
          remainingRedemptions={redemption?.remaining || 0}
          availabilityEndDate={rewardEndDate}
          drawDate={drawDate}
          isEnded={isRewardEnded}
          buttonText={t('arcade.button.play')}
        />
      ) : (
        <RewardDetail
          selectedReward={selectedReward}
          selectDraw={selectDraw}
          onRedeem={onRedeem}
          isLoggedIn={loggedIn}
          maxRedeemAvailable={maxRedeemAvailable}
          onNavigate={onNavigate}
          entries={drawEntries ?? 0}
          loading={loading}
          showSoldOutTag={constants.SHOW_COUPON_SOLD_OUT_BANNER}
          remainingCoupons={coupon?.remaining}
          availabilityStartDate={drawStartDate}
          availabilityEndDate={isDrawType ? drawEndDate : rewardEndDate}
          drawDate={drawDate}
          isSweepstakes={isDrawType}
          numWinners={numberOfWinners}
          draws={draws}
          drawPrizesWithContent={drawPrizesWithContent}
          drawPrizesWithWinners={drawPrizesWithWinners}
          latestDrawHistory={latestDrawHistory}
          drawHistoryRetrieved={drawHistoryRetrieved}
          drawPrizes={drawPrizes}
          isEnded={isRewardEnded}
          isEndingSoon={isRewardEndingSoon}
          disableButton={loggedIn && maxRedeemAvailable === 0}
          hideDrawDate={!constants.SHOW_DRAW_DATE}
        />
      )}
      {launchGame ? (
        <ArcadeGame
          isLoggedIn={loggedIn}
          launchGame={launchGame}
          onCloseGame={closeGame}
          gameData={digitalRevealTokenDetails}
          gamePoints={gamePoints}
          spinResults={spinResults}
          isPBLGame={true}
          onGameExitHandler={gameEnded}
          gameType={gameType}
          topPrize={0}
          wager={selectedReward.amount}
          selectedIndex={0}
        />
      ) : null}
      <Hideable show={loggedIn && showModal && maxRedeemAvailable > 0}>
        <RedeemModal
          maxRedeemAvailable={maxRedeemAvailable}
          onRedeem={requestRedeem}
          onError={showRedeemError}
          onQuantityChange={setRedeemQuantity}
          redeemablePoints={userPoints}
          open={showModal}
          quantity={redeemQuantity}
          selectedReward={{ ...selectedReward, date_end: moment(selectedReward.end).format('X'), num_points: selectedReward.amount }}
          rewardTerms={selectedReward && selectedReward.termsConditionsUrl}
          onClose={onModalClose}
          hideRedeemableQuantity={true}
          disableSlider={isCouponType || isRedemptionType}
        />
      </Hideable>
      <Hideable show={loggedIn && showCouponModal && (isCouponType || isRedemptionType) && !!barcodeData}>
        <RewardCouponModal
          open={showCouponModal && !!barcodeData}
          barcodeData={barcodeData}
          selectedReward={{ ...selectedReward, num_points: selectedReward.amount }}
          onClose={navigateWithRedeemSuccess}
        />
      </Hideable>
      <Hideable show={loggedIn && (isCouponType || isRedemptionType) && showDigitalDownloadModal}>
        <RewardDigitalDownloadModal
          title={selectedReward.title}
          open={showDigitalDownloadModal}
          downloadLink={rewardDigitalDownload as string}
          onClose={navigateWithRedeemSuccess}
        />
      </Hideable>
    </Container>
  );
};

export default RewardDetailScreen;
