import React from 'react';
import { scrollToTheTop } from 'utils/htmlUtil';

export default class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    scrollToTheTop();
  }

  render = () => null;
}
