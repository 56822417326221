import AccountActivationScreen from 'modules/account-activation/account-activation-screen';
import AccountReactivation from 'modules/account-reactivation/account-reactivation';
import ArcadeDetailsScreen from 'modules/arcade/arcade-details-screen';
import ArcadesScreen from 'modules/arcade/arcades-screen';
import ExploreScreen from 'modules/arcade/explore-screen';
import RewardDetailsScreen from 'modules/arcade/reward-details-screen';
import TransactionHistoryScreen from 'modules/arcade/transaction-history-screen';
import ForgotPasswordScreen from 'modules/authentication/forgot-password/forgot-password-screen';
import ForgotPasswordSentScreen from 'modules/authentication/forgot-password/forgot-password-sent-screen';
import LoginScreen from 'modules/authentication/login/login-screen';
import ResendActivationScreen from 'modules/authentication/resend-activation/resend-activation-screen';
import ResendActivationSentScreen from 'modules/authentication/resend-activation/resend-activation-sent-screen';
import UserActivatedScreen from 'modules/authentication/user-activated-screen';
import CollectThemAllScreen from 'modules/collect-them-all/collect-them-all-screen';
import DrawDetailScreen from 'modules/draws/draw-detail-screen';
import DrawsScreen from 'modules/draws/promotions-screen';
import EarnScreen from 'modules/earn/earn-screen';
import EmailSignUpScreen from 'modules/earn/email-sign-up-screen';
import InteractiveGameScreen from 'modules/earn/interactive-game-screen-v2';
import ProfileCompletionScreen from 'modules/earn/profile-completion-screen';
import PromoCodeScreen from 'modules/earn/promo-code-screen';
import UploadContentScreen from 'modules/earn/upload-content-screen';
import WatchVideoScreen from 'modules/earn/watch-video-screen';
import NotFoundScreen from 'modules/errors/not-found-screen';
import FeedbackScreen from 'modules/feedback/feedback-screen';
import MediaViewRenderer from 'modules/footer/MediaViewRenderer';
import DrawGameDetailScreen from 'modules/games/draw-game-details-screen';
import GameDetailScreen from 'modules/games/game-detail-screen';
import GamesScreen from 'modules/games/games-screen';
import QuickDrawGameDetailScreen from 'modules/games/quick-draw-game-details-screen';
import QuickDrawGameWidget from 'modules/games/quick-draw-game-widget';
import HistoryScreen from 'modules/history/history-screen';
import PastHistoryScreen from 'modules/history/past-history-screen';
import HomeScreen from 'modules/home/home-screen';
import InformationOnlyDetailScreen from 'modules/information-only-tile/information-only-detail-screen';
import InformationOnlyListScreen from 'modules/information-only-tile/information-only-list-screen';
import InviteScreen from 'modules/invite/invite-screen';
import PasswordResetScreen from 'modules/password-reset/password-reset-screen';
import PollScreen from 'modules/poll/poll-screen';
import QuizScreen from 'modules/quiz/quiz-screen';
import RedirectBack from 'modules/RedirectBack';
import RewardDetailScreen from 'modules/reward-detail/reward-detail-screen';
import PastDrawDetailScreen from 'modules/rewards/past-draw-detail-screen';
import PastDrawsScreen from 'modules/rewards/past-draws-screen';
import RewardsScreen from 'modules/rewards/rewards-screen';
import SettingsScreen from 'modules/settings/settings-screen';
import PaymentResponseScreen from 'modules/store/payment-response-screen';
import StoreBilling from 'modules/store/store-billing';
import StoreScreen from 'modules/store/store-screen';
import StoreSuccessPayment from 'modules/store/store-success-payment';
import SurveyScreen from 'modules/survey/survey-screen';
import TicketScreen from 'modules/ticket/ticket-screen';
import UserRegistrationScreen from 'modules/user-registration/user-registration-screen';
import ViewUrlScreen from 'modules/view-url/view-url-screen';

const indexRoutes = [
  { path: '/activate', name: 'AccountActivationScreen', component: AccountActivationScreen },
  { path: '/unsuspend', name: 'AccountReactivationScreen', component: AccountReactivation },
  { path: '/reset/finish', name: 'PasswordResetScreen', component: PasswordResetScreen },
  { path: '/login', name: 'LoginScreen', component: LoginScreen },
  { path: '/activated', name: 'UserActivatedScreen', component: UserActivatedScreen },
  { path: '/activate-email', name: 'ResendActivationScreen', component: ResendActivationScreen },
  { path: '/activate-email-sent', name: 'ResendActivationSentScreen', component: ResendActivationSentScreen },
  { path: '/forgot-password', name: 'ForgotPasswordScreen', component: ForgotPasswordScreen },
  { path: '/forgot-password-sent', name: 'ForgotPasswordSentScreen', component: ForgotPasswordSentScreen },
  { path: '/games/types/:gameTypeId', name: 'Games', component: GamesScreen },
  { path: '/games/:gameId', name: 'GameDetailScreen', component: GameDetailScreen },
  { path: '/games-draw/:gameId', name: 'DrawGameDetailScreen', component: DrawGameDetailScreen },
  { path: '/games-quick-draw/:gameId', name: 'QuickDrawGameDetailScreen', component: QuickDrawGameDetailScreen },
  { path: '/games-quick-draw-widget/:gameId', name: 'QuickDrawGameWidget', component: QuickDrawGameWidget },
  { path: '/games', name: 'Games', component: GamesScreen },
  {
    path: '/lucky-lounge/game/:gameId',
    name: 'ArcadeDetailsScreen',
    component: ArcadeDetailsScreen,
    feature: 'SWEEPSTAKES_V1',
    secured: true
  },
  { path: '/lucky-lounge/reward/:rewardId', name: 'RewardDetailsScreen', component: RewardDetailsScreen, feature: 'SWEEPSTAKES_V1' },
  { path: '/lucky-lounge/store/pay', name: 'PaymentResponseScreen', component: PaymentResponseScreen, feature: 'SWEEPSTAKES_V1' },
  { path: '/lucky-lounge/store', name: 'StoreScreen', component: StoreScreen, feature: 'SWEEPSTAKES_V1', secured: true },
  { path: '/lucky-lounge/store-billing', name: 'StoreBilling', component: StoreBilling, feature: 'SWEEPSTAKES_V1', secured: true },
  {
    path: '/lucky-lounge/history',
    name: 'TransactionHistoryScreen',
    component: TransactionHistoryScreen,
    feature: 'SWEEPSTAKES_V1',
    secured: true
  },
  {
    path: '/lucky-lounge/store-payment-success',
    name: 'StoreSuccessPayment',
    component: StoreSuccessPayment,
    feature: 'SWEEPSTAKES_V1',
    secured: true
  },
  { path: '/lucky-lounge/explore', name: 'ExploreScreen', component: ExploreScreen, feature: 'SWEEPSTAKES_V1' },
  { path: '/lucky-lounge', name: 'ArcadesScreen', component: ArcadesScreen, feature: 'SWEEPSTAKES_V1' },
  { path: '/information-list/:id', name: 'InformationOnlyList', component: InformationOnlyDetailScreen },
  { path: '/information-list', name: 'InformationOnlyList', component: InformationOnlyListScreen },
  { path: '/collect-them-all/:id', name: 'CollectThemAll', component: CollectThemAllScreen },
  { path: '/rewards/:rewardId', name: 'RewardDetailScreen', component: RewardDetailScreen },
  { path: '/rewards', name: 'Rewards', component: RewardsScreen },
  { path: '/survey/:activityId', name: 'Surveys', component: SurveyScreen },
  { path: '/poll/:activityId', name: 'Polls', component: PollScreen },
  { path: '/quiz/:activityId', name: 'Quizzes', component: QuizScreen },
  { path: '/promotion/:drawId', name: 'Draw', component: DrawDetailScreen },
  { path: '/promotion', name: 'Draws', component: DrawsScreen },
  { path: '/code-redemption/:activityId', name: 'PromoCode', component: PromoCodeScreen },
  { path: '/promo-code', name: 'PromoCode', component: PromoCodeScreen },
  { path: '/upload-content/:activityId', name: 'UploadContent', component: UploadContentScreen },
  { path: '/watch-video/:activityId', name: 'WatchVideo', component: WatchVideoScreen },
  { path: '/email-signUp/:activityId', name: 'EmailSignUp', component: EmailSignUpScreen, secured: true },
  { path: '/interactive-game/:activityId', name: 'Interactive', component: InteractiveGameScreen },
  { path: '/view-url/:activityId', name: 'ViewUrl', component: ViewUrlScreen },
  { path: '/earn', name: 'Earn', component: EarnScreen },
  { path: '/history', name: 'History', component: HistoryScreen, secured: true },
  { path: '/past-history', name: 'Past History', component: PastHistoryScreen, secured: true },
  { path: '/redirect-back', name: 'RedirectBack', component: RedirectBack },
  { path: '/settings', name: 'Settings', component: SettingsScreen, secured: true },
  { path: '/register/:inviteCode?', name: 'Registration', component: UserRegistrationScreen },
  { path: '/feedback', name: 'Feedback', component: FeedbackScreen },
  { path: '/media/:pageName', name: 'Footer Page', component: MediaViewRenderer },
  { path: '/ticket-entry', name: 'TicketEntry', component: TicketScreen, secured: true },
  { path: '/invite-friends', name: 'Invite Friends', component: InviteScreen, secured: true },
  { path: '/past-draws', name: 'Past Draws', component: PastDrawsScreen },
  { path: '/past-draw-detail', name: 'Past Draws', component: PastDrawDetailScreen },
  { path: '/profile-completion/:activityId', name: 'Profile Completion', component: ProfileCompletionScreen, secured: true },
  { path: '/', name: 'Home', component: HomeScreen, exact: true },
  { component: NotFoundScreen }
];

export default indexRoutes;
