import { fieldValueTypes, IField } from '@pbl/pbl-react-core/lib/models/forms/types';
import { IUserPreference } from '@pbl/pbl-react-core/lib/models/user-registration/types';
import { formatDayMonthYear } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { conformToMask } from 'react-text-mask';
import * as yup from 'yup';
import validation, { phoneMask } from './validation';

export interface IRegistrationField<T> extends IField<T> {
  page: registrationPage;
}

export type RegistrationFields = IRegistrationField<fieldValueTypes>[];
export type registrationPage = 0 | 1 | 2;
export const nameValidationRegex: RegExp = /^[a-z ,.'-]{2,}/i;
export const zipcodeValidationRegex: RegExp = /^\d*$/;

const firstNameField: IRegistrationField<string> = {
  type: 'string',
  key: 'firstName',
  label: 'First Name',
  get placeholder(): string {
    return this.label;
  },
  page: 0,
  isRequired: true,
  maxLength: 30,
  get fieldValue() {
    return this.value;
  },
  validationSchema: yup
    .string()
    .required()
    .max(30)
    .min(2)
    .matches(/^[a-z ,.'-]+$/i),
  disabledAutofill: true
};

const lastNameField: IRegistrationField<string> = {
  type: 'string',
  key: 'lastName',
  label: 'Last Name',
  maxLength: 30,
  get placeholder(): string {
    return this.label;
  },
  page: 0,
  isRequired: true,
  get fieldValue() {
    return this.value;
  },
  validationSchema: yup
    .string()
    .required()
    .max(30)
    .min(2)
    .matches(/^[a-z ,.'-]+$/i),
  disabledAutofill: true
};

const dateOfBirthField: IRegistrationField<string> = {
  type: 'dateOfBirth',
  key: 'dateOfBirth',
  label: 'Date of Birth',
  get placeholder(): string {
    return this.label;
  },
  page: 0,
  isRequired: true,
  minValue: validation.minDateOfBirth,
  maxValue: validation.maxDateOfBirth,
  get fieldValue() {
    return !!this.value ? formatDayMonthYear(this.value) : undefined;
  },
  validationSchema: yup.date().required().min(validation.minDateOfBirth).max(validation.maxDateOfBirth)
};

const emailField: IRegistrationField<string> = {
  type: 'email',
  key: 'email',
  label: 'Email',
  maxLength: 100,
  get placeholder(): string {
    return this.label;
  },
  page: 0,
  isRequired: true,
  get fieldValue() {
    return this.value;
  },
  validationSchema: yup.string().required().email().max(100),
  disabledAutofill: true
};

const passwordField: IRegistrationField<string> = {
  type: 'password',
  key: 'password',
  label: 'Password',
  get placeholder(): string {
    return this.label;
  },
  page: 0,
  isRequired: true,
  showPassword: true,
  secureTextEntry: true,
  maxLength: 20,
  get fieldValue() {
    return this.value;
  },
  validationSchema: yup
    .string()
    .test(
      'password-validator',
      'Enter valid password',
      value => value !== null && !!validation.passwordValidatorSchema.validate(value as string)
    )
    .required()
    .max(20),
  disabledAutofill: true
};

const genderField: IRegistrationField<string> = {
  type: 'radio',
  key: 'gender',
  label: 'Gender',
  get placeholder(): string {
    return this.label;
  },
  page: 1,
  isRequired: true,
  options: [
    {
      value: 'FEMALE',
      label: 'Female'
    },
    {
      value: 'MALE',
      label: 'Male'
    },
    {
      value: 'NEUTRAL',
      label: 'Prefer not to say'
    }
  ],
  get fieldValue() {
    return this.value;
  },
  validationSchema: yup.object().shape({
    gender: yup.boolean().required()
  })
};

const phoneField: IRegistrationField<string> = {
  type: 'phone',
  key: 'phone',
  label: 'Phone Number',
  get placeholder(): string {
    return this.label;
  },
  page: 1,
  isRequired: true,
  get fieldValue() {
    if (!!this.value && this.value.toString().length > 0) {
      return conformToMask(this.value, phoneMask, {}).conformedValue;
    }
    return this.value;
  },
  validationSchema: yup.string().required().matches(validation.phoneValidationRegex)
};

const sendUpdatesField: IRegistrationField<string> = {
  type: 'boolean',
  key: 'sendUpdates',
  label: ' Yes, send me updates and offers via email',
  get placeholder(): string {
    return this.label;
  },
  page: 0,
  isMultiline: true,
  isRequired: false,
  get fieldValue() {
    return this.value !== null ? this.value.toString() : null;
  }
};

const invitationCodeField: IRegistrationField<string> = {
  type: 'string',
  key: 'inviteCode',
  label: 'Invite Code',
  get placeholder(): string {
    return this.label;
  },
  page: 0,
  isRequired: false,
  maxLength: 30,
  get fieldValue() {
    return this.value;
  }
};

export const preferences: IUserPreference[] = [{ key: 'PROMOTIONS_EMAIL', value: '1', channel: 'EMAIL' }];

export const registrationFields: RegistrationFields = [
  firstNameField,
  lastNameField,
  dateOfBirthField,
  emailField,
  passwordField,
  invitationCodeField,
  sendUpdatesField,
  phoneField,
  genderField
];
