import { Button, Icon, TextField, Typography, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from 'assets/jss/modules/forgot-password/ForgotPasswordFormStyle';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { forgotPasswordSchema } from 'modules/authentication/forgot-password/components/validations';
import { withTranslation, WithTranslation } from 'react-i18next';
import { sendPasswordResetInstructions } from 'redux/reducers/authentication/actions';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface IForgotPasswordFormProps extends StateProps, DispatchProps, RouteComponentProps, WithTranslation, WithStyles<typeof styles> {}

interface IForgotPasswordFormState {
  email: string;
  formIsValid: boolean;
}

class ForgotPasswordForm extends React.Component<IForgotPasswordFormProps, IForgotPasswordFormState> {
  public state: IForgotPasswordFormState = {
    email: '',
    formIsValid: false
  };

  public render() {
    const { email, formIsValid } = this.state;
    const { classes, t } = this.props;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem sm={10} md={11}>
            <div className={classes.content}>
              <div className={classes.text}>
                <Typography color="textPrimary" variant="subtitle2">
                  {t('forgotPassword.title')}
                </Typography>
              </div>
              <div className={classes.text}>
                <Typography color="textPrimary" variant="body2">
                  {t('forgotPassword.text1')}
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {t('forgotPassword.text2')}
                </Typography>
              </div>
              <TextField
                label="Email*"
                aria-label="Enter email address"
                type="email"
                autoComplete="email"
                variant="filled"
                fullWidth={true}
                id="email"
                value={email}
                onChange={this.onEmailChange}
                autoFocus={true}
              />
              <Button
                className={classes.forgotButton}
                size="large"
                variant="contained"
                fullWidth={true}
                disabled={!formIsValid}
                color="primary"
                onClick={this.onSubmit}
                aria-label="Forgot password button. Click this to send recovery instructions to your email."
              >
                {t('forgotPassword.submitBtn')}
              </Button>
              <div className={classes.register}>
                <Typography color="textPrimary" variant="body2">
                  {t('forgotPassword.text3')}
                </Typography>
                <Button onClick={this.onNavigate.bind(this, '/register')} aria-label="click here to go to registration page">
                  <Typography variant="body2" className={classes.primaryText}>
                    {t('forgotPassword.registerBtn')}
                  </Typography>
                  <Icon color="primary">how_to_reg</Icon>
                </Button>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  private onSubmit = async () => {
    const { email } = this.state;
    this.props.sendPasswordResetInstructions(email);
  };

  private onNavigate = (page: string) => {
    this.props.history.push(page);
  };

  private onEmailChange = event => {
    const value = event.target.value;
    const formIsValid = forgotPasswordSchema.isValidSync({ email: value });
    this.setState({ formIsValid, email: value });
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  sendPasswordResetInstructions
};
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(withStyles(styles)(ForgotPasswordForm))));
