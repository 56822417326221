import { Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles } from '@material-ui/core/styles';
import { IDrawPage } from '@pbl/pbl-react-core/lib/models/draws/types';
import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import { IExtraData } from '@pbl/pbl-react-core/lib/models/ledger/types';
import { IRequestParams } from '@pbl/pbl-react-core/lib/services/types';
import { formatShortMonthDayShortTime } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import ArcadeHeader from '@pbl/pbl-react-web-components/lib/arcade/ArcadeHeader';
import { FilterSection, Pagination, SelectedFiltersSection } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/arcade/HistoryScreenStyle';
import constants from 'config/constants';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { replaceFilters, resetState, toggleFilter } from 'redux/reducers/arcade/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { fetchUserTokens, getTransactionHistory } from 'redux/reducers/ledger/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { getEntries } from 'utils/historyUtil';

type PropsConnected = ConnectedProps<typeof connector>;

export interface ITransactionHistoryScreenProps extends PropsConnected, WithStyles<typeof styles>, WithTranslation, RouteComponentProps {
  theme: Theme;
}

interface ITransactionHistoryScreenState {
  loading: boolean;
  selectedFilters: string[] | undefined;
  customDateRange: any;
}

const getPagination = (dispatchFetchPage: (page: number) => void, classes: any, page: IDrawPage): JSX.Element => (
  <Grid item={true} xs={12} className={classes.centered}>
    <Pagination
      breaks={true}
      first={page.first}
      last={page.last}
      activePage={page.number}
      totalPages={page.totalPages}
      onSelect={dispatchFetchPage}
      maxButtons={4}
      activeBtnColor={'secondary'}
      marginPagesDisplayed={0}
      buttonStyle={classes.buttonStyle}
    />
  </Grid>
);

class TransactionHistoryScreen extends React.Component<ITransactionHistoryScreenProps, ITransactionHistoryScreenState> {
  private newActivitiesSize = constants.PAST_DRAWS_PER_PAGE;

  constructor(props: ITransactionHistoryScreenProps) {
    super(props);
    this.state = {
      loading: false
    } as ITransactionHistoryScreenState;
  }

  public async componentDidMount() {
    document.title = 'Lucky Lounge';
    this.setState({ loading: true });
    this.props.resetState();
    // @ts-ignore
    if (this.props.loggedIn) {
      await this.props.fetchUserTokens();
      await this.props.getTransactionHistory({ page: 0, size: 10 } as IRequestParams);
    }
    this.setState({ loading: false });
  }

  public navigateToBuyScreen = (): void => {
    this.props.history.push('/lucky-lounge/store');
  };

  private navigateToArcadeScreen = (): void => {
    this.props.history.push('/lucky-lounge');
  };

  public dispatchFetchPage = async pageNumber => {
    let filters = '';
    if (this.state.selectedFilters) {
      const types = `historyTypes=${this.state.selectedFilters.join(',')}`;
      filters = `${types}`;
    }
    if (this.state?.customDateRange?.startDate && this.state?.customDateRange?.endDate) {
      filters = `startDate=${this.state?.customDateRange?.startDate}&endDate=${this.state?.customDateRange?.endDate}&${filters}`;
    }
    await this.props.getTransactionHistory(
      {
        page: pageNumber,
        size: this.newActivitiesSize,
        filters
      } as IRequestParams);
  };

  public getEntries = (extraData?: IExtraData[]) => {
    if (!extraData || extraData.length === 0) return '';
    return getEntries(extraData);
  };

  public getPurchasedAmount = (extraData?: IExtraData[]) => {
    if (!extraData || extraData.length === 0) return '';
    const amount = extraData.find(a => a.key === 'purchasedAmount');
    if (amount) {
      return `$${Math.round(parseFloat(amount.value))} `;
    } else {
      return '';
    }
  }

  private updateFilters = (filters: IFilters) => {
    const selectedFilters = filters
      ?.HistoryType
      ?.filters
      ?.filter(x => x.isSelected)
      ?.map(x => x.value as string);

    const filterDates = filters
      ?.DateFilter
      ?.filters
      ?.filter(x => x.isSelected)
      ?.map(x => ({
        endDate: x.customDateRangeEnd,
        startDate: x.customDateRangeStart
      }));

    let startDate: string | undefined;
    let endDate: string | undefined;

    if (filterDates && filterDates.length > 0) {
      startDate = filterDates[0]?.startDate?.format('YYYY-MM-DD');
      endDate = filterDates[0]?.endDate?.format('YYYY-MM-DD');
    }

    this.setState({ selectedFilters, customDateRange: { startDate, endDate } });

    let filtersParam = '';
    if (selectedFilters && selectedFilters.length > 0) {
      const types = `historyTypes=${selectedFilters.join(',')}`;
      filtersParam = `${types}`;
    }
    if (startDate && endDate) {
      filtersParam = `startDate=${startDate}&endDate=${endDate}&${filtersParam}`;
    }

    this.props.getTransactionHistory({ page: 0, size: this.newActivitiesSize, filters: filtersParam } as IRequestParams);
  };

  private onFilterDone = async (filters: IFilters) => {
    await this.props.replaceFilters(filters);
    this.updateFilters(filters);
  }

  private onRemoveSelectedFilter = (entityName: string, sectionKey: string, filterKey: string) => {
    this.props.toggleFilter(entityName, sectionKey, filterKey);
    this.updateFilters(this.props.filters);
  };

  private renderFilterSelectedSection = (): React.ReactNode => {
    const filters = this.props.filters;
    if (filters === null) {
      return null;
    }

    return <SelectedFiltersSection filters={filters} entityName="arcade" darkMode="true" onRemoveSelected={this.onRemoveSelectedFilter} />;
  };

  public render() {
    const { classes, userTokens, loggedIn, transactionHistory, page, t } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <AppSpinner label={'Loading...'} />;
    }
    return (
      <div className={classes.container}>
        <ScrollToTopOnMount />
        <Grid container={true} className={classes.gridContainer}>
          <Grid item={true} xs={12}>
            <ArcadeHeader
              navigateToBuyScreen={this.navigateToBuyScreen}
              onNavigate={this.navigateToArcadeScreen}
              balance={userTokens.balance}
              title={'Lucky Lounge History'}
              isLoggedIn={loggedIn}
              disableHistoryButton={true}
            />
          </Grid>
          <Grid style={{
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
            marginBottom: '20px'
          }}>
            <FilterSection
              entityName="history"
              title="Filter History"
              filterTitle="Filter History"
              filters={this.props.filters}
              onOk={this.onFilterDone}
              onRemoveSelected={this.onRemoveSelectedFilter}
              onlyfilter={true}
              darkMode={true}
            />
          </Grid>
          <Grid>
            {this.renderFilterSelectedSection()}
          </Grid>
          {transactionHistory.length > 0 ?
            (<Grid container={true} className={classes.tableBackground}>
            {getPagination(this.dispatchFetchPage, classes, page)}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <Typography color="textSecondary" variant="subtitle2">
                      {t('arcade.date')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Typography color="textSecondary" variant="subtitle2">
                      {t('arcade.description')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.historyCell}>
                    <Typography color="textSecondary" variant="subtitle2">
                      {t('arcade.tokens')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionHistory.map(transaction => (
                  <TableRow key={transaction.referenceId}>
                    <TableCell className={classes.cell}>
                      <Typography color="textSecondary" variant="body2">
                        {!!transaction.date ? formatShortMonthDayShortTime(transaction.date) : ''}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography color="textSecondary" variant="body2">
                        {this.getPurchasedAmount(transaction.extraData) + transaction.description + this.getEntries(transaction.extraData)}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.historyCell}>
                      {transaction.type && transaction.type.toString() === 'DEBIT' ? (
                        <Typography color="textSecondary" variant="body2">
                          - {transaction.amount}
                        </Typography>
                      ) : (
                        <Typography color="textSecondary" variant="body2">
                          + {transaction.amount}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {getPagination(this.dispatchFetchPage, classes, page)}
            </Grid>) : (<Grid container={true} justify="center" item={true}>
              <Typography color={'textPrimary'}>
                {t('arcade.noResults')}
              </Typography>
            </Grid>) }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication: { account, accessToken }, ledger: { userTokens, transactionHistory, page }, arcades: { filters } }: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;

  return {
    loggedIn,
    userTokens,
    transactionHistory,
    page,
    filters
  };
};

const mapDispatchToProps = {
  isAuthenticated,
  fetchUserTokens,
  getTransactionHistory,
  toggleFilter,
  replaceFilters,
  resetState
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTranslation()(TransactionHistoryScreen))));
