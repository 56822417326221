import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core';
import { IPromotion } from '@pbl/pbl-react-core/lib/models/draws/types';
import { IReward } from '@pbl/pbl-react-core/lib/models/reward/types';
import { AppSpinner } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import homeScreenEarnActivities from 'config/homeScreenEarnActivities';
import DrawsSection from 'modules/home/components/DrawsSection';
import EarnSection from 'modules/home/components/EarnSection';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import {
  fetchHomePageActivities,
  fetchHomePageBonus,
  fetchHomePageFeaturedActivity,
  fetchHomePageUserActivities,
  fetchHomePageUserFeaturedActivity
} from 'redux/reducers/activity/actions';
import { clearLoading, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import {
  fetchHomePageProgressivePrizes,
  fetchHomePageProgressivePromotions,
  fetchHomePagePromotions,
  selectPromotion
} from 'redux/reducers/draws/actions';
import { fetchUserPointsWithExpiry } from 'redux/reducers/ledger/actions';
import { fetchUserActivitiesWithInterests, getActivity } from 'redux/reducers/loyalty/actions';
import { getAdvertisements } from 'redux/reducers/media/actions';
import { fetchHomePageRewards, fetchHomePageUserRewards } from 'redux/reducers/reward/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import displayOutageBanner from 'utils/outageUtils';
import FeaturedSlider from './components/FeaturedSlider';
import InformationOnlySection from './components/InformationOnlySection';
import IntroSection from './components/IntroSection';
import RewardsSection from './components/RewardsSection';

type PropsConnected = ConnectedProps<typeof connector>;

export interface IHomeScreenProps extends PropsConnected, RouteComponentProps, WithStyles<typeof styles> {
  theme: Theme;
}

class HomeScreen extends React.Component<IHomeScreenProps, { fetching: boolean }> {
  private prizeCheck: any | null = null;
  constructor(props: IHomeScreenProps) {
    super(props);
    this.state = { fetching: false };
  }

  public async componentDidMount() {
    document.title = `${constants.PROJECT_NAME} - Home`;

    const { showOutageBanner, body, displayTime } = await displayOutageBanner();
    if (showOutageBanner) {
      this.props.showMessageBar({
        type: 'outage_warning',
        message: body,
        messageTimeout: displayTime
      });
    }

    this.setState({ fetching: true });
    await this.props.isAuthenticated();
    await this.getData();
    this.prizeCheck = setInterval(this.updateProgressivePrize, constants.HOME_SCREEN_FETCH_DRAW_INTERVAL);
    this.setState({ fetching: false });
  }

  public getData = async (): Promise<void> => {
    const requests: any = [];
    requests.push(this.props.getAdvertisements());
    requests.push(this.props.fetchHomePageProgressivePromotions());
    requests.push(this.props.fetchHomePageProgressivePrizes());
    requests.push(this.props.fetchHomePagePromotions());
    requests.push(this.props.fetchHomePageBonus());

    if (this.props.loggedIn) {
      requests.push(this.props.fetchHomePageUserRewards());
      requests.push(this.props.fetchHomePageUserActivities());
      requests.push(this.props.fetchHomePageUserFeaturedActivity());
      requests.push(this.props.fetchUserPointsWithExpiry());
    } else {
      requests.push(this.props.fetchHomePageRewards());
      requests.push(this.props.fetchHomePageActivities());
      requests.push(this.props.fetchHomePageFeaturedActivity());
    }
    await Promise.all(requests);
  };

  public componentWillUnmount = async () => {
    if (!!this.prizeCheck) {
      clearInterval(this.prizeCheck);
    }
    this.prizeCheck = null;
  };

  public updateProgressivePrize = async () => {
    if (this.props.progressive && this.props.progressive.length > 0) {
      await this.props.fetchHomePageProgressivePrizes();
    } else {
      if (!!this.prizeCheck) {
        clearInterval(this.prizeCheck);
      }
      this.prizeCheck = null;
    }
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    const { fetching } = this.state;
    return !fetching ? (
      <div className={classes.root}>
        <ScrollToTopOnMount />
        <h1 style={{ display: 'none' }}>Home Page</h1>
        {this.renderFeaturedSlide()}
        <div className={classNames(classes.main, classes.mainRaised, classes.container)}>
          {this.renderIntroSection()}
          {this.renderRewardsSection()}
          {this.renderEarnSection()}
          {this.renderDrawsSection()}
          {this.renderInformationOnlySection()}
        </div>
      </div>
    ) : (
      <AppSpinner label={'Loading'} />
    );
  }

  private renderIntroSection = (): React.ReactNode => <IntroSection />;

  private renderFeaturedSlide = (): React.ReactNode => {
    const { advertisements } = this.props;
    const featuredContent = advertisements.map(advert => ({
      id: advert.id,
      title: advert.title,
      image: advert.imageUrl,
      btnText: advert.buttonText,
      url: advert.buttonUrl,
      target: advert.buttonTarget,
      backgroundImage: advert.backgroundImage,
      textColor: advert.textColor
    }));
    if (!!advertisements && advertisements.length > 0) {
      return <FeaturedSlider content={featuredContent} />;
    }
    return null;
  };

  private renderRewardsSection = (): React.ReactNode => {
    const { rewards } = this.props;

    if (!!rewards && rewards.length > 0) {
      return <RewardsSection rewards={rewards} showSoldOutTag={constants.SHOW_COUPON_SOLD_OUT_BANNER} />;
    }
    return null;
  };

  private renderInformationOnlySection = (): React.ReactNode => (
    <InformationOnlySection onCardClicked={this.navigateToInformationOnlyList} />
  );

  private renderEarnSection = (): React.ReactNode => {
    const { earnActivities, bonusActivity } = this.props;

    if (!!homeScreenEarnActivities && homeScreenEarnActivities.length > 0 && earnActivities) {
      return <EarnSection activities={homeScreenEarnActivities} earnActivities={earnActivities} bonusActivity={bonusActivity} />;
    }

    return null;
  };

  private onCardClicked = (promotion: IPromotion): void => {
    this.props.selectPromotion(promotion);
    this.props.history.push('/promotion/' + promotion.id);
  };

  private navigateToInformationOnlyList = () => {
    this.props.history.push('/information-list');
  };

  private renderDrawsSection = (): React.ReactNode => {
    const { promotions, progressive } = this.props;
    if (!!promotions && promotions.length > 0) {
      return <DrawsSection promotions={promotions} progressive={progressive} onDrawCardClicked={this.onCardClicked} />;
    }
    return null;
  };
}

const sortRewards = (content: IReward[]) => {
  const toSort = [...content];
  return toSort.sort((x: IReward, y: IReward) => {
    const dateCreatedX = !!x.createdDate ? parseInt(x.createdDate.toString(), 10) : 0;
    const dateCreatedY = !!y.createdDate ? parseInt(y.createdDate.toString(), 10) : 0;
    const featuredX = x.featured ? dateCreatedX * 2 + 1000000 : 0;
    const featuredY = y.featured ? dateCreatedY * 2 + 1000000 : 0;
    return dateCreatedY + featuredY - (dateCreatedX + featuredX);
  });
};

const mapStateToProps = ({
  authentication: { account, accessToken },
  feature: { authorizedFlags },
  media: { advertisements },
  authentication,
  draws: { homePromotions, homeProgressivePromotions },
  reward: { rewardsList },
  activityState: { activityList, featured, bonusActivity }
}: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  const currentRewards = rewardsList ? sortRewards(rewardsList) : [];
  const currentActivities = featured && activityList ? [...featured, ...activityList] : activityList;

  return {
    rewards: currentRewards,
    earnActivities: currentActivities,
    authentication,
    promotions: homePromotions,
    progressive: homeProgressivePromotions,
    loggedIn,
    advertisements,
    authorizedFlags,
    bonusActivity: bonusActivity ?? []
  };
};

const mapDispatchToProps = {
  fetchHomePageRewards,
  fetchHomePageUserRewards,
  fetchHomePageActivities,
  fetchHomePageFeaturedActivity,
  fetchUserActivitiesWithInterests,
  fetchHomePagePromotions,
  fetchHomePageProgressivePromotions,
  fetchHomePageProgressivePrizes,
  toggleLoading,
  clearLoading,
  selectPromotion,
  isAuthenticated,
  getAdvertisements,
  getActivity,
  showMessageBar,
  fetchHomePageUserActivities,
  fetchHomePageUserFeaturedActivity,
  fetchUserPointsWithExpiry,
  fetchHomePageBonus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(HomeScreen))));
