import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { IErrorDTO } from '@pbl/pbl-react-core/lib/models/app';
import { IResponse, ISubmitResponse, ISurveyResult } from '@pbl/pbl-react-core/lib/models/survey/pbl/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import SurveyContent from '@pbl/pbl-react-web-components/lib/survey/pbl/SurveyContent';
import styles from 'assets/jss/modules/settings/interests/InterestsScreenStyle';
import constants from 'config/constants';
import { useActivityDetailsDataByKey } from 'modules/earn/hooks/useActivityDetailsDataByKey';
import { useSurveyDetails } from 'modules/survey/hooks/useSurveyDetails';
import React, { useCallback, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { fetchUserPoints } from 'redux/reducers/ledger/actions';
import { submit } from 'redux/reducers/survey/action';
import { useIsLoggedIn } from '../../../hooks/useIsLoggedIn';

interface IInterestsScreenProps extends WithStyles<typeof styles>, WithTranslation {}

const InterestsScreen: React.FunctionComponent<IInterestsScreenProps> = ({ t }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [userResponse, setUserResponse] = useState<ISubmitResponse>({} as ISubmitResponse);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [surveyResult, setSurveyResult] = useState<ISurveyResult>({} as ISurveyResult);

  const showInvalidError = useCallback(() => {
    dispatch(
      showMessageBar({
        message: t('activities.activityUnavailable'),
        type: 'warning',
        messageTimeout: 3000
      })
    );
  }, [dispatch, t]);

  const { activity } = useActivityDetailsDataByKey(constants.INTERESTS_SURVEY.ACTIVITY_KEY, showInvalidError, setLoading);
  const { survey, playerResponses } = useSurveyDetails(showInvalidError, activity?.key);
  const loggedIn: boolean = useIsLoggedIn();

  useEffect(() => {
    setUserResponse({ surveyId: survey?.id ?? 0, responses: playerResponses ?? [] });
  }, [survey, playerResponses]);

  if (!survey || !activity) {
    return null;
  }

  const onChange = (answer: IResponse) => {
    if (!survey) return;
    let currentResponses = userResponse.responses ?? [];
    currentResponses = currentResponses.filter(x => x.questionId !== answer.questionId);
    currentResponses.push(answer);
    setUserResponse({ surveyId: survey.id, responses: currentResponses });
    setIsFormDirty(true);
  };

  const postSurvey = async () => {
    if (!loggedIn) {
      navigate('/login', { from: pathname });
      return;
    }
    setLoading(true);
    const responses = userResponse.responses;
    responses.forEach(item => (item.responseText ? (item.options = undefined) : item));
    await submit(userResponse).then(data => validateResponse(data));
    dispatch(fetchUserPoints());
    setLoading(false);
  };

  const validateResponse = async (data: ISurveyResult | IErrorDTO) => {
    if (data && 'errorKey' in data && data.errorKey) {
      dispatch(
        showMessageBar({
          message: data.errorKey,
          type: 'error',
          messageTimeout: 10000
        })
      );
    } else if (data && activity) {
      setSurveyResult(data as ISurveyResult);
      setIsFormDirty(false);
      if ('points' in data && data.points === 0) {
        dispatch(showMessageBar({ message: 'interests.updated' }));
      } else {
        setShowModal(true);
      }
    }
  };

  const onDismissEarnPointsModal = (): void => {
    setShowModal(false);
  };

  return (
    <Grid container={true} spacing={3} direction="column">
      <Prompt when={isFormDirty} message={t('interests.promptMessage')} />
      {surveyResult && surveyResult.points ? (
        <EarnPointsModal pointEarned={surveyResult.points} isVisible={showModal} onClose={onDismissEarnPointsModal} />
      ) : null}
      {loading ? (
        <AppSpinner label={'Loading Interests'} />
      ) : (
        <SurveyContent
          onSaveSurvey={postSurvey}
          onChange={onChange}
          survey={survey}
          playerResponses={userResponse.responses}
          isLoading={loading}
          isSystemSurvey={true}
          bonusPoints={activity && activity.bonusAmount ? activity.bonusAmount + activity.amount : 0}
          amount={activity?.amount}
          limitReached={playerResponses && playerResponses.length > 0}
        />
      )}
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(InterestsScreen));
