import { IReward, RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import constants from 'config/constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'redux/reducers';
import { fetchRewards, fetchUserRewards } from 'redux/reducers/reward/actions';

export const useRewardActiveList = () => {
    const { accessToken, account } = useSelector((state: IRootState) => state.authentication);
    const loggedIn = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
    const [rewardTabsVisibility, setRewardTabsVisibility] = useState<any | undefined>([]);

    useEffect(() => {
        const fetchDataForRewardTypes = async () => {
          for (const type of constants.REWARD_TYPES.split(',')) {
            const shouldShowTab =
              type === RewardType.COUPON ||
              type === RewardType.GAMEPLAY ||
              type === RewardType.EXTERNAL
                ? (await (loggedIn ? fetchUserRewards : fetchRewards)(false, false, type as RewardType) as any as IReward[]).length > 0
                : true;

            setRewardTabsVisibility(prevState => ({
              ...prevState,
              [type]: shouldShowTab
            }));
          }
          };
          fetchDataForRewardTypes();
      }, []);
  return { rewardTabsVisibility };
};
