import { TextField, WithStyles, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ICity, ICountry, IState } from '@pbl/pbl-react-core/lib/models/address';
import { Address } from '@pbl/pbl-react-core/lib/models/forms';
import { TextFieldValidator } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/shared/components/input/AddressStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import validation from 'config/validation';
import { addressValidator, zipCodeValidator } from 'modules/settings/profile/validations';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as yup from 'yup';

interface IAddressProps extends WithStyles<typeof styles>, WithTranslation {
  address: Address;
  handleTextFieldChange: (field: string, value: string) => any;
  contentClass?: any;
  fieldClass?: any;
  fieldAutocompleteClass?: any;
  fieldVariant: 'filled' | 'standard' | 'outlined';
  getStates: (countryId: number) => any;
  getCities: (stateId: number) => any;
  resetStates: () => any;
  resetCities: () => any;
  countries?: ICountry[];
  states?: IState[];
  cities?: ICity[];
  loadingCities: boolean;
  showMandatory?: boolean;
  address1Error?: boolean;
  address2Error?: boolean;
  zipCodeError?: boolean;
  countryError?: boolean;
  stateError?: boolean;
  cityError?: boolean;
  disable?: boolean;
}

interface IAddressState {
  selectedCountry: ICountry | null;
  selectedCity?: ICity | null;
  selectedState?: IState | null;
  isTouched?: boolean;
}

class AddressComponent extends React.Component<IAddressProps, IAddressState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: null,
      selectedCity: null,
      selectedState: null,
      isTouched: false
    };
  }

  private updateAddressState = async () => {
    const { address } = this.props;
    if (address !== null) {
      if (address.country && this.props.countries) {
        const selectedCountry = this.props.countries.find((item: ICountry) => item.name === address.country);
        if (selectedCountry) {
          await this.props.getStates(selectedCountry.id);
          await this.setState({ selectedCountry });
        }
      } else if (address.countryCode && this.props.countries) {
        const selectedCountry = this.props.countries.find(item => item.iso2.toUpperCase() === address.countryCode.toUpperCase());
        if (selectedCountry) {
          await this.props.getStates(selectedCountry.id);
          await this.setState({ selectedCountry });
        }
      } else if (constants.DEFAULT_COUNTRY_CODE && this.props.countries) {
        const selectedCountry = this.props.countries.find(item => item.iso2.toUpperCase() === constants.DEFAULT_COUNTRY_CODE);
        if (selectedCountry) {
          this.props.handleTextFieldChange('countryCode', selectedCountry.iso2);
          this.props.handleTextFieldChange('country', selectedCountry.name);
          await this.props.getStates(selectedCountry.id);
          await this.setState({ selectedCountry });
        }
      }
      if (address.state && this.props.states) {
        const selectedState = this.props.states.find((item: IState) => item.name === address.state || item.stateCode === address.state);
        if (selectedState) {
          this.props.handleTextFieldChange('stateCode', selectedState.stateCode);
          address.state = selectedState.name;
          await this.props.getCities(selectedState.id);
          const selectedCity = this.props.cities ? this.props.cities.find((item: ICity) => item.name === address.city) : null;
          await this.setState({ selectedState, selectedCity });
        }
      } else if (constants.DEFAULT_STATE_CODE && this.props.states) {
        const selectedState = this.props.states.find((item: IState) => item.stateCode === constants.DEFAULT_STATE_CODE);
        if (selectedState) {
          this.props.handleTextFieldChange('stateCode', selectedState.stateCode);
          address.state = selectedState.name;
          await this.props.getCities(selectedState.id);
          await this.setState({ selectedState });
        }
      }
    }
  };

  public componentDidUpdate = async (prevProps: Readonly<IAddressProps>) => {
    if (this.props.address !== prevProps.address || this.props.countries !== prevProps.countries) {
      await this.updateAddressState();
    }
  };

  public componentDidMount = async () => {
    await this.updateAddressState();
  };

  public handleChangeCountry = async (_event: any, value: any, _reason: string) => {
    if (!this.props.countries) return;
    this.props.handleTextFieldChange('country', value ? value.name : '');
    this.props.handleTextFieldChange('state', '');
    this.props.handleTextFieldChange('city', '');
    this.setState({ isTouched: true });
    await this.setState({ selectedState: null, selectedCity: null });
    if (value) {
      const selectedCountry = this.props.countries.find((item: ICountry) => item.name === value.name);
      if (selectedCountry) {
        this.props.handleTextFieldChange('countryCode', selectedCountry.iso2);
        await this.props.getStates(selectedCountry.id);
        this.setState({ selectedCountry });
      }
    } else {
      this.props.handleTextFieldChange('countryCode', '');
      await this.props.resetStates();
      await this.setState({ selectedState: null, selectedCity: null, selectedCountry: null });
    }
  };

  public handleChangeState = async (_event: any, value: any, _reason: string) => {
    if (!this.props.states) return;
    this.props.handleTextFieldChange('state', value ? value.name : '');
    this.props.handleTextFieldChange('city', '');
    this.setState({ isTouched: true });
    await this.setState({ selectedCity: null });
    if (value) {
      const selectedState = this.props.states.find((item: IState) => item.name === value.name);
      if (selectedState) {
        this.props.handleTextFieldChange('stateCode', selectedState.stateCode);
        await this.props.getCities(selectedState.id);
        this.setState({ selectedState });
      }
    } else {
      this.props.handleTextFieldChange('stateCode', '');
      await this.props.resetCities();
      await this.setState({ selectedState: null, selectedCity: null });
    }
  };
  public handleChangeCity = (_event: any, value: any, _reason: string) => {
    this.props.handleTextFieldChange('city', value ? value.name : '');
    this.setState({ isTouched: true });
    this.setState({ selectedCity: value });
  };

  public hasError = (value: any) => {
    if (!this.state.isTouched) {
      return false;
    }
    if (value) {
      return false;
    }
    return true;
  };

  public getOptionLabel = (option: any) => option.name;
  public renderOption = (option: any) => <React.Fragment>{option.name}</React.Fragment>;
  private inputHasError = (field: string): boolean | undefined => {
    switch (field) {
      case 'Country':
        return this.props.countryError;
      case 'State':
        return this.props.stateError;
      case 'City':
        return this.props.cityError;
      default:
        return undefined;
    }
  };
  public renderInput = (value: any, label: string, params: any) => {
    const error = this.inputHasError(label);
    return (
      <TextFieldValidator
        key={label}
        {...params}
        label={this.props.showMandatory && label !== 'Country' ? label + ' *' : label}
        variant={this.props.fieldVariant}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'country',
          'aria-required': this.props.showMandatory
        }}
        validationSchema={this.props.showMandatory ? yup.string().required() : undefined}
        value={value ? value.name : undefined}
        error={error}
        helperText={'Please enter a valid ' + label}
      />
    );
  };

  public render(): React.ReactNode {
    const {
      classes,
      address,
      handleTextFieldChange,
      contentClass,
      fieldVariant,
      fieldClass,
      fieldAutocompleteClass,
      showMandatory,
      disable
    } = this.props;
    const handleChange = (event: any) => {
      handleTextFieldChange(event.target.id, event.target.value);
    };
    return (
      <div className={contentClass}>
        <TextFieldValidator
          id="address1"
          label={showMandatory ? this.props.t('authentication.required.address1', 'Address 1 *') : 'Address 1'}
          className={classNames(fieldClass, classes.field)}
          value={address.address1}
          inputProps={{ maxLength: validation.maxAddressLength, 'aria-required': showMandatory }}
          onChange={handleChange}
          validationSchema={this.props.showMandatory ? addressValidator : undefined}
          variant={fieldVariant}
          error={this.props.address1Error}
          helperText={'Please enter a valid Address 1/PO Box'}
          autoComplete={'new-password'}
          disabled={disable ? disable : false}
        />
        <TextField
          id="address2"
          label="Address 2"
          className={classNames(fieldClass, classes.field)}
          value={address.address2}
          inputProps={{ maxLength: validation.maxAddressLength }}
          onChange={handleChange}
          variant={fieldVariant}
          error={this.props.address2Error}
          autoComplete={'new-password'}
          disabled={disable ? disable : false}
        />
        <TextFieldValidator
          id="zipCode"
          label={showMandatory ? 'Zip Code *' : 'Zip Code'}
          className={classNames(fieldClass, classes.field)}
          value={address.zipCode}
          inputProps={{ maxLength: validation.maxZipCodeLength, 'aria-required': showMandatory }}
          onChange={handleChange}
          validationSchema={this.props.showMandatory ? zipCodeValidator : undefined}
          variant={fieldVariant}
          error={this.props.zipCodeError}
          helperText={'Please enter a valid Zip Code'}
          autoComplete={'off'}
          disabled={disable ? disable : false}
        />
        <Autocomplete
          className={classNames(fieldAutocompleteClass, classes.fieldAutocomplete)}
          id="country"
          aria-label={`${this.state.selectedCountry ? this.state.selectedCountry.name : ''}`}
          freeSolo={true}
          options={this.props.countries ? this.props.countries : []}
          autoHighlight={true}
          onChange={this.handleChangeCountry}
          getOptionLabel={this.getOptionLabel}
          renderOption={this.renderOption}
          renderInput={this.renderInput.bind(this, this.state.selectedCountry, 'Country')}
          value={address.countryCode || address.country ? this.state.selectedCountry : null}
          disabled={true}
        />
        <Autocomplete
          className={classNames(fieldAutocompleteClass, classes.fieldAutocomplete)}
          id="state"
          options={this.props.states ? this.props.states : []}
          autoHighlight={true}
          aria-label={`${this.state.selectedState ? this.state.selectedState.name : ''}`}
          onChange={this.handleChangeState}
          getOptionLabel={this.getOptionLabel}
          renderOption={this.renderOption}
          renderInput={this.renderInput.bind(this, this.state.selectedState, 'State')}
          value={address.state ? this.state.selectedState : null}
          disabled={disable ? disable : false}
        />
        <Autocomplete
          className={classNames(fieldClass, classes.field)}
          id="city"
          options={this.props.cities ? this.props.cities : []}
          autoHighlight={true}
          aria-label={`${this.state.selectedCity ? this.state.selectedCity.name : ''}`}
          onChange={this.handleChangeCity}
          getOptionLabel={this.getOptionLabel}
          renderOption={this.renderOption}
          renderInput={this.renderInput.bind(this, this.state.selectedCity, 'City')}
          value={address.city ? this.state.selectedCity : null}
          noOptionsText={this.props.loadingCities ? 'Loading...' : 'No Options'}
          disabled={disable ? disable : false}
        />
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(AddressComponent));
