import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import { useCallback, useEffect, useState } from 'react';
import { getActivity, getActivityWithFreqCap, getPromoCodeActivity } from 'redux/reducers/activity/actions';
import { useIsLoggedIn } from '../../../hooks/useIsLoggedIn';

export const useActivityDetailsData = (activityId: string, onError: () => void) => {
  const isLoggedIn = useIsLoggedIn();
  const [activity, setActivity] = useState<IActivity>();

  const initData = useCallback(async () => {
    const fetchFunction = isLoggedIn ? getActivityWithFreqCap : getActivity;
    const data = !isNaN(Number(activityId)) ? await fetchFunction(Number(activityId)) : await getPromoCodeActivity(isLoggedIn);
    if (!data) {
      onError();
      return;
    }

    setActivity(data);
  }, [activityId, isLoggedIn, onError]);

  useEffect(() => {
    initData();
  }, [initData]);

  return { activity, initData };
};
