import {
  CHECK_EMAIL,
  ERROR_RESET,
  IS_VALID_INVITE_CODE,
  REGISTER_PLAYER,
  RESET,
  RESET_ADDITIONAL_INFORMATION,
  UPDATE_INVITE_CODE_VISIBILITY,
  UPDATE_SHOW_CAPTCHA,
  UPDATE_STEPPER,
  UPDATE_USER_REGISTRATION_DATA_FIELDS,
  UserRegistrationState
} from '@pbl/pbl-react-core/lib/models/user-registration/types';
import { UserRegistrationData } from '@pbl/pbl-react-core/lib/models/user-registration/UserRegistrationData';
import { IDispatchAction } from '..';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const INITIAL_STATE: UserRegistrationState = {
  loading: false,
  error: null,
  stepper: {
    selected: 0,
    steps: [
      { name: 'account_credentials', step: 0 },
      { name: 'personal_information', step: 1 },
      { name: 'verify', step: 2 }
    ]
  },
  userRegistrationData: new UserRegistrationData(),
  registrationSucceeded: false,
  emailExists: false,
  showInviteCode: false,
  showCaptcha: false,
  inviteCodeStatus: ''
};

export default (state: UserRegistrationState = INITIAL_STATE, action: IDispatchAction): UserRegistrationState => {
  switch (action.type) {
    case IS_VALID_INVITE_CODE:
      return {
        ...state,
        inviteCodeStatus: action.payload
      };
    case UPDATE_STEPPER:
      return {
        ...state,
        stepper: {
          ...state.stepper,
          selected: action.payload
        }
      };
    case ERROR_RESET:
      return {
        ...state,
        error: null
      };
    case RESET:
      return {
        ...INITIAL_STATE
      };
    case UPDATE_INVITE_CODE_VISIBILITY:
      return {
        ...state,
        showInviteCode: !!action.payload
      };
    case UPDATE_SHOW_CAPTCHA:
      return {
        ...state,
        showCaptcha: !!action.payload
      };
    case RESET_ADDITIONAL_INFORMATION:
      return {
        ...state,
        userRegistrationData: state.userRegistrationData.resetAdditionalInformation()
      };
    case UPDATE_USER_REGISTRATION_DATA_FIELDS:
      const userRegistrationData = state.userRegistrationData.updateField(action.payload.fieldName, action.payload.value);
      return {
        ...state,
        userRegistrationData
      };
    case REQUEST(REGISTER_PLAYER):
    case REQUEST(CHECK_EMAIL):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(REGISTER_PLAYER):
      return {
        ...state,
        loading: false,
        error: null,
        registrationSucceeded: true
      };
    case SUCCESS(CHECK_EMAIL):
      return {
        ...state,
        loading: false,
        error: null,
        emailExists: !!(action.payload && action.payload.message)
      };
    case FAILURE(REGISTER_PLAYER):
      return {
        ...state,
        loading: false,
        error: action.payload,
        registrationSucceeded: false
      };
    case FAILURE(CHECK_EMAIL):
      return {
        ...state,
        loading: false,
        error: action.payload,
        emailExists: true
      };
    default:
      return state;
  }
};
