import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    slickButton: {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      background: 'rgb(255 255 255 / 80%)',
      minWidth: '40px',
      padding: 0,
      left: '10px',
      [theme.breakpoints.down('xs')]: {
        left: '8px'
      }
    },
    slickButtonNext: {
      left: 'unset',
      right: '10px',
      [theme.breakpoints.down('xs')]: {
        right: '8px'
      }
    },
    carouselContainer: {
      '& .slick-dots': {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 'unset',
        height: '25px',
        padding: '0px 16px 30px 16px',
        borderRadius: '5px',
        background: 'rgba(255, 255, 255, 0.8)'
      },
      '& .slick-dots li button': {
        width: '10px',
        height: '10px',
        padding: 0
      },
      '& .slick-dots li button::before': {
        backgroundColor: theme.palette.primary.main
      },
      '& .slick-dots li button:focus::before': {
        border: '2px solid black'
      },
      '& .slick-dots li': {
        width: '10px',
        height: '10px',
        margin: '0 10px'
      }
    }
  });

export default styles;
