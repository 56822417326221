import {
  CREDIT,
  DISMISS_DAILY_BONUS,
  GET_ACTIVITY,
  GET_ACTIVITY_FEATURED,
  GET_ACTIVITY_LIST_V2,
  GET_ACTIVITY_WITH_FREQ_CAP,
  GET_BONUS_ACTIVITY,
  IActivityState
} from '@pbl/pbl-react-core/lib/models/activity/types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: IActivityState = {} as IActivityState;

const ActivityStateReducer = (state: IActivityState = INITIAL_STATE, action: IDispatchAction): IActivityState => {
  switch (action.type) {
    case REQUEST(GET_ACTIVITY_LIST_V2):
    case REQUEST(GET_ACTIVITY_FEATURED):
    case REQUEST(GET_BONUS_ACTIVITY):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_ACTIVITY):
    case SUCCESS(GET_ACTIVITY_WITH_FREQ_CAP):
    case SUCCESS(CREDIT):
      return {
        ...state,
        activities: action.payload.activities
      };
    case GET_ACTIVITY_LIST_V2:
    case SUCCESS(GET_ACTIVITY_LIST_V2):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activityList: action.payload
      };
    case GET_BONUS_ACTIVITY:
    case SUCCESS(GET_BONUS_ACTIVITY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        bonusActivity: action.payload
      };
    case SUCCESS(GET_ACTIVITY_FEATURED):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        featured: action.payload
      };
    case DISMISS_DAILY_BONUS:
      return {
        ...state,
        dailyBonusRedemption: action.payload.dailyBonusRedemption
      };
    case FAILURE(GET_ACTIVITY_LIST_V2):
    case FAILURE(GET_ACTIVITY_FEATURED):
    case FAILURE(GET_BONUS_ACTIVITY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'activity-state',
  storage,
  blacklist: []
};

export default persistReducer(persistConfig, ActivityStateReducer);
