import { react_core_config } from '@pbl/pbl-react-core/lib/config/config';
import dateTimeFormatter, { DateFormatterKey } from '@pbl/pbl-react-core/lib/utils/DateTimeFormatter';
import { formatLongMonthDayCommaYear } from '@pbl/pbl-react-core/lib/utils/TimeUtil';

export const formatRewardDate = (dateFormat: DateFormatterKey, tbd: string, date?: string) =>
  date
    ? !!react_core_config.internalConfig.dateFormatter?.drawStartEndDateFormat
      ? dateTimeFormatter.getFormattedDateFromDateString(dateFormat, date)
      : formatLongMonthDayCommaYear(date)
    : tbd;
