import { Theme, WithStyles, withStyles, withTheme } from '@material-ui/core/styles';
import PastHistory from '@pbl/pbl-react-web-components/lib/history/v2/past-history-screen';
import styles from 'assets/jss/modules/history/HistoryScreenStyle';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { resetState } from 'redux/reducers/history/actions';
import { DEFAULT_ACTIVITIES_PER_PAGE, DEFAULT_PAGE, DEFAULT_REWARDS_PER_PAGE } from 'redux/reducers/history/pagination/actions';
import { fetchPastActivityHistoryList, fetchPastRewardHistoryList } from 'redux/reducers/past-history/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';

export interface IPastHistoryScreenProps extends WithStyles<typeof styles> {
  theme: Theme;
}

const perPageOptions: number[] = [10, 25, 50, 75, 100];

const PastHistoryScreen: React.FC<IPastHistoryScreenProps> = ({ classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state: locationState } = useLocation();

  const pastHistoryData = useSelector((state: IRootState) => state.pastHistory);
  const historyData = useSelector((state: IRootState) => state.history);
  const historyPagination = useSelector((state: IRootState) => state.historyPagination);
  const { activitiesLastPage, rewardsLastPage } = historyData;
  const userData = useSelector((state: IRootState) => state.authentication.userData);
  const [loading, setLoading] = React.useState(false);
  const historyType = (locationState as any).type;
  const isPastActivityHistory = historyType === 'past-activity';

  React.useEffect(() => {
    document.title = 'PlayOn Past History';

    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const getData = () => {
    setLoading(true);
    if (isPastActivityHistory) {
      getPastActivitiesData(DEFAULT_PAGE, DEFAULT_ACTIVITIES_PER_PAGE);
    } else {
      getPastRewardsData(DEFAULT_PAGE, DEFAULT_ACTIVITIES_PER_PAGE);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, [dispatch]);

  const getPastActivitiesData = React.useCallback(
    async (pageNumber: number, size: number) => {
      const newPage = pageNumber ? pageNumber : DEFAULT_PAGE;
      const pageSize = size ?? DEFAULT_ACTIVITIES_PER_PAGE;
      const legacyUserId = (userData as any).legacyUserId;

      if (legacyUserId) {
        dispatch(fetchPastActivityHistoryList(legacyUserId, { page: newPage, size: pageSize }));
      }
    },
    [dispatch, userData]
  );

  const getPastRewardsData = React.useCallback(
    async (pageNumber: number, size: number) => {
      const newPage = pageNumber ? pageNumber : DEFAULT_PAGE;
      const pageSize = size ?? DEFAULT_ACTIVITIES_PER_PAGE;
      const legacyUserId = (userData as any).legacyUserId;

      if (legacyUserId) {
        dispatch(fetchPastRewardHistoryList(legacyUserId, { page: newPage, size: pageSize }));
      }
    },
    [dispatch, userData]
  );

  const navigateBackToHistory = () => {
    history.push('/history');
  };

  const pageData = isPastActivityHistory
    ? {
        lastPage: activitiesLastPage,
        currentPage: historyPagination.pastActivities.pageNumber,
        totalPages: historyPagination.pastActivities.totalPages,
        number: historyPagination.pastActivities.pageSize || DEFAULT_ACTIVITIES_PER_PAGE
      }
    : {
        lastPage: rewardsLastPage,
        currentPage: historyPagination.pastRewards.pageNumber,
        totalPages: historyPagination.pastRewards.totalPages,
        number: historyPagination.pastRewards.pageSize || DEFAULT_REWARDS_PER_PAGE
      };

  return (
    <div className={classes.root}>
      <ScrollToTopOnMount />
      <PastHistory
        loading={loading}
        pastHistoryData={isPastActivityHistory ? pastHistoryData.pastHistoryActivities : pastHistoryData.pastHistoryRewards}
        getPastData={isPastActivityHistory ? getPastActivitiesData : getPastRewardsData}
        navigateToHistory={navigateBackToHistory}
        PAST_HISTORY_ITEMS_PER_PAGE={isPastActivityHistory ? DEFAULT_ACTIVITIES_PER_PAGE : DEFAULT_REWARDS_PER_PAGE}
        pastHistoryPage={pageData}
        perPageOptions={perPageOptions}
        historyType={historyType}
        errorMessage={historyData.errorMessage}
      />
    </div>
  );
};

export default withTheme(withStyles(styles)(PastHistoryScreen));
