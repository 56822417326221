import { IDraw, IDrawHistory, IDrawPrize } from '@pbl/pbl-react-core/lib/models/draws/types';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchDrawPrizesById,
  getDrawEntries,
  getDrawPrizesWithContent,
  getDrawPrizesWithWinners,
  getDrawsByPromotion,
  getDrawWithAdditionalInformation,
  getLatestDrawHistory
} from 'redux/reducers/draws/actions';

export const useDrawData = (drawId?: number, promotionId?: number) => {
  const [drawPrizes, setDrawPrizes] = useState<IDrawPrize[]>([]);
  const [draws, setDraws] = useState<IDraw[]>([]);
  const [draw, setDraw] = useState<IDraw>();
  const [drawPrizesWithContent, setDrawPrizesWithContent] = useState<IDrawPrize[]>([]);
  const [drawPrizesWithWinners, setDrawPrizesWithWinners] = useState<IDrawPrize[]>([]);
  const [latestDrawHistory, setLatestDrawHistory] = useState<IDrawHistory>();
  const [drawHistoryRetrieved, setDrawHistoryRetrieved] = useState<boolean>(false);
  const [drawEntries, setDrawEntries] = useState<number>(0);

  const initData = useCallback(async () => {
    if (drawId !== undefined) {
      const fetchedDrawPrizes = await fetchDrawPrizesById(drawId);
      const fetchedDraw = await getDrawWithAdditionalInformation(drawId);
      const fetchedDrawPrizesWithContent = await getDrawPrizesWithContent(drawId);
      const fetchedDrawPrizesWithWinners = await getDrawPrizesWithWinners(drawId);
      const fetchedLatestDrawHistory = await getLatestDrawHistory(drawId);

      setDrawPrizes(fetchedDrawPrizes ?? []);
      setDraw(fetchedDraw);
      setDrawPrizesWithContent(fetchedDrawPrizesWithContent ?? []);
      setDrawPrizesWithWinners(fetchedDrawPrizesWithWinners ?? []);
      setLatestDrawHistory(fetchedLatestDrawHistory);
      setDrawHistoryRetrieved(true);
    }
  }, [drawId]);

  const fetchDraws = useCallback(async () => {
    if (promotionId !== undefined) {
      const fetchedDraws = await getDrawsByPromotion(promotionId);
      setDraws(fetchedDraws ?? []);
    }
  }, [promotionId]);

  const fetchDrawEntries = useCallback(async () => {
    if (promotionId !== undefined && drawId !== undefined) {
      const fetchedEntries = await getDrawEntries(promotionId, drawId);
      setDrawEntries(fetchedEntries ?? 0);
    }
  }, [promotionId, drawId]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    fetchDraws();
  }, [fetchDraws]);

  useEffect(() => {
    fetchDrawEntries();
  }, [fetchDrawEntries]);

  return { drawPrizes, draws, draw, drawPrizesWithContent, drawPrizesWithWinners, drawHistoryRetrieved, latestDrawHistory, drawEntries };
};
