import { IDigitalGame, IPrize } from '@pbl/pbl-react-core/lib/models/digital-game';
import { IDigitalRevealTokenDetails } from '@pbl/pbl-react-core/lib/models/ticket/types';
import { DigitalGameService, InteractiveGameService, SweepstakesService } from '@pbl/pbl-react-core/lib/services';

export const fetchDigitalGame = async (key: string, onError: (e: any) => void): Promise<IDigitalGame | undefined> => {
  try {
    return await DigitalGameService.fetchDigitalGame(key);
  } catch (e: any) {
    console.error(e);
    onError(e);
  }
};

export const playForFun = async (key: string, onError: (e: any) => void): Promise<IPrize[] | undefined> => {
  try {
    return await DigitalGameService.playForFun(key);
  } catch (e: any) {
    console.error(e);
    onError(e);
  }
};

export const playForPoints = async (key: string, onError: (e: any) => void): Promise<IPrize[] | undefined> => {
  try {
    return await DigitalGameService.playForPoints(key);
  } catch (e: any) {
    console.error(e);
    onError(e);
  }
};

export const getGameConfig = async (digitalRevealToken: string): Promise<IDigitalRevealTokenDetails | undefined> => {
  try {
    return await SweepstakesService.getArcadeGameData(digitalRevealToken);
  } catch (e: any) {
    console.error(e);
  }
};

export const endGame = async (digitalRevealToken: string): Promise<any> => {
  try {
    return await InteractiveGameService.endGame(digitalRevealToken);
  } catch (e: any) {
    console.error(e);
  }
};

export const skipGame = async (digitalRevealToken: string): Promise<any> => {
  try {
    return await InteractiveGameService.skipGame(digitalRevealToken);
  } catch (e: any) {
    console.error(e);
  }
};
