import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { jssPreset } from '@material-ui/styles';
import * as NumberUtil from '@pbl/pbl-react-core/lib/utils/NumberUtil';
import { config } from '@pbl/pbl-react-web-components/lib/package';
import constants, { RemoteConstants } from 'config/constants';
import { create } from 'jss';
import React from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { JssProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { persistentStore, store } from 'redux/store';
import getTheme from 'shared/theme/custom';
import jssVendorPrefixer from 'utils/jssVendorPrefix';
import NavigationUtils from 'utils/NavigationUtils';
import AppLayout from './AppLayout';

const jss = create({ plugins: [...jssPreset().plugins, jssVendorPrefixer()] });

class AppRoot extends React.Component {

  public async componentDidMount(): Promise<void> {
    // try to sync remote config on app mount.
    setTimeout(async () => {
      try {
        await RemoteConstants.syncConfig();
      } catch (e) {}
    }, 100);

    const analyticCodes = constants.GOOGLE_ANALYTICS_CODE.includes(`;;`)
      ? constants.GOOGLE_ANALYTICS_CODE.split(`;;`)
      : [constants.GOOGLE_ANALYTICS_CODE];

    analyticCodes.forEach(analyticCode => {
      ReactGA.initialize(analyticCode, { debug: constants.CONSOLE_DEBUG });
    });

    ReactGA.event({ category: 'Load', action: 'Application Loaded' });

    const tagManagerCodes = constants.GOOGLE_TAG_MANAGER_ID.includes(`;;`)
      ? constants.GOOGLE_TAG_MANAGER_ID.split(`;;`)
      : [constants.GOOGLE_TAG_MANAGER_ID];
    tagManagerCodes.forEach(tagManagerCode => {
      TagManager.initialize({
        gtmId: tagManagerCode
      });
    });

    const link = await RemoteConstants.getString('INVITE_INSTRUCTIONS_CODE');
    if (link) {
      config.internalConfig.codeBlockInstructionsRegistrationLink = link;
    }
    const code = await RemoteConstants.getString('INVITE_INSTRUCTIONS_NO_CODE');
    if (code) {
      config.internalConfig.codeBlockInstructionsRegistrationPage = code;
    }

    NumberUtil.loadNewCurrencyFormat();
  }

  public render() {
    return (
      <JssProvider jss={jss} classNamePrefix={'ks-'}>
        <MuiThemeProvider theme={getTheme()}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Provider store={store}>
              <PersistGate persistor={persistentStore}>
                <Router history={NavigationUtils.setupRouter()}>
                  <React.Fragment>
                    <CssBaseline />
                    <AppLayout />
                  </React.Fragment>
                </Router>
              </PersistGate>
            </Provider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}

export default AppRoot;
