import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...container,
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    feedbackContainer: {
      marginTop: theme.spacing(2)
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    },
    feedbackTitle: {
      [theme.breakpoints.up('lg')]: {
        textAlign: 'center'
      }
    },
    feedbackCard: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3)
    },
    spacing: {
      marginBottom: theme.spacing(2)
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
      height: 40,
      alignSelf: 'center',
      [theme.breakpoints.up('sm')]: {
        width: 255
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    imageContainer: {
      overflow: 'hidden',
      maxHeight: 480,
      [theme.breakpoints.down('lg')]: {
        maxHeight: 450
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 400
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: 360
      }
    },
    link: {
      padding: 0,
      marginLeft: theme.spacing() / 2,
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    moreContactInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center'
    },
    closeBtn: {
      margin: '16px auto',
      display: 'flex'
    },
    requiredField:{
      margin: '10px 0px',  
    },
    
  });

export default styles;
