import { fieldValueTypes, IField } from '@pbl/pbl-react-core/lib/models/forms/types';
import { ITicketTab } from '@pbl/pbl-react-core/lib/models/ticket/types';
import DrawLogo from 'assets/img/DrawTicketLogo.png';
import InstantPulltabLogo from 'assets/img/KS-instant-pull-logo.svg';
import QuickCardLogo from 'assets/img/Quickcard.svg';
import constants from 'config/constants';

export interface ITicketField<T> extends IField<T> {}

export type LoginFields = ITicketField<fieldValueTypes>[];

const gameNumberField: ITicketField<string> = {
  type: 'string',
  key: 'gameNumber',
  label: 'Game Number',
  get placeholder(): string {
    return this.label;
  },
  isRequired: true,
  get fieldValue() {
    return this.value;
  }
};

const bookNumberField: ITicketField<string> = {
  type: 'string',
  key: 'bookNumber',
  label: 'Book Number',
  get placeholder(): string {
    return this.label;
  },
  isRequired: true,
  get fieldValue() {
    return this.value;
  }
};

const ticketNumberField: ITicketField<string> = {
  type: 'string',
  key: 'ticketNumber',
  label: 'Ticket Number',
  get placeholder(): string {
    return this.label;
  },
  isRequired: true,
  get fieldValue() {
    return this.value;
  }
};

const validationCodeField: ITicketField<string> = {
  type: 'string',
  key: 'validationCode',
  label: 'VIRN Number',
  get placeholder(): string {
    return this.label;
  },
  isRequired: true,
  get fieldValue() {
    return this.value;
  }
};

const quickCardTab: ITicketTab = {
  tabLogo: QuickCardLogo,
  tabKey: 'quickCard',
  tabTitle: 'ticket.quickCard.tabTitle',
  fields: [
    {
      key: 'Ticket-QuickCard-Entry-Code',
      label: 'ticket.quickCard.entryCodeLabel',
      placeholder: '00000 00000 00000 00000 0000',
      maskKey: 'QuickCardTicketEntryCodeInputMask',
      mask: [
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ],
      min: 24,
      numbersOnly: true
    }
  ],
  sampleImageLink: 'ticket.sampleImageLink',
  sampleImage: constants.SAMPLE_QUICK_CARD_TICKET_KEY,
  eligibleGamesEnabled: true,
  eligibleGameText: 'ticket.eligibleGames',
  eligibleGameLinkText: 'ticket.eligibleGamesLink',
  consentText: 'ticket.consentText'
};

export const getTicketTab = (enableQuickCard: boolean, enableQuickDraw: boolean) =>
  enableQuickCard ? [...getTicketTabs(enableQuickDraw), quickCardTab] : getTicketTabs(enableQuickDraw);

const getTicketTabs = (enableQuickDraw: boolean): ITicketTab[] => [
  {
    tabLogo: InstantPulltabLogo,
    tabKey: 'scratchTicket',
    tabTitle: 'ticket.scratch.tabTitle',
    fields: [
      {
        key: 'Ticket-Barcode-Number',
        label: 'ticket.scratch.ticketNumberLabel',
        placeholder: '000000000-000',
        maskKey: 'TicketBarcodeInputMask',
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
        min: 12,
        numbersOnly: true
      },
      {
        key: 'Validation-Number',
        label: 'ticket.scratch.entryCodeLabel',
        placeholder: '00000000-000',
        maskKey: 'TicketValidationInputMask',
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
        min: 11,
        numbersOnly: true
      }
    ],
    sampleImageLink: 'ticket.sampleImageLink',
    sampleImage: constants.SAMPLE_SCRATCH_TICKET_KEY,
    eligibleGamesEnabled: true,
    eligibleGameText: 'ticket.eligibleGames',
    eligibleGameLinkText: 'ticket.eligibleGamesLink',
    consentText: 'ticket.consentText'
  },
  {
    tabLogo: DrawLogo,
    tabKey: 'drawTicket',
    tabTitle: enableQuickDraw ? 'ticket.draw.tabFullTitle' : 'ticket.draw.tabTitle',
    fields: [
      {
        key: 'Ticket-Entry-Code',
        label: 'ticket.draw.entryCodeLabel',
        placeholder: '00000 00000 00000 00000 00000',
        maskKey: 'TicketEntryCodeInputMask',
        mask: [
          /[A-Za-z0-9]/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          '-',
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          '-',
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          '-',
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          '-',
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/
        ],
        min: 25,
        numbersOnly: false
      }
    ],
    sampleImageLink: 'ticket.sampleImageLink',
    sampleImage: enableQuickDraw ? constants.SAMPLE_DRAW_QUICK_DRAW_TICKET_KEY : constants.SAMPLE_DRAW_TICKET_KEY,
    eligibleGamesEnabled: true,
    eligibleGameText: 'ticket.eligibleGames',
    eligibleGameLinkText: 'ticket.eligibleGamesLink',
    consentText: 'ticket.consentText',
    codeDisclaimer: 'ticket.codeDisclaimer'
  }
  // {
  //   tabKey: 'fastPlayTicket',
  //   tabTitle: 'ticket.fastPlay.tabTitle',
  //   fields: [
  //     {
  //       key: 'Ticket-Barcode-Number',
  //       label: 'ticket.fastPlay.ticketNumberLabel',
  //       placeholder: '0000-000000-000',
  //       maskKey: 'FastPlayBarcodeInputMask',
  //       mask: [/[0-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  //       min: 13,
  //       numbersOnly: true
  //     },
  //     {
  //       key: 'Validation-Number',
  //       label: 'ticket.fastPlay.entryCodeLabel',
  //       placeholder: '000',
  //       maskKey: 'FastPlayValidationInputMask',
  //       mask: [/[0-9]/, /\d/, /\d/],
  //       min: 3,
  //       numbersOnly: true
  //     }
  //   ],
  //   sampleImageLink: 'ticket.sampleImageLink',
  //   sampleImage: constants.SAMPLE_FAST_PLAY_TICKET_KEY,
  //   eligibleGamesEnabled: true,
  //   eligibleGameText: 'ticket.eligibleGames',
  //   eligibleGameLinkText: 'ticket.eligibleGamesLink',
  //   consentText: 'ticket.consentText'
  // }
];

export const getTicketHelpTabs = () => [
  {
    tabKey: 'scratchTicket',
    tabTitle: 'ticket.scratch.helpTabTitle',
    sampleImage: require('assets/img/sample-tickets/sample-scratch-ticket.png').default
  },
  {
    tabKey: 'tabTicket',
    tabTitle: 'ticket.tab.helpTabTitle',
    sampleImage: require('assets/img/sample-tickets/sample-tab-ticket.png').default
  },
  {
    tabKey: 'fastPlayTicket',
    tabTitle: 'ticket.fastPlay.tabTitle',
    sampleImage: require('assets/img/sample-tickets/sample-fast-play-ticket.png').default
  },
  {
    tabKey: 'drawTicket',
    tabTitle: 'ticket.draw.tabTitle',
    sampleImage: require('assets/img/sample-tickets/sample-draw-ticket.png').default
  }
];

export const ticketFields: LoginFields = [gameNumberField, bookNumberField, ticketNumberField, validationCodeField];
