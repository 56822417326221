import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    container,
    pollBtn: {
      width: '40%',
      alignSelf: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(4)
      }
    },
    pollOption: {
      marginBottom: theme.spacing(2),
      position: 'relative',
      minHeight: 36
    },
    pollAnswer: {
      position: 'relative',
      padding: theme.spacing(2),
      zIndex: 2
    },
    pollBackground: {
      height: '100%',
      borderRadius: '0px 5px 5px 0px',
      minHeight: 36,
      position: 'absolute',
      top: 0,
      zIndex: 1
    },
    PollAnswerAll: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      }
    }
  });

export default styles;
