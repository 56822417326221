import { createStyles, Theme } from '@material-ui/core';
import colors from 'assets/jss/colors';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flex: '0 0 auto',
      flexDirection: 'column'
    },
    appBar: {
      background: theme.palette.primary.main
    },
    button: {
      marginLeft: theme.spacing() + 2,
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(),
      borderRadius: '10px',
      width: 'auto',
      height: 32
    },
    pointsEarnedImg: {
      width: 21,
      paddingLeft: 2,
      paddingBottom: 2,
      [theme.breakpoints.down('xs')]: {
        width: 21
      }
    },
    userPointBalance: {
      background: theme.palette.primary.dark,
      display: 'flex',
      padding: theme.spacing(),
      color: theme.palette.primary.contrastText,
      justifyContent: 'center'
    },
    userGreetings: {
      background: theme.palette.primary.dark,
      display: 'flex',
      padding: theme.spacing(),
      paddingTop: theme.spacing(2),
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        marginLeft: theme.spacing(3)
      },
      color: theme.palette.primary.contrastText
    },
    userPointBalanceHeader: {
      background: theme.palette.primary.dark
    },
    userPointBalanceContent: {
      ...container,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.palette.primary.dark,
      paddingRight: '30px',
      paddingLeft: '30px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
      }
    },
    //TO-DO: To be removed as part of PDSENGAGE-79
    userPointBalanceToBeRemoved: {
      background: theme.palette.primary.dark,
      display: 'flex',
      padding: theme.spacing(),
      paddingTop: 0,
      justifyContent: 'space-between',
      color: theme.palette.primary.contrastText,
      alignItems: 'baseline'
    },
    toolBar: {
      ...container,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    grow: {
      flexGrow: 1
    },
    sectionDesktop: {
      display: 'none',
      borderRadius: '5px',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    logo: {
      height: `3.2em`,
      [theme.breakpoints.down('lg')]: {
        height: `3em`
      },
      [theme.breakpoints.down('xs')]: {
        height: `2.7em`
      }
    },
    list: {
      WebkitBoxAlign: 'center',
      MsFlexAlign: 'center',
      alignItems: 'center',
      WebkitBoxOrient: 'horizontal',
      WebkitBoxDirection: 'normal',
      MsFlexDirection: 'row',
      flexDirection: 'row',
      marginTop: '0px',
      display: 'flex',
      paddingLeft: '0',
      marginBottom: '0',
      listStyle: 'none',
      padding: '0'
    },
    listItem: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    listItemMobile: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0'
    },
    badgeActive: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      fontFamily: 'Steagal'
    },
    badgeInActive: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
      fontFamily: 'Steagal'
    },
    navLink: {
      position: 'relative',
      padding: '0.90rem',
      textDecoration: 'none',
      margin: '0px',
      marginLeft: theme.spacing() / 4,
      display: 'inline-flex'
    },
    navLinkIcon: {
      '&.MuiIcon-root': {
        height: 'auto'
      },
      marginRight: theme.spacing() / 2,
      [theme.breakpoints.down('md')]: {
        marginRight: 0
      }
    },
    navLinkText: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    navLinkActive: {
      '&, &:hover, &:focus,&:active ': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default
      }
    },
    navLinkActiveLL: {
      '&, &:hover, &:focus,&:active ': {
        color: colors.themeSweepstakesColors.backgroundColor.hex(),
        backgroundColor: theme.palette.background.default
      }
    },
    navLinkDisabled: {
      '&, &:hover, &:focus,&:active ': {
        color: 'inherit',
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      }
    },
    innerWrapper: {
      display: 'flex',
      width: '100%'
    },
    header: {
      [theme.breakpoints.down('md')]: {
        padding: '10px 0'
      }
    },
    skipLink: {
      position: 'absolute',
      top: '-200%',
      padding: '0 8px',
      zIndex: 100,
      width: '220px',
      textDecoration: 'none',
      lineHeight: '48px',
      textTransform: 'uppercase',
      borderRadius: '5px',
      background: theme.palette.secondary.main,
      color: theme.palette.text.primary,
      fontWeight: 500,
      letterSpacing: '1.5px',
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: theme.typography.subtitle2.fontFamily,
      margin: '10px 0',
      '&:focus, &:focus-visible': {
        zIndex: 0,
        top: 0,
        position: 'relative'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        left: 0
      }
    }
  });

export default styles;
