import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { IDraw, IDrawPage } from '@pbl/pbl-react-core/lib/models/draws/types';
import PastDrawsScreenList from '@pbl/pbl-react-web-components/lib/draws/past-draws-screen-list';
import { CardContentLoader } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import styles from 'assets/jss/modules/rewards/RewardsScreenStyle';
import constants from 'config/constants';
import DrawCard from 'modules/draws/components/DrawCard';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { selectDraw } from 'redux/reducers/draws/actions';
import {
  fetchReward,
  fetchRewardEntries,
  fetchRewards,
  fetchSweepstakeDraws,
  fetchUserRewards,
  setSelectedReward
} from 'redux/reducers/loyalty/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';

export interface IPastDrawsScreenProps extends StateProps, DispatchProps, WithTranslation, WithStyles<typeof styles>, RouteComponentProps {
  theme: Theme;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({ authentication: { accessToken }, loyalty }: IRootState) => {
  const isLoggedIn: boolean = !!accessToken && accessToken.length > 0;
  return {
    loyalty,
    isLoggedIn,
    rewards: isLoggedIn ? loyalty.userRewards : loyalty.rewards
  };
};

const mapDispatchToProps = {
  fetchSweepstakeDraws,
  isAuthenticated,
  selectDraw,
  fetchUserRewards,
  fetchRewards,
  fetchReward,
  setSelectedReward,
  fetchRewardEntries
};

interface IPastDrawsScreenState {
  pageLoading: boolean;
}

class PastDrawsScreen extends React.Component<IPastDrawsScreenProps, IPastDrawsScreenState> {
  constructor(props: IPastDrawsScreenProps) {
    super(props);
    this.state = {
      pageLoading: false
    };
  }

  // tslint:disable-next-line: ter-arrow-body-style
  public renderScreenTitle = (): React.ReactNode => {
    const { history } = this.props;
    const onNavigateToReward = () => {
      history.push('/rewards');
    };
    return (
      <>
        <Grid item={true} xs={12}>
          <div className={this.props.classes.header}>
            <DetailTitle
              title={this.props.t('reward.pastRewards')}
              linkText={this.props.t('reward.rewards')}
              navigateTo={onNavigateToReward}
            />
          </div>
        </Grid>
      </>
    );
  };

  public async componentDidMount() {
    await this.getEntities();
  }

  public getEntities = async () => {
    this.setState({ pageLoading: true });
    await this.props.fetchSweepstakeDraws({ page: 0, size: constants.PAST_REWARDS_PER_PAGE });
    if (this.props.isLoggedIn) {
      await this.props.fetchUserRewards();
    } else {
      await this.props.fetchRewards();
    }
    this.setState({ pageLoading: false });
  };

  public getNextPage = async (pageNumber: number) => {
    await this.props.fetchSweepstakeDraws({ page: pageNumber, size: constants.PAST_REWARDS_PER_PAGE });
  };

  private onCardClicked = async (draw: IDraw) => {
    this.props.selectDraw(draw);
    const rewardId = draw.externalId;
    if (!!rewardId) {
      await this.props.fetchReward(rewardId);
      if (this.props.isLoggedIn) {
        await this.props.fetchRewardEntries(rewardId);
      }
    }
    this.props.history.push('/past-draw-detail');
  };

  public getDrawCard = (draw: IDraw, onClick: () => void) => (
    <Grid item={true} xs={12} sm={6} lg={4} key={draw.id}>
      <DrawCard
        name={draw.name}
        description={draw.description}
        image={draw.drawFullImage ? draw.drawFullImage : ''}
        onClick={onClick}
        iconName={'icon-KS-rewards'}
        showYellowBackground={true}
      />
    </Grid>
  );

  public render() {
    const { classes } = this.props;
    if (this.state.pageLoading) {
      const contentLoader = (
        <Grid item={true} xs={12} sm={6} md={4}>
          <CardContentLoader loaderStyle={'loyalty'} />
        </Grid>
      );

      return (
        <div className={classes.container}>
          <ScrollToTopOnMount />
          {this.renderScreenTitle()}
          <Grid container={true} spacing={4}>
            {contentLoader}
            <Hidden smDown={true}>{contentLoader}</Hidden>
            <Hidden xsDown={true}>{contentLoader}</Hidden>
          </Grid>
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <PastDrawsScreenList
          loading={this.state.pageLoading}
          renderTitle={this.renderScreenTitle}
          draws={
            !!this.props.loyalty.sweepStakeDraws && !!this.props.loyalty.sweepStakeDraws.content
              ? this.props.loyalty.sweepStakeDraws.content
              : []
          }
          page={
            !!this.props.loyalty.sweepStakeDraws && !!this.props.loyalty.sweepStakeDraws.page
              ? this.props.loyalty.sweepStakeDraws.page
              : ({} as IDrawPage)
          }
          getNextPage={this.getNextPage}
          getDrawCard={this.getDrawCard}
          onCardClicked={this.onCardClicked}
          drawsPerPage={constants.PAST_REWARDS_PER_PAGE}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTranslation()(withRouter(withTheme(withStyles(styles)(PastDrawsScreen)))));
