import { Button, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { assets } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/shared/components/menu/AppBarStyle';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'redux/reducers';
import displayGreetingsMessage from 'utils/GreetingsMessageUtil';

interface IProps extends StateProps, WithStyles<typeof styles>, WithTranslation {
  firstName: string;
  points: number;
  expiryAmount: number;
  isHomeScreen: boolean;
}

const UserPointBalance: React.FunctionComponent<IProps> = ({ classes, points, expiryAmount, firstName, isHomeScreen, t }) => {
  // TODO: Kept the greetMessage for further changes
  // const [greetMessage, setGreetMessage] = React.useState('');
  const history = useHistory();
  const [isFirstNameEnabled, setIsFirstNameEnabled] = React.useState<boolean>();
  const [greetFontColor, setFontColor] = React.useState('');
  const [greetFontSize, setFontSize] = React.useState<number>();
  const [greetFontFamily, setFontFamily] = React.useState('');

  const navigateToReward = () => {
    history.push('/rewards');
  };

  React.useEffect(() => {
    (async () => {
      const { showFirstName, fontColor, fontSize, fontFamily } = await displayGreetingsMessage();
      // setGreetMessage(message);
      setIsFirstNameEnabled(showFirstName);
      setFontColor(fontColor);
      setFontSize(fontSize);
      setFontFamily(fontFamily);
    })();
  }, []);

  if (isHomeScreen) {
    return (
      <div className={classes.userPointBalanceHeader}>
        <div className={classes.userPointBalanceContent}>
          <div className={classes.userGreetings}>
            <Typography
              variant="body2"
              style={{
                fontSize: greetFontSize,
                fontFamily: greetFontFamily,
                color: greetFontColor
              }}
            >
              {'Welcome, ' + (isFirstNameEnabled ? firstName : '')}
            </Typography>
          </div>
        </div>
        <div className={classes.userPointBalanceContent}>
          <div className={classes.userPointBalanceToBeRemoved}>
            <Typography color="inherit" variantMapping={{ subtitle1: 'p' }} variant="subtitle1">
              {expiryAmount?.toPointsFormat()}
            </Typography>
            <Typography color="inherit" variant="body2" style={{ paddingTop: '5px', paddingLeft: '5px' }}>
              {t('home.pointsExpiryTitle')}
            </Typography>
          </div>
          <div className={classes.userPointBalanceToBeRemoved}>
            <Button variant="contained" className={classes.button} color="primary" onClick={navigateToReward}>
              {points?.toPointsFormat()}
              <img alt={'coins'} src={assets.internalAssets.pointsEarnedImg} className={classes.pointsEarnedImg} />
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.userPointBalance}>
        <Typography color="inherit" variant="h6">
          {points?.toPointsFormat()}
        </Typography>
        <Typography color="inherit" variant="caption">
          &nbsp;{points?.getPointsUnit()}
        </Typography>
      </div>
    );
  }
};
const mapStateToProps = ({ authentication: { account } }: IRootState) => ({
  firstName: account ? `${account.firstName}` : ''
});
type StateProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(UserPointBalance)));
