import { Button, Grid, IconButton, InputAdornment, TextField, WithStyles, withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from 'assets/jss/modules/settings/account/AccountScreenStyle';
import constants from 'config/constants';

type StateProps = ReturnType<typeof mapStateToProps>;

interface IAccountScreenProps extends RouteComponentProps, StateProps, WithStyles<typeof styles>, WithTranslation {}

class AccountScreen extends React.Component<IAccountScreenProps> {
  public componentDidMount() {
    document.title = `${constants.PROJECT_NAME} - Account & Security`;
  }
  public render(): React.ReactNode {
    const { account, classes, t } = this.props;

    return (
      <Grid container={true} spacing={3} direction="column">
        <Grid item={true}>
          <TextField
            required={true}
            id="Email"
            label="Email"
            className={classes.field}
            value={account.email}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Edit email"
                    onClick={this.handleNavigate('/settings/account-security/change-email', 'Change Email Address')}
                  >
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            helperText={t('account.changeEmailHelper')}
          />
        </Grid>

        <Grid item={true} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size="large"
            variant="text"
            color="primary"
            onClick={this.handleNavigate('/settings/account-security/change-password', 'Change Password')}
            aria-label="Change Password"
          >
            {t('account.changePassword')}
          </Button>
        </Grid>
        <Grid item={true} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size="large"
            variant="text"
            color="primary"
            onClick={this.handleNavigate('/settings/account-security/deactivate-account', 'Deactivate Account')}
            aria-label="Deactivate Account"
          >
            {t('account.deactivateAccount')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private handleNavigate = (path, name) => () => {
    this.props.history.push(path, {
      currentRoute: path,
      currentRouteName: name
    });
  };
}

const mapStateToProps = ({ authentication: { account } }) => ({ account });

export default connect(mapStateToProps)(withRouter(withTranslation()(withStyles(styles)(AccountScreen))));
