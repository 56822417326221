import { ICoupon, IReward, RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'redux/reducers';
import { fetchCoupon, fetchRedemption, fetchRewards, fetchUserRewards } from 'redux/reducers/reward/actions';

export const useRewardListData = (type: RewardType) => {
  const { accessToken, account } = useSelector((state: IRootState) => state.authentication);
  const loggedIn = !!accessToken && accessToken.length > 0 && !!account && !!account.email;

  const [rewards, setRewards] = useState<IReward[]>([]);
  const [featuredRewards, setFeaturedRewards] = useState<IReward[]>([]);
  const [couponDetails, setCouponDetails] = useState<(ICoupon | undefined)[]>([]);
  const [featuredDetails, setFeaturedDetails] = useState<(ICoupon | undefined)[]>([]);

  const fetchNonFeaturedRewards = useCallback(async () => {
    const requestMethod = loggedIn ? fetchUserRewards : fetchRewards;
    const rewardResponse = await requestMethod(false, false, type);

    setRewards(rewardResponse ? (rewardResponse as any as IReward[]) : []);

    let details: (ICoupon | undefined)[] = [];
    if (rewardResponse && type === RewardType.COUPON) {
      details = await Promise.all(
        (rewardResponse as any as IReward[]).map(async (reward): Promise<ICoupon | undefined> => {
          const coupon = await fetchCoupon(reward.key);
          return coupon;
        })
      );
    }
    if (rewardResponse && type === RewardType.EXTERNAL) {
      details = await Promise.all(
        (rewardResponse as any as IReward[]).map(async (reward): Promise<ICoupon | undefined> => {
          const redemption = await fetchRedemption(reward.key);
          return redemption;
        })
      );
    }
    setCouponDetails(details);
  }, [type, loggedIn]);

  const fetchFeatured = useCallback(async () => {
    const featuredRewardResponse = await fetchRewards(false, true, type);
    setFeaturedRewards(featuredRewardResponse ? (featuredRewardResponse as any as IReward[]) : []);
    const details =
      featuredRewardResponse && type === RewardType.COUPON
        ? await Promise.all(
            (featuredRewardResponse as any as IReward[]).map(async (reward): Promise<ICoupon | undefined> => {
              const coupon = await fetchCoupon(reward.key);
              return coupon;
            })
          )
        : [];
    setFeaturedDetails(details);
  }, [type, loggedIn]);

  const initData = useCallback(async (): Promise<void> => {
    fetchFeatured();
    fetchNonFeaturedRewards();
  }, [type, fetchNonFeaturedRewards]);

  useEffect(() => {
    initData();
  }, [initData, type]);

  return {
    rewards,
    featuredRewards,
    couponDetails,
    featuredDetails
  };
};
