import { Card, CardContent, Hidden, Typography, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from 'assets/jss/modules/forgot-password/ForgotPasswordScreenStyle';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import constants from 'config/constants';
import ForgotPasswordForm from './components/ForgotPasswordForm';

interface IForgotPasswordScreenProps extends RouteComponentProps, WithStyles<typeof styles> {}

class ForgotPasswordScreen extends React.Component<IForgotPasswordScreenProps> {
  public componentDidMount(): void {
    document.title = `${constants.PROJECT_NAME} - Forgot Password`;
  }

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.forgotPasswordScreen}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem sm={10} md={6}>
              <Card className={classes.forgotPasswordCard}>
                <CardContent className={classes.forgotPasswordCardContent}>
                  <div className={classes.forgotPasswordCardContentInner}>
                    <Hidden xsDown={true}>
                      <Typography color="primary" variant="h4" className={classes.titleText}>
                        Forgot Password?
                      </Typography>
                    </Hidden>
                    <Hidden smUp={true}>
                      <Typography color="primary" variant="h6" className={classes.titleText}>
                        Forgot Password?
                      </Typography>
                    </Hidden>
                    <ForgotPasswordForm />
                  </div>
                </CardContent>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ForgotPasswordScreen));
