import constants from 'config/constants';

const TIMEOUT = 60 * 1000;

const apiAuthConfiguration = {
  baseURL: constants.AUTH_URL,
  timeout: TIMEOUT,
  withCredentials: true
};
export { apiAuthConfiguration };
