import { Button, Card, CardContent, Hidden, Icon, Typography, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from 'assets/jss/modules/forgot-password/ForgotPasswordScreenStyle';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import constants from 'config/constants';

interface IForgotPasswordSentScreenProps extends RouteComponentProps, WithStyles<typeof styles> {}

class ForgotPasswordSentScreen extends React.Component<IForgotPasswordSentScreenProps> {
  public componentDidMount(): void {
    document.title = `${constants.PROJECT_NAME} - Forgot Password Sent`;
  }

  public render(): React.ReactNode {
    const { classes, location } = this.props;
    // @ts-ignore
    const email = location.state && location.state.email ? location.state.email : 'unknown email';

    return (
      <div className={classes.forgotPasswordScreen}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem sm={10} md={6}>
              <Card className={classes.forgotPasswordCard}>
                <CardContent className={classes.forgotPasswordCardContent}>
                  <div className={classes.forgotPasswordCardContentInner}>
                    <Hidden xsDown={true}>
                      <Typography color="primary" variant="h4" className={classes.titleText}>
                        Forgot Password?
                      </Typography>
                    </Hidden>
                    <Hidden smUp={true}>
                      <Typography color="primary" variant="h6" className={classes.titleText}>
                        Forgot Password?
                      </Typography>
                    </Hidden>
                    <div className={classes.container}>
                      <GridContainer justify="center">
                        <GridItem sm={10} md={10}>
                          <div className={classes.content}>
                            <div className={classes.text}>
                              <Icon color="primary" className={classes.mailIcon}>
                                mail
                              </Icon>
                            </div>
                            <div className={classes.closeText}>
                              <Typography color="textPrimary" variant="body2">
                                Password reset instructions sent to
                              </Typography>
                            </div>
                            <div className={classes.text}>
                              <Typography color="textPrimary" variant="subtitle2">
                                {email}
                              </Typography>
                            </div>
                            <div className={classes.text}>
                              <Typography color="textPrimary" variant="body2">
                                Go to
                              </Typography>
                            </div>

                            <Button
                              className={classes.linkButton}
                              onClick={this.onNavigate.bind(this, '/login')}
                              size="large"
                              variant="outlined"
                              aria-label="click here to go to login page"
                            >
                              <Typography variant="button" className={classes.primaryText}>
                                SIGN IN
                              </Typography>
                            </Button>

                            <Button
                              className={classes.linkButton}
                              style={{ marginBottom: '0' }}
                              onClick={this.onNavigate.bind(this, '/')}
                              size="large"
                              variant="outlined"
                              aria-label="click here to go to home page"
                            >
                              <Typography variant="button" className={classes.primaryText}>
                                HOME
                              </Typography>
                            </Button>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }

  private onNavigate = (page: string) => {
    this.props.history.push(page);
  };
}

export default withRouter(withStyles(styles)(ForgotPasswordSentScreen));
