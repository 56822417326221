import { react_core_config } from '@pbl/pbl-react-core/lib/config/config';
import { API, AUTHAPI } from '@pbl/pbl-react-core/lib/services';
import { DateFormatterType } from '@pbl/pbl-react-core/lib/utils/DateTimeFormatter';
import { now } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { assets, config } from '@pbl/pbl-react-web-components/lib/package';
import playersClubLogo from 'assets/img/az_players_club_white_logo.png';
import cvvHelper from 'assets/img/card-cvv.png';
import claimItAllSecondaryImage from 'assets/img/collect-them-all/claim-it-all-2-lg.png';
import claimItAllImage from 'assets/img/collect-them-all/claim-it-all-lg.png';
import collectCheckLogo from 'assets/img/icons-check@1x.png';
import tagBg from 'assets/img/sweepstakes/arcade-tag-bg.svg';
import convertArrow from 'assets/img/sweepstakes/arrow-right-yellow.svg';
import TokenPrimary from 'assets/img/sweepstakes/icons-lucky-lounge-token-primary-color.png';
import TokenSecondary from 'assets/img/sweepstakes/icons-lucky-lounge-token-secondary-color.png';
import luckyLoungeLogo from 'assets/img/sweepstakes/logos-lucky-lounge-vertical-gold.png';
import appColors from 'assets/jss/colors';
import constants, { RemoteConstants } from 'config/constants';
import { getTicketTab } from 'config/ticket';
import validation from 'config/validation';
import virtualTourConfiguration from 'config/VirtualTourConfig';
import React, { useEffect, useState } from 'react';
import { apiAuthConfiguration } from 'utils/apiAuthConfig';
import { addRequestTransform, addResponseTransform, apiConfiguration } from 'utils/apiConfig';
import AppDeviceHandler from './AppDeviceHandler';
import AppRoot from './AppRoot';

// tslint:disable-next-line:ter-arrow-body-style
const App: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [intialized, setIntialized] = useState(false);
  const onDeviceReady = () => {
    setLoaded(true);
  };

  const intialize = () => {
    const coreConfig = {
      lotteryTimeZone: constants.LOTTERY_TIME_ZONE,
      minDateOfBirth: now(constants.LOTTERY_TIME_ZONE).subtract(120, 'years').toDate(),
      maxDateOfBirth: now(constants.LOTTERY_TIME_ZONE).subtract(18, 'years').toDate(),
      currencyFormat: constants.CURRENCY_FORMAT,
      currencyFormatWithDecimal: constants.CURRENCY_FORMAT_WITH_DECIMAL,
      instantGameType: constants.INSTANT_GAMES_TYPE_ID,
      gameType: constants.DEFAULT_GAME_TYPE,
      showGameTypeFilter: constants.SHOW_GAME_TYPE_FILTER,
      defaultDateFormat: constants.DEFAULT_DATE_FORMAT,
      dateFormatter: {
        historyDateFormat: DateFormatterType.DateFormat,
        drawDrawDateFormat: DateFormatterType.DateFormat,
        drawStartEndDateFormat: DateFormatterType.DateFormat
      },
      addressBaseUrl: constants.ADDRESS_URL,
      pointsFormat: constants.POINTS_FORMAT,
      promoEndingSoonDays: constants.DRAW_ENDING_SOON_PERIOD,
      arcadeGameType: constants.ARCADE_GAME_TYPE,
      lotteryId: constants.LOTTERY_ID,
      gatewayV2: constants.GATEWAY_V2
    };
    config.coreConfig = coreConfig;
    config.themeColors = appColors.themeColors;
    react_core_config.internalConfig = coreConfig;
    assets.internalAssets = {
      noImage: require('../src/assets/img/no-image.svg').default,
      pointsEarnedImg: require('../src/assets/img/pointsEarnedImg.png').default,
      noFeaturedImg: require('../src/assets/img/no-image-featured.svg').default,
      facebookLogo: require('../src/assets/img/facebook-logo.png').default,
      instagramLogo: require('../src/assets/img/instagram-logo.png').default,
      twitterLogo: require('../src/assets/img/twitter-logo.png').default,
      rewardDownloadLogo: require('../src/assets/img/reward-download.svg').default,
      rewardRedeemLogo: require('../src/assets/img/rewardRedeemed.svg').default,
      kenoHotNumberImg: constants.BASE_IMAGE_URL + '../src/assets/img/hot-ball.png',
      kenoColdNumberImg: constants.BASE_IMAGE_URL + '../src/assets/img/cold-ball.png',
      playLogo: require('../src/assets/img/play-icon.png').default,
      preAssignedPointsImg: require('../src/assets/img/bonus-points.png').default,
      bonusIcon: require('../src/assets/img/icon-left-badge-bonus.png').default,
      correctIcon: require('../src/assets/img/Correct.png').default,
      incorrectIcon: require('../src/assets/img/Incorrect.png').default,
      activeLegendImg: require('../src/assets/img/active-legend.png').default,
      endedLegendImg: require('../src/assets/img/ended-legend.png').default,
      emptyCart: require('../src/assets/img/empty-cart-background.svg').default,
      arcadeTagBG: tagBg,
      tokenPrimary: TokenPrimary,
      tokenSecondary: TokenSecondary,
      cvvHelper,
      freeClaim: require('../src/assets/img/sweepstakes/free-ticket.png').default,
      otwImage: require('../src/assets/img/One-To-Win-Game_Image.png').default,
      otwLogo: require('../src/assets/img/Onetowinlogo.png').default,
      ftffImage: require('../src/assets/img/ftffImage.png').default,
      ftffLogo: require('../src/assets/img/ftffLogo.png').default,
      starImg: require('../src/assets/img/did-you-know-star.png').default,
      luckyLoungeLogoWhite: luckyLoungeLogo,
      convertArrow,
      playersClubLogoWhite: playersClubLogo,
      collectCheckLogoIcon: collectCheckLogo,
      claimItAll: claimItAllImage,
      claimItAllSecondary: claimItAllSecondaryImage,
      superBonusImg: null,
      shieldIcon: require('../src/assets/img/sweepstakes/id-verification/icons-lucky-lounge-secure.png').default,
      iconAlertWarning: require('../src/assets/img/sweepstakes/icon-alert-warning.svg').default,
      quickDrawImages: {
        AZ_MRL: {
          '01': require('../src/assets/img/quick-draw/AZ_MRL-1.jpg').default,
          '02': require('../src/assets/img/quick-draw/AZ_MRL-2.jpg').default,
          '03': require('../src/assets/img/quick-draw/AZ_MRL-3.jpg').default,
          '04': require('../src/assets/img/quick-draw/AZ_MRL-4.jpg').default,
          '05': require('../src/assets/img/quick-draw/AZ_MRL-5.jpg').default,
          '06': require('../src/assets/img/quick-draw/AZ_MRL-6.jpg').default
        }
      }
    };

    config.internalConfig = {
      DISABLE_EARN_CORNER_ICON: false,
      FIELDS_VARIANT: 'standard',
      SHOW_TICKETDISCLAIMER: false,
      CURRENCY_FORMAT: constants.CURRENCY_FORMAT,
      GOOGLE_PLACES_API_KEY: constants.GOOGLE_PLACES_API_KEY,
      POINTS_FORMAT: constants.POINTS_FORMAT,
      PHONE_MASK: constants.PHONE_MASK,
      HISTORY_FILTER_START_DAYS: constants.HISTORY_FILTER_START_DAYS,
      MEDIA_API: constants.MEDIA_API,
      BASE_URL: constants.BASE_URL,
      TICKET_TABS_DATA: getTicketTab(false, false),
      TICKET_FOCUS_NEXT_FIELD: true,
      DATE_FORMAT: constants.DATE_FORMAT,
      DATE_TIME_FORMAT: constants.DATE_TIME_FORMAT,
      REWARDS_MAXIMUM: constants.REWARDS_MAXIMUM,
      BUTTON_BACKGROUND: 'primary',
      TABLE_CELL_BACKGROUND: 'primary',
      EARN_ICON: 'icon-KS-earn',
      DRAW_ICON: 'icon-draws',
      REWARDS_ICON: 'icon-KS-rewards',
      DISABLE_RIPPLE: false,
      SHOW_DRAW_DATE: true,
      SHOW_REWARD_TYPE_COLUMN: true,
      HIDE_REWARDS_INFO: constants.HIDE_REWARDS_INFO,
      SHOW_REWARD_COLUMN: false,
      SHOW_IMAGE_PREFIX: false,
      TICKET_HELP_DATA: [],
      codeBlockInstructionsRegistrationLink:
        'Share this invitation link with a friend. The invite code will automatically apply when your friend registers for a PlayOn account.',
      codeBlockInstructionsRegistrationPage: "Invite a friend to join PlayOn with the invite code above and you'll BOTH earn points.",
      SHOW_USER_POINTS: constants.SHOW_USER_POINTS,
      COUPON_BACKGROUND: constants.COUPON_BACKGROUND,
      GAME_DRAWS_PER_PAGE: constants.GAME_DRAWS_PER_PAGE,
      DRAW_ENDING_SOON_PERIOD: constants.DRAW_ENDING_SOON_PERIOD,
      JACKPOT_RESULTS_PER_PAGE: constants.JACKPOT_RESULTS_PER_PAGE,
      PROMO_COMING_SOON_PERIOD: 0,
      IGNORE_ACCENT_DIGITAL_REVEAL: true,
      MAX_FREE_ENTRIES: 1,
      PAYMENT: {
        NAME_VALIDATION: validation.holderNameValidator,
        ZIP_VALIDATION: validation.zipCodeValidator,
        PAYSAFE_ENV: '',
        PAYSAFE_API_KEY: ''
      },
      VIRTUALTOUR: virtualTourConfiguration,
      SHOW_TITLE_ABOVE_IMAGE: constants.SHOW_TITLE_ABOVE_IMAGE,
      SHOW_SUPER_BONUS_DIALOG: constants.SHOW_SUPER_BONUS_DIALOG,
      SHOW_BONUS_POINTS: constants.SHOW_BONUS_POINTS,
      SHOW_TRANSACTION_ID: constants.SHOW_TRANSACTION_ID,
      HIDE_PENDING_MODAL: constants.HIDE_PENDING_MODAL,
      SHOW_WINNER_ANNOUNCEMENT_DATE: constants.SHOW_WINNER_ANNOUNCEMENT_DATE,
      SWITCH_FAST_PLAY_VALIDATION_CODE: constants.SWITCH_FAST_PLAY_VALIDATION_CODE
    };
    // Fetch remote constants
    RemoteConstants.sync().then();
    API.setup(apiConfiguration, { requestTransFormation: { addRequestTransform, addResponseTransform } });
    AUTHAPI.setup(apiAuthConfiguration, { requestTransFormation: { addRequestTransform, addResponseTransform } });
    setIntialized(true);
  };

  useEffect(() => {
    intialize();
  }, []);

  if (!intialized) {
    return null;
  }

  if (!loaded) {
    return <AppDeviceHandler onReady={onDeviceReady} />;
  }

  return <AppRoot />;
};

export default App;
