import color from 'color';
import { IThemeColor } from '@pbl/pbl-react-web-components/lib/assets/jss/colors';

const themeColors: IThemeColor = {
  primaryColor: color('#3851AF'),
  secondaryColor: color('#C4D600'),
  warningColor: color('#FEDC00'),
  dangerColor: color('#D11600'),
  successColor: color('#6AD359'),
  infoColor: color('#00acc1'),
  roseColor: color('#e91e63'),
  grayColor: color('#6E7C8D'),
  white: color('#ffffff'),
  black: color('#000000'),
  accessibilityWarningColor: color('#d82a00'),
  cornerColors: {
    activity: color('#3851AF'),
    activityContrast: color('#FFFFFF'),
    reward: color('#ff9933'),
    rewardContrast: color('#000000'),
    promotion: color('#FFFFFF'),
    promotionContrast: color('#3851AF'),
    game: color('#3851AF'),
    gameContrast: color('#FFFFFF')
  },
  boxShadowColor: color('#111835')
};

const themeSweepstakesColors: {
  primaryColor: color;
  secondaryColor: color;
  textPrimaryColor: color;
  textSecondaryColor: color;
  dangerColor: color;
  white: color;
  black: color;
  warningColor: color;
  backgroundColor: color;
} = {
  primaryColor: color('#FDB71C'),
  secondaryColor: color('#eed494'),
  textPrimaryColor: color('#FFFFFF'),
  textSecondaryColor: color('#f3f4c5'),
  dangerColor: color('#FF4832'),
  white: color('#ffffff'),
  black: color('#000000'),
  warningColor: color('#FEC524'),
  backgroundColor: color('#36322B')
};

const appColors = {
  themeColors,
  themeSweepstakesColors
};

export default appColors;
