import { Button, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { ISuperBonusState } from '@pbl/pbl-react-core/lib/models/super-bonus/types';
import { GamesService } from '@pbl/pbl-react-core/lib/services/games-service';
import EligibleGamesDialog from '@pbl/pbl-react-web-components/lib/games/EligibleGamesDialog';
import { AppSpinner, DetailTitle } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/collect-them-all/CollectThemAllScreenStyle';
import { useActivityDetailsData } from 'modules/earn/hooks/useActivityDetailsData';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { IRootState } from 'redux/reducers';
import { fetchActivityWithFrecCap, getActivity } from 'redux/reducers/activity/actions';
import { fetchActivities, fetchUserActivities } from 'redux/reducers/loyalty/actions';
import { getSuperBonusDetails } from 'redux/reducers/super-bonus/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import background2 from '../../assets/img/collect-them-all/claim-it-all-2-lg.png';
import background1 from '../../assets/img/collect-them-all/claim-it-all-lg.png';
import CollectThemAll from './collect-them-all';

const useStyles = makeStyles(styles);

interface ICollectThemAllProps extends ConnectedProps<typeof connector> {
  loggedIn: boolean;
}

const CollectThemAllScreen: React.FC<ICollectThemAllProps> = props => {
  const id: any = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const { push: navigate } = useHistory();
  const [superBonus, setSuperBonus] = React.useState<ISuperBonusState | undefined>(undefined);
  const [relatedGameList, setRelatedGameList] = React.useState<any>(undefined);
  const superBonusObj = useSelector((state: IRootState) => state.superBonus);
  const [showEligibleGamesDialog, setShowEligibleGamesDialog] = React.useState<boolean>(false);

  // This function is used in case a user directly opens the link of the activity in their browser
  // If the link is open directly in the browser - location.state is undefined, therefore we need to fetch the activity data by ID
  const fetchActivityData = async (activityId: number) => {
    const collectActivity = await getActivity(activityId);
    if (collectActivity) {
      const activityKey = collectActivity.key;
      dispatch(getSuperBonusDetails(activityKey));
      dispatch(fetchActivityWithFrecCap(activityKey));
    }
  };

  const { superBonusKey } = location.state || ({} as any);
  useEffect(() => {
    document.title = 'Collect Them All Activity';
    if ((location.state && (location.state as { activityKey: string }).activityKey) || superBonusKey) {
      const activityKey = (location.state as { activityKey: string }).activityKey || superBonusKey;
      dispatch(getSuperBonusDetails(activityKey));
      dispatch(fetchActivityWithFrecCap(activityKey));
    } else if (id.id !== undefined && id.id !== null) {
      fetchActivityData(id.id);
    }
  }, [dispatch, location.state, superBonusKey]);

  const getRelatedGames = useCallback(
    async games => {
      const gameIds: any = await GamesService.getPublishedGamesByIds(games);
      const listOfGames = gameIds.parsedGames;
      if (props.loggedIn) {
        for (const game of listOfGames) {
          const listOfEntries = superBonus?.superBonusDetails?.games;
          if (listOfEntries) {
            const gameIndex = listOfEntries?.findIndex(s => s.gameId === game.id.toString());
            game.currentEntries = listOfEntries[gameIndex]?.currentEntries;
            game.maxEntries = listOfEntries[gameIndex]?.maxEntries;
          }
        }
      }
      gameIds.parsedGames = listOfGames.sort((a, b) => (a.currentEntries > b.currentEntries ? -1 : 1));

      setRelatedGameList(gameIds);
    },
    [props.loggedIn, superBonus?.superBonusDetails?.games]
  );

  useEffect(() => {
    const games = superBonus?.superBonusDetails?.games?.map(game => game.gameId);
    if (games) {
      getRelatedGames(games);
    }
  }, [superBonus, getRelatedGames]);

  useEffect(() => {
    setSuperBonus(superBonusObj);
  }, [superBonusObj, superBonus]);

  const navigateBackToEarn = () => {
    history.push('/earn');
  };

  const showRelatedGames = () => {
    setShowEligibleGamesDialog(true);
  };

  const hideRelatedGamesDialog = () => {
    setShowEligibleGamesDialog(false);
  };

  const navigateToTicketEntry = () => {
    history.push('/ticket-entry', { from: location.pathname });
  };
  const navigateToActivities = useCallback((message?: IErrorParams) => navigate('/earn', { message }), [navigate]);

  const showInvalidError = useCallback(() => {
    navigateToActivities({
      message: t('activities.activityUnavailable'),
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToActivities, t]);
  const { activity } = useActivityDetailsData(id.id, showInvalidError);

  const entries = superBonus?.superBonusDetails?.numberOfBucketsFilled ? superBonus?.superBonusDetails?.numberOfBucketsFilled : 0;
  const totalEntries = superBonus?.superBonusDetails?.superBonusDTO?.minBuckets
    ? superBonus?.superBonusDetails?.superBonusDTO?.minBuckets
    : 0;

  if (superBonus?.loading) {
    return <AppSpinner label={'Loading...'} />;
  }

  const theme = superBonus?.superBonusDetails?.superBonusDTO?.theme;
  const iconTheme = theme ? JSON.parse(theme)?.icon : null;

  const limitReached = activity?.frequencyCap?.limitReached ?? false;
  return (
    <>
      <ScrollToTopOnMount />
      <EligibleGamesDialog
        title={'Detailed Progress'}
        relatedGames={relatedGameList?.parsedGames}
        handleClose={hideRelatedGamesDialog}
        openDialog={showEligibleGamesDialog}
        bucketType={superBonus?.superBonusDetails?.bucketType}
      />
      <Grid className={classes.root} container={true} spacing={2}>
        <DetailTitle
          title={superBonus?.superBonusDetails?.superBonusDTO?.name}
          linkText={'Earn'}
          buttonText={'ENTER TICKET'}
          onPress={navigateToTicketEntry}
          navigateTo={navigateBackToEarn}
        />
        <CollectThemAll
          description={superBonus?.superBonusDetails?.superBonusDTO?.description}
          endDate=""
          imageUrl={superBonus?.superBonusDetails?.superBonusDTO?.image}
          rulesDocument={superBonus?.superBonusDetails?.superBonusDTO?.rulesDocument}
          startDate=""
          title={superBonus?.superBonusDetails?.superBonusDTO?.name}
          totalEntries=""
          navigateToTicketEntry={navigateToTicketEntry}
          maximimumLimitReached={limitReached}
        />

        {props.loggedIn && (
          <Grid className={classes.collectTable} container={true}>
            <Grid className={classes.firstSec} item={true} xs={12} md={5} lg={5}>
              <Grid style={{ width: '20%', margin: '10px 0px 10px 10px' }}>
                <img
                  src={iconTheme?.toLowerCase() === 'secondary' ? background2 : background1}
                  alt="Claim it all"
                  style={{ height: '100%', width: '100%' }}
                />
              </Grid>
              <Grid style={{ width: '80%', margin: '10px', alignContent: 'center' }}>
                <Typography variant="body1" style={{ color: 'black', fontWeight: 'bold' }}>
                  {t('collectThemAll.infoTitle')}
                </Typography>
                <Typography>{t('collectThemAll.infoDescription')}</Typography>
              </Grid>
            </Grid>

            <Grid className={classes.secondSec} item={true} xs={12} md={5} lg={5}>
              <Grid style={{ width: '20%', margin: '10px', textAlign: 'center' }}>
                <Typography variant="h4" style={{ color: 'white' }}>
                  {entries}/{totalEntries}
                </Typography>
                <Typography variant="caption" style={{ color: 'white' }}>
                  {t('collectThemAll.collected')}
                </Typography>
              </Grid>

              <Grid style={{ width: '80%', margin: '10px' }}>
                <Typography style={{ color: 'white' }}>{t('collectThemAll.progressTracker')}</Typography>
                <Typography>
                  <LinearProgress
                    variant="determinate"
                    color="primary"
                    value={(entries * 100) / totalEntries}
                    classes={{
                      colorPrimary: classes.linearColorPrimary,
                      barColorPrimary: classes.linearBarColorPrimary
                    }}
                  />
                </Typography>
                <Button className={classes.progressButton} onClick={showRelatedGames} color="primary" variant="contained" size="small">
                  {t('collectThemAll.viewProgressBtn')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = ({
  authentication: { account, accessToken },
  loyalty: { selectedActivity },
  activityState: { activity }
}: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  return {
    selectedActivity,
    loggedIn,
    activity
  };
};

const mapDispatchToProps = {
  fetchActivities,
  fetchUserActivities,
  fetchActivityWithFrecCap
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CollectThemAllScreen);
