import { ISubmitResponse, ISurveyActivity } from '@pbl/pbl-react-core/lib/models/survey/pbl/types';
import { SurveyService } from '@pbl/pbl-react-core/lib/services';

export const getSurvey = async (key: string): Promise<ISurveyActivity | undefined> => {
  try {
    return await SurveyService.getSurvey(key);
  } catch (e) {
    console.error(e);
  }
};

export const submit = async (data: ISubmitResponse) => {
  try {
    return await SurveyService.submit(data);
  } catch (e: any) {
    console.error(e);
    return e?.payload;
  }
};

export const submitQuiz = async (data: ISubmitResponse) => {
  try {
    return await SurveyService.submitQuiz(data);
  } catch (e: any) {
    console.error(e);
    return e?.payload;
  }
};

export const submitPoll = async (data: ISubmitResponse) => {
  try {
    return await SurveyService.submitPoll(data);
  } catch (e: any) {
    console.error(e);
    return e?.payload;
  }
};

export const getPlayerResponses = async (id: number) => {
  try {
    return await SurveyService.getResponses(id);
  } catch (e) {
    console.error(e);
  }
};
