import { IDraw, IDrawPrize, IPromotion } from '@pbl/pbl-react-core/lib/models/draws/types';
import { ICoupon, IRewardDetails, RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import { useCallback, useEffect, useState } from 'react';
import { fetchDrawPrizesById } from 'redux/reducers/draws/actions';
import {
  fetchCoupon,
  fetchEntries,
  fetchRedemption,
  fetchRewardById,
  fetchRewardWithFrequencyCapById,
  getActiveDrawByPromotionId,
  getPromotionByPromotionId
} from 'redux/reducers/reward/actions';
import { useIsLoggedIn } from '../useIsLoggedIn';

export const useRewardData = (rewardId: string, onError: () => void) => {
  const isLoggedIn = useIsLoggedIn();
  const [reward, setReward] = useState<IRewardDetails>();
  const [activeDraw, setActiveDraw] = useState<IDraw>();
  const [drawPrizes, setDrawPrizes] = useState<IDrawPrize[]>([]);
  const [entries, setEntries] = useState<number | undefined>();
  const [coupon, setCoupon] = useState<ICoupon>();
  const [redemption, setRedemption] = useState<ICoupon>();
  const [promotion, setPromotion] = useState<IPromotion>();

  const initData = useCallback(async () => {
    const fetchFunction = isLoggedIn ? fetchRewardWithFrequencyCapById : fetchRewardById;

    const rewardData = await fetchFunction(rewardId);
    if (!rewardData) {
      onError();
      return;
    }

    if (rewardData.type === RewardType.DRAW) {
      const draw = await getActiveDrawByPromotionId(rewardData.externalId);
      if (draw) {
        const prizes = await fetchDrawPrizesById(draw?.id);
        const drawEntries = await fetchEntries(draw?.id);
        setEntries(drawEntries);
        setDrawPrizes(prizes ?? []);
      }
      setActiveDraw(draw);

      const promotionData = await getPromotionByPromotionId(rewardData.externalId);
      setPromotion(promotionData);
    }
    if (rewardData.type === RewardType.COUPON) {
      const couponData = await fetchCoupon(rewardData.key);
      setCoupon(couponData);
    }
    if (rewardData.type === RewardType.EXTERNAL) {
      const redemptionFrequency = await fetchRedemption(rewardData.key);
      setRedemption(redemptionFrequency);
    }
    if (rewardData.type === RewardType.GAMEPLAY) {
      const redemptionFrequency = await fetchRedemption(rewardData.key);
      setRedemption(redemptionFrequency);
    }

    setReward(rewardData);
  }, [rewardId, isLoggedIn, onError]);

  useEffect(() => {
    initData();
  }, [initData]);

  return { reward, activeDraw, drawPrizes, entries, coupon, promotion, redemption };
};
