import { Card, CardContent, Hidden, Typography, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from 'assets/jss/modules/resend-activation/ResendActivationScreenStyle';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import constants from 'config/constants';
import ResendActivationForm from './components/ResendActivationForm';

interface IResendActivationScreenProps extends RouteComponentProps, WithStyles<typeof styles> {}

class ResendActivationScreen extends React.Component<IResendActivationScreenProps> {
  public componentDidMount(): void {
    document.title = `${constants.PROJECT_NAME} - Re-send Activation Email`;
  }

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.resendActivationScreen}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem sm={10} md={6}>
              <Card className={classes.resendActivationCard}>
                <CardContent className={classes.resendActivationCardContent}>
                  <div className={classes.resendActivationCardContentInner}>
                    <Hidden xsDown={true}>
                      <Typography color="primary" variant="h4" className={classes.titleText}>
                        Re-send Activation Email
                      </Typography>
                    </Hidden>
                    <Hidden smUp={true}>
                      <Typography color="primary" variant="h6" className={classes.titleText}>
                        Re-send Activation Email
                      </Typography>
                    </Hidden>
                    <ResendActivationForm />
                  </div>
                </CardContent>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ResendActivationScreen));
