import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import { useCallback, useEffect, useState } from 'react';
import { fetchActivities, fetchUserActivities } from 'redux/reducers/activity/actions';
import { useIsLoggedIn } from '../../../hooks/useIsLoggedIn';

export const useActivityData = () => {
  const [activities, setActivities] = useState<IActivity[]>([]);

  const isLoggedIn = useIsLoggedIn();
  const initData = useCallback(async (): Promise<void> => {
    let data: IActivity[] | undefined;
    const list = isLoggedIn ? await fetchUserActivities(false) : await fetchActivities(false);
    const featured = isLoggedIn ? await fetchUserActivities(true) : await fetchActivities(true);
    data = featured && list ? [...featured, ...list] : list;
    setActivities(data ?? []);
  }, [isLoggedIn]);

  useEffect(() => {
    initData();
  }, [initData]);

  return {
    activities
  };
};
