import { Grid, Icon, Link as LinkComponent, SvgIcon, Tooltip } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/shared/components/footer/FooterStyles';
import classNames from 'classnames';
import { INavItem } from 'config/menu';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface ISocialMenuProps {
  mainMenu: INavItem[];
  isAuthenticated: boolean;
}

const TwitterLogo: React.FunctionComponent<{classes: string}> = ({ classes }) =>
  <SvgIcon
    className={classes}
    viewBox="0 0 1200 1227"
    style={{
      width: '14px',
      height: 'auto'
    }}
  >
    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" />
  </SvgIcon>;

interface IProps extends ISocialMenuProps, WithStyles<typeof styles> {}

const SocialMenu: React.FunctionComponent<IProps> = ({ mainMenu, classes, isAuthenticated }) => {
  const renderMenuItem = (item: INavItem) => {
    // @ts-ignore
    const renderLink = (props: ButtonProps & { children?: React.ReactNode }) => <Link {...props} to={item.route} />;
    if (item.isExternal) {
      return (
        <Grid className={classes.listItemSocial} key={`${item.title}-${item.route}`}>
          <Tooltip title={item.title} aria-label={item.title}>
            <a
              href={item.route}
              target="_blank"
              className={classNames({
                [classes.navLink]: true,
                [classes.navLinkActive]: !!item.isActive
              })}
              color="primary"
            >
              {item.key === 'twitter' ? (
                <TwitterLogo classes={classes.navLinkIcon} />
              ) : (
                <Icon color="inherit" className={classNames(item.icon, classes.navLinkIcon)} />
              )}
            </a>
          </Tooltip>
        </Grid>
      );
    } else {
      return (
        <Grid className={classes.listItemSocial} key={`${item.title}-${item.route}`}>
          <Tooltip title={item.title} aria-label={item.title}>
            <LinkComponent
              component={renderLink}
              className={classNames({
                [classes.navLink]: true,
                [classes.navLinkActive]: !!item.isActive
              })}
              color="primary"
            >
              <Icon color="inherit" className={classNames(item.icon, classes.navLinkIcon)} />
            </LinkComponent>
          </Tooltip>
        </Grid>
      );
    }
  };
  const menu = isAuthenticated ? mainMenu.filter(x => !x.hideOnAuth) : mainMenu.filter(x => !x.hideOnUnAuth);
  return (
    <Grid item={true} container={true} alignItems="center" justify="center">
      {menu.map(item => renderMenuItem(item))}
    </Grid>
  );
};
export default withStyles(styles)(SocialMenu);
