import { ActivityType, IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

export const useNavigateToActivity = () => {
  const { push: navigate } = useHistory();

  const navigateToActivity = useCallback(
    (activity: IActivity): void => {
      if (activity.type === ActivityType.COLLECT_ALL) {
        navigate({
          pathname: `/collect-them-all/${activity.id}`,
          state: { activityKey: activity.key }
        });
      } else if (activity.type === ActivityType.STANDARD_ACTIVITY && activity.key === window._env_.REACT_APP_INVITE_FRIENDS_ACTIVITY_KEY) {
        // In standard activity, when the key matches the invite-friend key in env variables only then navigate to /invite-friends
        navigate({ pathname: 'invite-friends' });
      } else {
        navigate({ pathname: `/${activity.type.toLowerCase().replace('_', '-')}/${activity.id}` });
      }
    },
    [navigate]
  );

  return {
    navigateToActivity
  };
};
