import { WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { IBonusCampaign } from '@pbl/pbl-react-core/lib/models/activity/types';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IDigitalGame, IPrize } from '@pbl/pbl-react-core/lib/models/digital-game';
import { IDigitalRevealTokenDetails, PrizeType } from '@pbl/pbl-react-core/lib/models/ticket/types';
import InteractiveGame from '@pbl/pbl-react-web-components/lib/earn/v2/InteractiveGame';
import { AppSpinner } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import { useActivityDetailsData } from 'modules/earn/hooks/useActivityDetailsData';
import React, { useCallback, useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { getBonusCampaign } from 'redux/reducers/activity/actions';
import { showMessageBar } from 'redux/reducers/app/actions';
import { endGame, fetchDigitalGame, getGameConfig, playForFun, playForPoints } from 'redux/reducers/digital-game/action';
import { fetchUserPoints } from 'redux/reducers/ledger/actions';
import { getPrizeByExtraData } from 'redux/reducers/prize-distribution/action';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';
import styles from '../../assets/jss/modules/earn/EarnScreenStyle';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';
import { useScrollToTop } from '../../hooks/useScrollToTop';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}
const getSpinResultsFromExtraData = (extraData: string) => {
  try {
    if (!!extraData) {
      const obj = JSON.parse(extraData);
      if (!!obj.spinResults) {
        return JSON.stringify(obj.spinResults);
      }
    }
  } catch (e) {
    return undefined;
  }
};

const InteractiveGameScreen: React.FC<IProps> = ({ classes, t }) => {
  const { activityId }: Record<string, string> = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const onNavigate = useCallback(() => navigate('/earn', { from: pathname }), [navigate, pathname]);
  const navigateToActivities = useCallback((message?: IErrorParams) => navigate('/earn', { message }), [navigate]);

  const showInvalidError = useCallback(() => {
    navigateToActivities({
      message: t('activities.activityUnavailable'),
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToActivities, t]);

  const { activity, initData } = useActivityDetailsData(activityId, showInvalidError);
  const loggedIn: boolean = useIsLoggedIn();

  const [gameCompleted, setGameCompleted] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [gameType, setGameType] = React.useState('');
  const [digitalGameActivity, setDigitalGameActivity] = React.useState<IDigitalGame>();
  const [gameConfig, setGameConfig] = React.useState<IDigitalRevealTokenDetails>();
  const [prizes, setPrizes] = React.useState<IPrize[]>([]);
  const [multiplier, setMultiplier] = React.useState<IBonusCampaign>();
  const [loading, setLoading] = React.useState(false);
  useScrollToTop();

  const showErrorMessage = error => {
    dispatch(
      showMessageBar({
        message: error?.payload?.detail?.includes('invalidAccess') ? t('error.activity.invalidAccess') : t(error?.payload?.errorKey),
        type: 'error'
      })
    );
  };

  const getGameData = useCallback(
    async (type?: string) => {
      if (!loggedIn) {
        navigate('/login', { from: pathname });
        return;
      }
      setRefreshing(true);
      setGameType(type ?? 'prize');
      let data: IPrize[] | undefined = [];
      if (activity) {
        data = type === 'fun' ? await playForFun(activity.key, showErrorMessage) : await playForPoints(activity.key, showErrorMessage);
      }
      setPrizes(data ?? []);
      setRefreshing(false);
    },
    [activity]
  );

  const getDigitalGameConfig = useCallback(async (token: string) => {
    const data = await getGameConfig(token);
    if (data) {
      setGameConfig(data);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (activity && activity.frequencyCap?.limitReached) {
        setLoading(true);
        try {
          const response = await getPrizeByExtraData(activity.key);
          if (response) {
            try {
              const bonus_response = await getBonusCampaign(parseInt(response.externalId, 10));
              if (bonus_response) {
                setMultiplier(bonus_response);
              }
            } catch (error) {
              console.error(error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [activity]);

  useEffect(() => {
    setRefreshing(true);
    if (prizes && prizes.length > 0) {
      getDigitalGameConfig(prizes[0].digitalRevealToken);
    }
    setRefreshing(false);
  }, [prizes, getDigitalGameConfig]);

  const getDigitalGame = useCallback(async (activityKey: string) => {
    const data = await fetchDigitalGame(activityKey, (e: any) => {
      dispatch(
        showMessageBar({
          message: e?.payload?.detail?.includes('invalidAccess') ? t('error.activity.invalidAccess') : t(e?.payload?.errorKey),
          type: 'error'
        })
      );
    });
    if (data) {
      setDigitalGameActivity(data);
    }
  }, []);

  useEffect(() => {
    if (activity) {
      getDigitalGame(activity.key);
      scrollToTheTop();
    }
  }, [activity]);

  const gameEnded = async () => {
    setRefreshing(true);
    if (gameCompleted) {
      if (prizes && prizes.length > 0 && prizes[0].digitalRevealToken) {
        await endGame(prizes[0].digitalRevealToken);
        await dispatch(fetchUserPoints());
        await initData();
      }
    }

    setRefreshing(false);
  };

  const onGameClose = async () => {
    if (prizes && prizes.length > 0 && prizes[0].digitalRevealToken && prizes[0].prizeType === PrizeType.BONUS_CAMPAIGN) {
      await endGame(prizes[0].digitalRevealToken);
      await dispatch(fetchUserPoints());
      await initData();
    } else {
      await dispatch(fetchUserPoints());
      await initData();
    }
  };

  const setGameCompletedFunc = () => {
    setGameCompleted(true);
  };

  if (!activity) {
    return null;
  }

  const interactiveGameConfig = gameConfig?.gameConfig;

  let spinResults;
  const gamePoints = prizes && prizes.length > 0 ? prizes[0].baseAmount : 0;

  if (gameConfig && gameConfig.face) {
    spinResults = getSpinResultsFromExtraData(gameConfig.face);
  }
  const renderTitle = () => <DetailTitle title={activity.title} linkText={'Earn'} navigateTo={onNavigate} />;

  return (
    <div>
      <ScrollToTopOnMount />
      <Grid className={classes.container} container={true} spacing={2}>
        {loading ? (
          <AppSpinner label={t('loading')} />
        ) : (
          <InteractiveGame
            getGameData={getGameData}
            renderTitle={renderTitle}
            selectedUserActivity={activity}
            playForFun={digitalGameActivity?.playForFun as boolean}
            digitalRevealTokenDetails={gameConfig}
            numPoints={activity.amount}
            limitReached={activity.frequencyCap ? activity.frequencyCap.limitReached : false}
            refreshing={refreshing}
            gameFace={gameConfig?.face}
            gameConfig={interactiveGameConfig}
            gameUrl={gameConfig?.themeHostName ?? ''}
            gamePoints={gamePoints}
            spinResults={spinResults}
            gameType={gameType}
            onGameExitHandler={gameEnded}
            onGameEndHandler={setGameCompletedFunc}
            portraitMode={activity.extraData['portraitMode']}
            isLoggedIn={loggedIn}
            onClose={onGameClose}
            multiplier={multiplier}
          />
        )}
      </Grid>
    </div>
  );
};
export default withTranslation()(withStyles(styles)(InteractiveGameScreen));
