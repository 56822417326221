import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import BonusCard from '@pbl/pbl-react-web-components/lib/card/BonusCard';
import { ICornerIconProps } from '@pbl/pbl-react-web-components/lib/card/CornerIcon';
import ActivityListItem from '@pbl/pbl-react-web-components/lib/earn/v2/ActivityListItem';
import colors from 'assets/jss/colors';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import { IHomeEarnActivity } from 'config/homeScreenEarnActivities';
import { useNavigateToActivity } from 'modules/earn/hooks/useNavigateToActivity';
import EarnCard from 'modules/home/components/EarnCard';
import SectionHeading from 'modules/home/components/SectionHeading';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  activities: IHomeEarnActivity[];
  earnActivities: IActivity[];
  bonusActivity: IActivity[];
}

const cornerIcon: ICornerIconProps = {
  icon: 'verified',
  iconType: 'material',
  contentColor: colors.themeColors.white.hex(),
  trianglePosition: { top: 0, left: 0 },
  triangleType: 'topLeft',
  triangleBackgroundColor: colors.themeColors.primaryColor.hex(),
  triangleSize: 88
};

const EarnSection: React.FunctionComponent<IProps> = ({ classes, activities, earnActivities, t, bonusActivity }) => {
  const { navigateToActivity } = useNavigateToActivity();

  const activitiesToRender: IActivity[] = earnActivities
    .filter(activity => activity.visibilityWeb)
    .filter((_1, index) => index < constants.HOME_SCREEN_EARN_ACTIVITIES_COUNT);

  const goToDetails = () => navigateToActivity(bonusActivity[0]);

  return (
    <div className={classNames(classes.section)}>
      <div
        style={{
          marginBottom: bonusActivity.length > 0 ? '32px' : 0
        }}
      >
        <SectionHeading
          title={t('home.earnSectionTitle')}
          linkRoute="/earn"
          linkTitle={t('home.earnSectionLink')}
          linkLabel={t('home.earnSectionLinkLabel')}
        />
      </div>

      {bonusActivity.length > 0 ? <BonusCard onPress={goToDetails} activity={bonusActivity[0]} cornerIcon={cornerIcon} /> : null}

      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {activitiesToRender &&
          activitiesToRender.map(activity => {
            const onSelect = () => navigateToActivity(activity);

            return (
              <Grid key={activity.id} xs={12} md={4} item={true} className={classes.sectionCard}>
                {<ActivityListItem activity={activity} onPress={onSelect} />}
              </Grid>
            );
          })}
      </Grid>

      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {activities.map(activity => (
          <Grid key={activity.activityId} xs={12} md={4} item={true} className={classes.sectionCard}>
            {<EarnCard activity={activity} />}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(EarnSection));
