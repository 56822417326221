import { GET_PAST_HISTORY_ACTIVITIES, GET_PAST_HISTORY_REWARDS } from '@pbl/pbl-react-core/lib/models/kslegacy/types';
import { KSLegacyService } from '@pbl/pbl-react-core/lib/services';
import { IRequestParams } from '@pbl/pbl-react-core/lib/services/types';
import { updatePaging } from 'redux/reducers/history/pagination/actions';

export const fetchPastRewardHistoryList = (id: string, request: IRequestParams) => async (dispatch: any) => {
  try {
    const data = await KSLegacyService.getRewardHistory(id, request);
    dispatch({
      type: GET_PAST_HISTORY_REWARDS,
      payload: data.content ?? []
    });
    dispatch(updatePaging('pastRewards', data.page.number, request?.size ?? 0, data.page.totalPages));
  } catch (e) {
    console.error(e);
  }
};

export const fetchPastActivityHistoryList = (id: string, request: IRequestParams) => async (dispatch: any) => {
  try {
    const data = await KSLegacyService.getActivityHistory(id, request);
    dispatch({
      type: GET_PAST_HISTORY_ACTIVITIES,
      payload: data.content ?? []
    });
    dispatch(updatePaging('pastActivities', data.page.number, request?.size ?? 0, data.page.totalPages));
  } catch (e) {
    console.error(e);
  }
};
