import { GET_UPLOAD_CONTENT, UploadDetailsState, UPLOAD_CONTENT } from '@pbl/pbl-react-core/lib/models/upload/types';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { IDispatchAction } from 'redux/reducers';

const INITIAL_STATE: UploadDetailsState = {
  loading: false,
  errorMessage: null,
  content: null,
  uploadResponse: null
};

export default (state: UploadDetailsState = INITIAL_STATE, action: IDispatchAction): UploadDetailsState => {
  switch (action.type) {
    case REQUEST(GET_UPLOAD_CONTENT):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_UPLOAD_CONTENT):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        content: action.payload
      };
    case FAILURE(GET_UPLOAD_CONTENT):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case REQUEST(UPLOAD_CONTENT):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(UPLOAD_CONTENT):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        uploadResponse: action.payload
      };
    case FAILURE(UPLOAD_CONTENT):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
