import { Theme, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { createMediaURL } from '@pbl/pbl-react-core/lib/utils/mediaUtil';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import noImage from 'assets/img/no-image.svg';
import styles from 'assets/jss/modules/earn/EarnScreenStyle';
import constants from 'config/constants';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import {
  creditUserPoints,
  fetchActivities,
  fetchUserActivities,
  fetchUserPoints,
  resetActivityCompleted,
  resetSelectedActivity,
  setActivityCompleted,
  setSelectedUserActivity
} from 'redux/reducers/loyalty/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

type PropsConnected = ConnectedProps<typeof connector>;

interface IMatchParams {
  activityId: string;
}

export interface IUploadPhotoScreenProps extends PropsConnected, RouteComponentProps<IMatchParams>, WithStyles<typeof styles> {
  theme: Theme;
}

interface IUploadPhotoScreenState {
  loading: boolean;
}

class WatchVideoScreen extends React.Component<IUploadPhotoScreenProps, IUploadPhotoScreenState> {
  constructor(props: IUploadPhotoScreenProps) {
    super(props);
    this.state = {
      loading: false
    };
  }

  public componentWillUnmount() {
    this.props.resetSelectedActivity();
  }

  public async componentDidMount() {
    document.title = 'Watch Video Activity';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params.activityId.trim().length > 0) {
      await this.getData(params.activityId);
    } else {
      await this.showInvalidActivityError();
    }
  }

  public async getData(id) {
    if (this.props.loggedIn) {
      await this.props.fetchUserActivities();
    } else {
      await this.props.fetchActivities();
    }

    const selectedActivity = this.props.loyalty.userActivities.find(activity => activity.id.toString() === id);
    if (selectedActivity) {
      await this.props.setSelectedUserActivity(selectedActivity);
    } else {
      await this.showInvalidActivityError();
    }
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn', {
      message: {
        message: `This activity is not available.`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  private onDismissEarnPointsModal = (): void => {
    this.props.resetActivityCompleted();
    this.props.history.push('/earn');
  };

  private openVideo = async () => {
    await this.props.isAuthenticated();
    if (!this.props.loyalty.selectedActivity) return;
    if (!this.props.loggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    const { id, redirect_url, num_points } = this.props.loyalty.selectedActivity;
    // @ts-ignore
    window.open(redirect_url);
    this.setState({ loading: true });
    await this.props.creditUserPoints({
      activityId: id
    });
    await this.props.fetchUserPoints();
    this.setState({ loading: false });

    if (this.props.loyalty.awarded) {
      this.props.setActivityCompleted({
        pointsEarned: num_points,
        activity: this.props.loyalty.selectedActivity
      });
    }
  };

  public render() {
    const { classes } = this.props;
    const { loading } = this.state;
    const { selectedActivity, activityCompleted, maximumLimitReached } = this.props.loyalty;
    if (!selectedActivity) {
      return null;
    }
    const image = !!selectedActivity.image
      ? // @ts-ignore
        createMediaURL(constants.BASE_URL, constants.MEDIA_API, selectedActivity.image)
      : noImage;
    const navigate = () => {
      this.props.history.push('/earn');
    };
    return (
      <div>
        {loading && <AppSpinner label={'Loading...'} />}
        {activityCompleted !== null ? (
          <EarnPointsModal
            pointEarned={
              !!selectedActivity && selectedActivity.totalBonusPoints > 0
                ? selectedActivity.totalBonusPoints
                : activityCompleted.pointsEarned
            }
            isVisible={activityCompleted !== null}
            onClose={this.onDismissEarnPointsModal}
          />
        ) : null}
        <Grid className={classes.container} container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <EarnActivityHeader
              data={{
                image,
                title: selectedActivity.title,
                description:
                  !!selectedActivity && !!selectedActivity.extra_data && !!selectedActivity.extra_data.additionalInformation
                    ? selectedActivity.extra_data.additionalInformation
                    : selectedActivity.description,
                points: selectedActivity.num_points,
                preview_url:
                  !!selectedActivity.extra_data && !!selectedActivity.extra_data.preview_url
                    ? selectedActivity.extra_data.preview_url
                    : undefined,
                isCoverImage: true
              }}
              buttonText={
                selectedActivity.isVideo ? 'Watch' : selectedActivity.isAudio ? 'Listen' : selectedActivity.isDocument ? 'Read' : 'View'
              }
              onPress={this.openVideo}
              buttonLabel={'Link opens in a new browser tab'}
              buttonLabelIcon={'open_in_new'}
              navigateTo={navigate}
              limitReached={maximumLimitReached}
              bonusPoints={selectedActivity.totalBonusPoints}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication: { account, accessToken }, loyalty }: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  return {
    loyalty,
    loggedIn
  };
};

const mapDispatchToProps = {
  setSelectedUserActivity,
  setActivityCompleted,
  resetActivityCompleted,
  creditUserPoints,
  fetchUserPoints,
  resetSelectedActivity,
  isAuthenticated,
  fetchActivities,
  fetchUserActivities
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(WatchVideoScreen))));
