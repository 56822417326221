import { Button, Grid, withStyles, WithStyles, withTheme } from '@material-ui/core';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { ICity, ICountry, IState } from '@pbl/pbl-react-core/lib/models/address';
import { IAddress } from '@pbl/pbl-react-core/lib/models/forms/types';
import { UserRegistrationDataFields } from '@pbl/pbl-react-core/lib/models/user-registration/types';
import Icon from '@pbl/pbl-react-web-components/lib/icon/Icon';
import { FieldRenderer } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/user-registration/UserRegistrationScreenStyle';
import { RegistrationFields } from 'config/registration';
import React, { useEffect } from 'react';
import AddressComponent from 'shared/components/input/Address';

interface IProps extends ThemedComponentProps, WithStyles<typeof styles> {
  updateField: (fieldName: UserRegistrationDataFields, value: any) => any;
  personalInformationFields: RegistrationFields;
  onBack: () => any;
  handleAddressSelect: (field: string, value: string) => any;
  address: IAddress;
  getStates: (countryId: number) => any;
  getCities: (stateId: number) => any;
  resetStates: () => any;
  resetCities: () => any;
  countries?: ICountry[];
  states?: IState[];
  cities?: ICity[];
  loadingCities: boolean;
}

const PersonalInformation: React.FunctionComponent<IProps> = ({
  updateField,
  personalInformationFields,
  handleAddressSelect,
  address,
  classes,
  onBack,
  getStates,
  getCities,
  resetCities,
  resetStates,
  countries,
  states,
  cities,
  loadingCities
}: IProps) => {

  const getButton = () => {
    return (
      <Grid className={classes.fieldContainer} item={true} xs={12} lg={10}>
        <Button
          onClick={onBack}
          size={'small'}
          aria-label={'PREVIOUS'}
          variant={'text'}
          color={'primary'}
          style={{ textTransform: 'none', paddingLeft: 0 }}
        >
          <Icon iconType={'material'} iconName={'keyboard_arrow_left'} />
          {'PREVIOUS'}
        </Button>
      </Grid>
    );
  };

  const getFields = () => {
    return personalInformationFields.map(field => {
      const onFieldChange = (value: any) => {
        updateField(field.key as UserRegistrationDataFields, value);
      };
      const fieldView = <FieldRenderer key={field.key} field={field} onChange={onFieldChange} className={classes.field} />;
      return (
        <Grid key={field.key} className={classes.fieldContainer} item={true} xs={12} container={true} md={12} lg={10}>
          {fieldView}
        </Grid>
      );
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // Set the focus on the first field to reset the tab order for accessibility
    const gridItem = document.getElementById('address1');
    if (gridItem) {
      gridItem.focus();
    }
  }, []);

  return (
    <Grid item={true} xs={12} container={true} alignItems="center" justify="center">
      {getButton()}
      <Grid key={'address'} item={true} xs={12} container={true} md={12} lg={10}>
        <AddressComponent
          address={address}
          handleTextFieldChange={handleAddressSelect}
          contentClass={classes.addressContent}
          fieldVariant={'filled'}
          getStates={getStates}
          getCities={getCities}
          countries={countries}
          states={states}
          cities={cities}
          resetCities={resetCities}
          resetStates={resetStates}
          loadingCities={loadingCities}
          showMandatory={true}
        />
      </Grid>
      {getFields()}
    </Grid>
  );
};

export default withTheme(withStyles(styles)(PersonalInformation));
