import { Button, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import { IGame } from '@pbl/pbl-react-core/lib/models/games/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import ArcadePointsSection from '@pbl/pbl-react-web-components/lib/arcade/ArcadePointsSection';
import ClaimFreeTokensBar from '@pbl/pbl-react-web-components/lib/message-bar/ClaimFreeTokensBar';
import { ArcadeCardContainer, ArcadeRewardCard, VirtualTourModal } from '@pbl/pbl-react-web-components/lib/package';
import logo from 'assets/img/sweepstakes/logos-lucky-lounge-vertical-gold.png';
import styles from 'assets/jss/modules/arcade/ArcadesScreenStyle';
import constants from 'config/constants';
import moment from 'moment';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { credit, dismissDailyBonus, fetchActivityWithFrecCap } from 'redux/reducers/activity/actions';
import { showMessageBar } from 'redux/reducers/app/actions';
import { fetchArcadeGames } from 'redux/reducers/arcade/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { fetchUserTokens } from 'redux/reducers/ledger/actions';
import { fetchRewardList } from 'redux/reducers/reward/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { setTourInfo } from '../../redux/reducers/virtual-tour/actions';

type PropsConnected = ConnectedProps<typeof connector>;

export interface IArcadesScreenProps extends PropsConnected, WithStyles<typeof styles>, WithTranslation, RouteComponentProps {
  theme: Theme;
}

interface IArcadesScreenState {
  loading: boolean;
  showVirtualTour: boolean;
}

class ArcadesScreen extends React.Component<IArcadesScreenProps, IArcadesScreenState> {
  constructor(props: IArcadesScreenProps) {
    super(props);
    this.state = {
      loading: false,
      showVirtualTour: false
    };
  }

  public async componentDidMount() {
    if (!this.props.completedTour && !this.props.skippedTour) {
      this.showVirtualTour();
    }

    document.title = 'Lucky Lounge';
    this.setState({ loading: true });
    const { location, history } = this.props;
    // @ts-ignore
    if (location && location.state && location.state.message) {
      // @ts-ignore
      this.props.showMessageBar(location.state.message);
      // Clear route state after consuming the message.
      history.replace('/lucky-lounge', {});
    }
    if (this.props.loggedIn) {
      await this.props.fetchUserTokens();
    }
    await Promise.all([
      this.props.fetchArcadeGames('lastModifiedDate,desc'),
      this.props.fetchRewardList(),
      this.props.fetchActivityWithFrecCap(constants.REDEEM_DAILY_BONUS_ACTIVITY)
    ]);

    this.setState({ loading: false });
  }

  private skipTour = () => {
    this.props.setTourInfo(new Date(), new Date());
  };
  private showVirtualTour = () => {
    this.setState({ showVirtualTour: true });
  };

  public navigateToExploreLLScreen = (): void => {
    this.props.history.push(`/lucky-lounge/explore`);
  };

  public selectGame = (game: IGame): void => {
    this.props.history.push(`/lucky-lounge/game/${game.id}`);
  };

  public navigateToBuyScreen = (): void => {
    this.props.history.push('/lucky-lounge/store');
  };

  public signUp = (): void => {
    this.props.history.push('/register', { from: this.props.location });
  };

  public navigateToHistory = (): void => {
    this.props.history.push('/lucky-lounge/history');
  };

  public selectReward = (reward): void => {
    this.props.history.push(`/lucky-lounge/reward/${reward.id}`);
  };

  private onVirtualTourClose = () => {
    this.setState({ showVirtualTour: false });
    this.skipTour();
  };

  public render() {
    const { classes, userTokens, loggedIn, t, arcades, reward } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <AppSpinner label={'Loading...'} />;
    }
    return (
      <>
        {this.renderClaimTokensMessage()}
        <div className={classes.container}>
          {this.renderVirtualTourModal()}
          <ScrollToTopOnMount />
          <Grid container={true} spacing={4} className={classes.gridContainer}>
            <Grid item={true} xs={12} className={classes.header}>
              <img alt={'Lucky Lounge Logo'} src={logo} className={classes.logo} />
              {loggedIn ? (
                <div className={classes.balance}>
                  <ArcadePointsSection
                    balance={userTokens.balance}
                    onClick={this.navigateToBuyScreen}
                    navigateToHistory={this.navigateToHistory}
                  />
                </div>
              ) : (
                <div className={classes.balance}>
                  <ArcadePointsSection
                    balance={userTokens.balance}
                    onClick={this.navigateToBuyScreen}
                    navigateToHistory={this.navigateToHistory}
                    disableHistoryButton={true}
                    disableBalance={true}
                  />
                </div>
              )}
            </Grid>
            <Grid item={true}>
              <Typography variant={'subtitle1'} align={'center'} color={'textPrimary'} gutterBottom={true} component={'h1'}>
                {t('arcade.welcome.title')}
              </Typography>
              <Typography variant={'body1'} align={'center'} color={'textPrimary'}>
                {t('arcade.welcome.text')}
              </Typography>
            </Grid>
            <Grid item={true} alignContent={'center'} className={classes.virtualTour}>
              <Button variant={'contained'} size={'large'} color={'primary'} onClick={this.showVirtualTour}>
                {t('arcade.virtualTour')}
              </Button>
              <Button
                variant={'contained'}
                size={'large'}
                color={'primary'}
                onClick={this.navigateToExploreLLScreen}
                className={classes.exploreLLBtn}
              >
                {t('arcade.exploreLL')}
              </Button>
            </Grid>
            <Grid item={true} xs={12} className={classes.spaceBetween}>
              <Typography variant={'h6'} color={'secondary'} component={'h2'}>
                {t('arcade.games')}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              {arcades.games && arcades.games.length > 0 ? <ArcadeCardContainer games={arcades.games} onPress={this.selectGame} /> : null}
            </Grid>
            <Grid item={true} xs={12} className={classes.spaceBetween}>
              <Typography variant={'h6'} color={'secondary'} component={'h2'}>
                {t('arcade.rewards')}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={3}>
                {reward.rewardsList && reward.rewardsList.length > 0
                  ? reward.rewardsList.map(item => (
                      <Grid item={true} xs={12} sm={12} md={12} lg={6} key={item.title}>
                        <ArcadeRewardCard
                          onPress={this.selectReward.bind(this, item)}
                          title={item.internalName}
                          image={item.imageUrl}
                          tokens={item.amount}
                          list={item.extraData}
                        />
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }

  private renderVirtualTourModal = () => (
    <VirtualTourModal open={this.state.showVirtualTour} onClose={this.onVirtualTourClose} styles={{ outerWidth: '1000px' }} />
  );

  private onCloseClaimTokenMessage = () => {
    this.props.dismissDailyBonus();
  };

  private onRedeemBonus = () => {
    this.props.credit(constants.REDEEM_DAILY_BONUS_ACTIVITY, this.ShowDailySuccessMessage, () => {});
  };

  private ShowDailySuccessMessage = () => {
    const { t } = this.props;
    this.props.showMessageBar({
      message: t('arcade.dailyBonus.claimConfirmation'),
      messageTimeout: 5000
    });
    this.props.fetchUserTokens();
  };
  private renderClaimTokensMessage = () => {
    const limitReached = this.props.activity?.frequencyCap?.limitReached ?? true;
    // Check if the user dismissed the message today
    const today = moment().startOf('day');
    const dateDismissed =
      this.props.dailyBonusRedemption?.dateDismissed === undefined
        ? moment().startOf('day').subtract(2, 'day')
        : moment(this.props.dailyBonusRedemption?.dateDismissed).startOf('day');
    const isDismissedToday = (this.props.dailyBonusRedemption?.dismissed && today.diff(dateDismissed, 'days') === 0) ?? false;

    const show = this.props.loggedIn && !limitReached && !isDismissedToday;
    return (
      <>
        <ClaimFreeTokensBar show={show ?? false} close={this.onCloseClaimTokenMessage} redeemBonus={this.onRedeemBonus} />
      </>
    );
  };
}

const mapStateToProps = ({
  authentication: { account, accessToken },
  ledger: { userTokens },
  arcades,
  reward,
  virtualtour: { completedTour, skippedTour },
  activityState: { activities, dailyBonusRedemption }
}: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;

  return {
    loggedIn,
    userTokens,
    arcades,
    reward,
    completedTour,
    skippedTour,
    activity: activities ? activities[constants.REDEEM_DAILY_BONUS_ACTIVITY] : ({} as IActivity),
    dailyBonusRedemption
  };
};

const mapDispatchToProps = {
  showMessageBar,
  isAuthenticated,
  fetchUserTokens,
  fetchArcadeGames,
  fetchRewardList,
  setTourInfo,
  credit,
  fetchActivityWithFrecCap,
  dismissDailyBonus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTranslation()(ArcadesScreen))));
