import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import { useCallback, useEffect, useState } from 'react';
import { getActivityByKey } from 'redux/reducers/activity/actions';
import { useIsLoggedIn } from '../../../hooks/useIsLoggedIn';

export const useActivityDetailsDataByKey = (activityKey: string, onError: () => void, setLoading: (loading: boolean) => void) => {
  const isLoggedIn = useIsLoggedIn();
  const [activity, setActivity] = useState<IActivity>();

  const initData = useCallback(async () => {
    setLoading(true);
    const data = await getActivityByKey(activityKey, isLoggedIn);
    if (!data) {
      onError();
      return;
    }

    setActivity(data);
    setLoading(false);
  }, [activityKey, isLoggedIn, onError, setLoading]);

  useEffect(() => {
    initData();
  }, [initData]);

  return { activity };
};
