import { Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import background1 from '../../assets/img/outage/graphic-bulb-sm.png';
import background2 from '../../assets/img/outage/group-sm.png';
import background from '../../assets/img/outage/planned-lg.png';
export interface IOutageScreenProps {
  outageType: 'PLANNED'| 'UNPLANNED';
  flag: boolean;
  body: string;
}
const OutageScreen: FC<IOutageScreenProps> = ({ outageType, body, flag }) => {
  const { t } = useTranslation();

  if (flag !== true) {
    return null;
  }

  if (outageType === 'PLANNED') {
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
          alignItems: 'center',
          height: '100vh',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Grid container={true} direction="column" alignItems="center" style={{ height: '100%', paddingTop: '210px' }}>
          <Typography component="h1" style={{ color: 'white' }} variant="h5">
            {t('outage.planned')}
          </Typography>

          <img src={background1} style={{ marginTop: '20px', marginBottom: '20px', height: '50px' }} alt={'Outage'} />

          <Typography component="h6" style={{ color: 'white', maxWidth: '500px', textAlign: 'center' }} variant="body1">
            {body}
          </Typography>
        </Grid>
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        alignItems: 'center',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <Grid container={true} direction="column" alignItems="center" style={{ height: '100%', paddingTop: '210px' }}>
        <Typography component="h1" style={{ color: 'white' }} variant="h5">
          {t('outage.unplanned')}
        </Typography>

        <img src={background2} style={{ marginTop: '20px', marginBottom: '20px', height: '50px' }} alt={'Outage'} />

        <Typography component="h6" style={{ color: 'white', maxWidth: '350px', textAlign: 'center' }} variant="body1">
          {body}
        </Typography>
      </Grid>
    </div>
  );
};

export default OutageScreen;
