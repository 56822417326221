import { PrizebyExtraDataDTO, PrizebyRefDTO } from '@pbl/pbl-react-core/lib/models/prize-distribution/types';
import { PrizeDistributionService } from '@pbl/pbl-react-core/lib/services';

export const getPrizeByExtraData = async (key: string): Promise<PrizebyExtraDataDTO | undefined> => {
  try {
    return await PrizeDistributionService.getPrizeByExtraData(key);
  } catch (e: any) {
    console.error(e);
  }
};

export const getPrizeByRef = async (key: string): Promise<PrizebyRefDTO | undefined> => {
  try {
    return await PrizeDistributionService.getPrizeByRef(key);
  } catch (e: any) {
    console.error(e);
  }
};
