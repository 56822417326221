import { withStyles, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ICodeSubmitResponse } from '@pbl/pbl-react-core/lib/models/activity/types';
import { IErrorDTO, IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import PromoCode from '@pbl/pbl-react-web-components/lib/earn/v2/PromoCode';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import constants from 'config/constants';
import { useActivityDetailsData } from 'modules/earn/hooks/useActivityDetailsData';
import React, { useCallback, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { redeemCodeRedemption } from 'redux/reducers/activity/actions';
import { showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { fetchUserPoints } from 'redux/reducers/ledger/actions';
import styles from '../../assets/jss/modules/earn/PromoCodeScreenStyle';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';
import { useScrollToTop } from '../../hooks/useScrollToTop';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

const PromoCodeScreen: React.FunctionComponent<IProps> = ({ classes, t }) => {
  const dispatch = useDispatch();
  const { activityId }: Record<string, string> = useParams();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const onNavigate = useCallback(() => navigate('/earn', { from: pathname }), [navigate, pathname]);
  const navigateToActivities = useCallback((message?: IErrorParams) => navigate('/earn', { message }), [navigate]);

  const showInvalidError = useCallback(() => {
    navigateToActivities({
      message: t('activities.activityUnavailable'),
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToActivities, t]);

  const { activity } = useActivityDetailsData(activityId, showInvalidError);
  const loggedIn: boolean = useIsLoggedIn();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [responseCode, setResponseCode] = useState<ICodeSubmitResponse>({});
  useScrollToTop();

  const openTos = () => {
    if (activity && activity.termsLink) {
      window.open(activity.termsLink);
    }
  };

  const redeem = useCallback(
    async (promoCode: string) => {
      return redeemCodeRedemption({
        codeValue: promoCode,
        campaignId: constants.HOME_PROMO_CODE_ACTIVITY_KEY !== activity?.key ? activity?.key : undefined
      });
    },
    [activity]
  );

  if (!activity) {
    return null;
  }

  const validatePromoCode = async (promoCode: string) => {
    if (!loggedIn) {
      navigate('/login', { from: pathname });
      return;
    }
    dispatch(
      toggleLoading({
        spinning: true
      })
    );

    setSubmitting(true);

    redeem(promoCode).then(data => validatePromoCodeResponse(data));

    dispatch(fetchUserPoints());
    dispatch(
      toggleLoading({
        spinning: false
      })
    );
  };

  const validatePromoCodeResponse = async (response: ICodeSubmitResponse | IErrorDTO | undefined) => {
    setSubmitting(false);
    if (response && 'errorKey' in response && response.errorKey) {
      dispatch(
        showMessageBar({
          message: response.errorKey,
          type: 'error',
          messageTimeout: 10000
        })
      );
    } else if (response && 'numPoints' in response && response.numPoints) {
      setResponseCode(response as ICodeSubmitResponse);
      setShowModal(true);
    } else {
      dispatch(
        showMessageBar({
          type: 'error',
          message: (response as any)?.detail?.includes('invalidAccess') ? t('error.activity.invalidAccess') : t('error.general'),
          messageTimeout: 10000
        })
      );
    }
  };

  const onDismissEarnPointsModal = (): void => {
    navigate('/earn');
  };

  const renderTitle = () => <DetailTitle title={activity.title} linkText={'Earn'} navigateTo={onNavigate} />;
  return (
    <div>
      {responseCode && responseCode.numPoints && responseCode.numPoints > 0 ? (
        <EarnPointsModal
          pointEarned={responseCode.numPoints}
          isVisible={showModal}
          onClose={onDismissEarnPointsModal}
          message={responseCode.campaignName ? t('codeCampaign.title', { title: responseCode.campaignName }) : null}
          campaignTitle={responseCode.campaignName ? responseCode.campaignName : undefined}
        />
      ) : null}
      <Grid className={classes.container} container={true} spacing={2}>
        <PromoCode
          renderTitle={renderTitle}
          validatePromoCode={validatePromoCode}
          data={{
            termsOfConditions: activity.termsRequired ? activity.termsLink : null,
            imageUrl: activity.imageUrl,
            videoUrl: activity.videoUrl,
            bonusCampaign: Boolean(activity.bonusCampaigns.length > 0 || activity.bonusAmount),
            description: activity.description,
            tosTitle: 'promoCode.tos'
          }}
          submitting={submitting}
          limitReached={activity.frequencyCap?.limitReached}
          openTermsOfConditions={openTos}
        />
      </Grid>
    </div>
  );
};
export default withTranslation()(withStyles(styles)(PromoCodeScreen));
