import { createStyles, Theme } from '@material-ui/core';
import { container, main, mainRaised, mrAuto, mlAuto } from 'assets/jss/material-kit-pro-react.jsx';
// @ts-ignore
const isIE = !!document.documentMode;
// @ts-ignore
export default (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flexGrow: 1
    },
    container: {
      ...container
    },
    // @ts-ignore
    main,
    mainRaised: {
      ...mainRaised,
      flexGrow: 1,
      alignSelf: 'center',
      padding: 0,
      marginBottom: theme.spacing(4),
      marginTop: -171,
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginTop: -60,
        maxWidth: '96%'
      }
    },
    mrAuto,
    mlAuto,
    carouselSection: {
      padding: 0
    },
    carouselImageContainer: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '24px !important'
      }
    },
    carouselData: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0 !important'
      }
    },
    carouselContainer: {
      display: 'flex',
      padding: 0,
      maxWidth: '100% !important',
      '& .slick-dots': {
        marginBottom: '12rem',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 'unset',
        height: '25px',
        padding: '0px 16px 30px 16px',
        borderRadius: '5px',
        background: 'rgba(255, 255, 255, 0.8)',
        [theme.breakpoints.down('xs')]: {
          marginBottom: '4rem'
        }
      },
      '& .slick-dots li button': {
        width: '10px',
        height: '10px',
        padding: 0
      },
      '& .slick-dots li button::before': {
        backgroundColor: theme.palette.primary.main
      },
      '& .slick-dots li button:focus::before': {
        border: '2px solid black'
      },
      '& .slick-dots li': {
        width: '10px',
        height: '10px',
        margin: '0 10px'
      }
    },
    slickDot: {
      minWidth: '8px',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    slickButton: {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      background: 'rgb(255 255 255 / 80%)',
      minWidth: '40px',
      padding: 0,
      left: '70px',
      [theme.breakpoints.down('xs')]: {
        left: '8px'
      }
    },
    slickButtonNext: {
      left: 'unset',
      right: '70px',
      [theme.breakpoints.down('xs')]: {
        right: '8px'
      }
    },
    carouselImage: {
      height: '585px',
      objectFit: 'cover',
      [theme.breakpoints.down('xs')]: {
        height: '400px'
      },
      opacity: isIE ? 0.1 : 1
    },
    carouselContent: {
      position: `absolute`,
      left: 0,
      right: 0,
      top: 0,
      bottom: 172,
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        bottom: 61
      }
    },
    carouselInnerContainer: {
      display: 'flex',
      flexGrow: 1,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    carouselGrid: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse'
      }
    },
    carouselText: {
      color: theme.palette.primary.main,
      overflowX: 'clip',
      overflowY: 'visible',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      [theme.breakpoints.down('xs')]: {
        '-webkit-line-clamp': 2,
        fontSize: '20px',
        fontFamily: 'Steagal',
        fontWeight: 'bold',
        lineHeight: '22px',
        letterSpacing: '1px',
        textTransform: 'uppercase'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        fontFamily: 'Steagal',
        fontWeight: 'bold',
        lineHeight: '22px',
        letterSpacing: '1px',
        textTransform: 'uppercase'
      },
      '-webkit-box-orient': 'vertical'
    },
    carouselDescription: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      '& p, h4, div': {
        margin: theme.spacing(),
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          textAlign: 'center',
          margin: theme.spacing() / 4
        }
      }
    },
    carouselDescriptionBtnView: {
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        '& button': {
          width: '100%'
        }
      }
    },
    carouselCardImage: {
      width: 350,
      height: 350,
      objectFit: 'cover',
      marginLeft: 'auto',
      borderRadius: '6px',
      [theme.breakpoints.down('md')]: {
        width: 280,
        height: 280
      },
      [theme.breakpoints.down('xs')]: {
        width: 160,
        height: 160,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    rightIcon: {
      marginLeft: theme.spacing()
    },
    marginAuto: {
      marginLeft: 'auto !important',
      marginRight: 'auto !important'
    },
    section: {
      padding: theme.spacing(2)
    },
    drawsSection: {},
    sectionList: {
      paddingTop: theme.spacing(3)
    },
    sectionCard: {
      padding: theme.spacing()
    },
    earnCard: {
      //  minHeight: '114px'
    },
    earnCardAction: {
      minHeight: '114px',
      display: 'flex'
    },
    earnCardContent: {
      display: 'flex'
    },
    earnCardContentText: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      paddingLeft: theme.spacing(),
      justifyContent: 'center'
    },
    earnCardIcon: {
      fontSize: '40px'
    },
    earnCardDescription: {
      color: theme.palette.primary.main
    },
    introSection: {
      padding: theme.spacing(3),
      paddingBottom: 0
    },
    introSectionTitle: {
      marginTop: theme.spacing() / 2,
      marginBottom: theme.spacing() / 2,
      color: theme.palette.primary.main
    },
    introSectionContent: {
      marginTop: theme.spacing() / 2,
      marginBottom: theme.spacing(2),
      color: theme.palette.text.primary
    },
    introSectionButtonContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3)
    },
    introSectionButton: {
      alignSelf: 'center'
    },
    drawMedia: {
      height: 260,
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        height: 216
      },
      [theme.breakpoints.down('md')]: {
        height: 328
      },
      [theme.breakpoints.down('xs')]: {
        height: 350
      }
    },
    luckLoungeMedia: {
      height: 450,
      display: 'flex',
      borderRadius: 4,
      cursor: 'pointer',
      marginRight: 8,
      marginLeft: 8,
      [theme.breakpoints.down('md')]: {
        height: 275
      },
      [theme.breakpoints.down('xs')]: {
        height: 350
      }
    },
    informationOnlyTileMedia: {
      cursor: 'pointer',
      paddingRight: 8,
      paddingLeft: 8,
      borderRadius: 4,
      minHeight: '104px',
      objectFit: 'contain'
    },
    cardContent: {
      minHeight: 89
    }
  });
