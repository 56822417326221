import { Grid, Hidden, IconButton, withStyles } from '@material-ui/core';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons';
import { WithStyles } from '@material-ui/styles';
import { IReward, RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import { Hideable } from '@pbl/pbl-react-web-components/lib/hideable';
import { assets, config, FeaturedCard, NoResultsFound } from '@pbl/pbl-react-web-components/lib/package';
import { Stack } from '@pbl/pbl-react-web-components/lib/stack';
import classNames from 'classnames';
import { NBSP } from 'config/constants';
import { useRewardListData } from 'hooks/providers/useRewardListData';
import RewardCard from 'modules/home/components/RewardCard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel, { Settings } from 'react-slick';
import { isEnded, isEndingSoon } from 'utils/rewardUtil';
import styles from './RewardSectionStyle';

interface Props extends WithStyles<typeof styles> {
  type: RewardType;
  onRewardSelect: (reward: IReward) => void;
}

const RewardSectionComponent = ({ type, onRewardSelect, classes }: Props) => {
  const { t } = useTranslation();
  const { rewards, featuredRewards, couponDetails, featuredDetails } = useRewardListData(type);

  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <IconButton className={classes.slickButton} onClick={onClick} aria-label="Previous Slider">
        <ArrowBackIosRounded color={'primary'} fontSize={'small'} />
      </IconButton>
    );
  };

  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <IconButton className={classNames(classes.slickButton, classes.slickButtonNext)} onClick={onClick} aria-label="Next Slider">
        <ArrowForwardIosRounded color={'primary'} fontSize={'small'} />
      </IconButton>
    );
  };

  const settings: Settings = {
    accessibility: true,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  const getBanner = (r: IReward, idx: number, featured: boolean) => {
    const showEndedBanner = isEnded(r.end);
    const showEndingSoonBanner = r.type === RewardType.DRAW && isEndingSoon(r.endingSoon, r.entryEnd);
    const showSoldOutBanner = featured
      ? featuredDetails[idx] && featuredDetails[idx]?.remaining === 0
      : couponDetails[idx] && couponDetails[idx]?.remaining === 0;
    return showEndedBanner
      ? { showBanner: true, bannerText: t(`reward.rewardStatus.ended`), styleType: 'GrayedOut' }
      : showEndingSoonBanner
      ? { showBanner: true, bannerText: t(`reward.rewardStatus.endingSoon`), styleType: 'WhiteHighlight' }
      : showSoldOutBanner
      ? { showBanner: true, bannerText: t('reward.soldOut'), styleType: 'GrayedOut' }
      : {};
  };

  const rewardList = React.useMemo(
    () =>
      rewards?.map((r, idx: number) => {
        const banner = getBanner(r, idx, false);
        const textDescription = r.summary?.trim() || NBSP;
        return (
          <Grid item={true} xs={12} sm={12} md={4} key={`reward_card_${idx}`}>
            <RewardCard reward={r} banner={banner} categoryName={type} preview={textDescription} />
          </Grid>
        );
      }),
    [rewards, type, couponDetails, t]
  );

  if (!featuredRewards.length && !rewards.length) {
    return <NoResultsFound title={t('rewards.noResults')} />;
  }

  return (
    <>
      <Hideable show={Boolean(featuredRewards?.length)}>
        <Carousel className={classes.carouselContainer} {...settings}>
          {featuredRewards?.map((fr, idx: number) => {
            const onSelect = () => onRewardSelect(fr);
            const banner = getBanner(fr, idx, true);

            return (
              <div key={`featured_reward_card_${idx}`}>
                <Hidden smDown={true}>
                  <Stack>
                    <FeaturedCard
                      onButtonClick={onSelect}
                      buttonText={t('rewards.redeem')}
                      image={fr.imageUrl}
                      imageTitle={`Featured Reward ${fr.title}`}
                      text={fr.title}
                      textDescription={fr.summary}
                      textFooter={t('rewards.redeemFor', { amount: fr.amount.toPointsFormatWithUnit() })}
                      iconFooter={config.internalConfig.REWARDS_ICON}
                      noImageAsset={assets.internalAssets.noFeaturedImg}
                      badgeText={banner.bannerText}
                      showBadge={banner.showBanner}
                      styleType={banner.styleType}
                      carousel={true}
                    />
                  </Stack>
                </Hidden>

                <Hidden mdUp={true}>
                  <FeaturedCard
                    onButtonClick={onSelect}
                    buttonText={t('rewards.redeem')}
                    image={fr.imageUrl}
                    imageTitle={`Featured Reward ${fr.title}`}
                    text={fr.title}
                    textDescription={fr.summary}
                    textFooter={t('rewards.redeemFor', { amount: fr.amount.toPointsFormatWithUnit() })}
                    iconFooter={config.internalConfig.REWARDS_ICON}
                    noImageAsset={assets.internalAssets.noFeaturedImg}
                    badgeText={banner.bannerText}
                    showBadge={banner.showBanner}
                    styleType={banner.styleType}
                    carousel={true}
                  />
                </Hidden>
              </div>
            );
          })}
        </Carousel>
      </Hideable>
      <Hideable show={Boolean(rewards?.length)}>
        <Hidden smDown={true}>
          <Grid container={true} spacing={4} style={{ marginTop: 45 }}>
            {rewardList}
          </Grid>
        </Hidden>
        <Hidden mdUp={true}>
          <Grid container={true} spacing={4}>
            {rewardList}
          </Grid>
        </Hidden>
      </Hideable>
    </>
  );
};

export const RewardSection: any = withStyles(styles)(RewardSectionComponent);
