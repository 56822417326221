import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { IReward, RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants, { NBSP } from 'config/constants';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isEnded, isEndingSoon } from 'utils/rewardUtil';
import RewardCard from './RewardCard';
import SectionHeading from './SectionHeading';

interface IBanner {
  showBanner?: boolean;
  bannerText?: string;
  styleType?: string;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  rewards: IReward[];
  banner?: IBanner;
  showSoldOutTag?: boolean;
}

const RewardsSection: React.FunctionComponent<IProps> = ({ classes, rewards, t, showSoldOutTag }) => {
  const rewardsToRender: IReward[] = rewards
    .filter(reward => reward.visibilityWeb)
    .filter((_1, index) => index < constants.HOME_SCREEN_REWARD_COUNT);

  return (
    <div className={classNames(classes.section)}>
      <SectionHeading
        title={t('home.rewardsSectionTitle')}
        linkRoute="/rewards"
        linkTitle={t('home.rewardsSectionLink')}
        linkLabel={t('home.rewardsSectionLinkLabel')}
      />
      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {rewardsToRender?.map(reward => {
          const preview = reward.summary?.trim() || NBSP;
          let banner = {};
          if (showSoldOutTag) {
            if (reward.type === RewardType.COUPON) {
              banner =
                reward?.remaining === 0
                  ? {
                      showBanner: true,
                      bannerText: t('reward.soldOut'),
                      styleType: 'GrayedOut'
                    }
                  : {};
            }
          }
          if (reward.type === RewardType.DRAW && isEnded(reward.end)) {
            banner = {
              showBanner: true,
              bannerText: t(`reward.rewardStatus.ended`),
              styleType: 'GrayedOut'
            };
          } else if (reward.type === RewardType.DRAW && isEndingSoon(reward.endingSoon, reward.entryEnd)) {
            banner = {
              showBanner: true,
              bannerText: t(`reward.rewardStatus.endingSoon`),
              styleType: 'WhiteHighlight'
            };
          }
          return (
            <Grid xs={12} md={4} item={true} className={classes.sectionCard} key={`reward_card_${reward.id}`}>
              <RewardCard reward={reward} banner={banner} categoryName={reward.type} preview={preview} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(RewardsSection));
