import DeviceRegistration from '@pbl/pbl-react-core/lib/device/DeviceRegistration';
import { DeviceService, SessionService } from '@pbl/pbl-react-core/lib/services';
import React from 'react';
import DeviceUtil from './utils/DeviceUtil';

type AppDeviceHandlerProps = {
  children?: React.ReactNode,
  onReady: () => any;
};

const AppDeviceHandler: React.FC<AppDeviceHandlerProps> = ({ children, onReady }) => {

  const createOrUpdateDevice = async () => {

    try {
      await SessionService.statusGet();
      await SessionService.statusPost();
    } catch (e) {
      console.warn(e);
    }

    const device = DeviceRegistration.device;
    if (!device) {
      return;
    }
    // update
    if (!!device.deviceKey) {
      try {
        await DeviceService.updateDevice(device);
      } catch (e) {
        console.warn(e);
      }
      return;
    }
    try {
      const response = await DeviceService.registerDevice(device);
      DeviceRegistration.device!!.deviceKey = response.deviceKey;
      DeviceUtil.setDeviceKey(response.deviceKey);
    } catch (e) {
      console.warn(e);
    }
  };

  const setup = async () => {
    try {
      DeviceUtil.getDeviceId();
      const deviceKey = DeviceUtil.getDeviceKey();
      DeviceRegistration.device = await DeviceUtil.buildDeviceMeta(deviceKey);
      await createOrUpdateDevice();
    } catch (e) {
      console.warn(e);
    }
    onReady();
  };

  React.useEffect(() => {
    setup().then();
  }, []);

  return (
    <>
      {children}
    </>
  );
};

export default AppDeviceHandler;
