const virtualTourConfiguration = [
  {
    id: 1,
    header: 'Welcome to Lucky Lounge!',
    body:
      'Lucky Lounge is a place to play and win online! Play digital games, earn tokens, then enter to win Lucky Lounge cash drawings.',
    image: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-1-desktop.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-1-tablet.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-1-mobile.png').default
  },
  {
    id: 2,
    header: 'Tokens Are Currency',
    body: `Buy tokens in the Lucky Lounge, earn tokens by playing games, or convert Players Club Points to tokens. Then start playing!`,
    image: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-2-desktop.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-2-tablet.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-2-mobile.png').default
  },
  {
    id: 3,
    header: 'Lucky Lounge Games',
    body: `Select a game to play in the Lucky Lounge. You can see how many tokens you can earn and how many tokens it takes to play.`,
    image: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-3-desktop.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-3-tablet.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-3-mobile.png').default
  },
  {
    id: 4,
    header: 'Sweepstakes Drawings',
    body: `Use tokens to enter weekly, bi-weekly, monthly, and quarterly Sweepstakes drawings for a chance to win cash prizes!`,
    image: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-4-desktop.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-4-tablet.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-4-mobile.png').default
  },
  {
    id: 5,
    header: 'Lucky Lounge History',
    body: `Your History shows all points exchanges, game plays, and drawings entered. Token balances appear on every page in Lucky Lounge. `,
    image: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-5-desktop.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-5-tablet.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/virtualTour/virtual-tour-slide-5-mobile.png').default,
    buttonText: 'Play'
  }
];

export default virtualTourConfiguration;
