import { RemoteConstants } from 'config/constants';
import colors from '../assets/jss/colors';

const displayGreetingsMessage = async () => {
  let showFirstName = true;
  let message = '';
  let fontColor = colors.themeColors.secondaryColor.hex();
  let fontSize = 24;
  let fontFamily = 'Steagal-Me';
  await RemoteConstants.sync();
  const GREETINGS_MESSAGE_CONFIG = RemoteConstants.getString('GREETINGS_MESSAGE_CONFIG');

  if (!!GREETINGS_MESSAGE_CONFIG) {
    const greetingsMessageConfig = JSON.parse(GREETINGS_MESSAGE_CONFIG);
    showFirstName = greetingsMessageConfig.showFirstName;
    if (greetingsMessageConfig.fontColor) {
      fontColor = greetingsMessageConfig.fontColor;
    }
    if (greetingsMessageConfig.fontSize) {
      fontSize = greetingsMessageConfig.fontSize;
    }
    if (greetingsMessageConfig.fontFamily) {
      fontFamily = greetingsMessageConfig.fontFamily;
    }
    if (greetingsMessageConfig.type?.toString().toLowerCase() === 'static') {
      message = greetingsMessageConfig.message;
    }
  }
  if (!message) {
    const myDate = new Date();
    const hours = myDate.getHours();
    message = 'Good ';
    if (hours < 12) {
      message = message + 'Morning ';
    } else if (hours >= 12 && hours <= 17) {
      message = message + 'Afternoon ';
    } else if (hours >= 17 && hours <= 24) {
      message = message + 'Evening ';
    }
  }
  return { message, showFirstName, fontColor, fontSize, fontFamily };
};

export default displayGreetingsMessage;
