import { UploadService } from '@pbl/pbl-react-core/lib/services';

export const uploadContent = async (activityId: number, file: File, cancelToken: any, onUploadProgress: (progressEvent: any) => void) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    return await UploadService.uploadFile(activityId, formData, cancelToken, onUploadProgress);
  } catch (e: any) {
    console.error(e);
    return e?.payload;
  }
};

export const getContentConfig = async (key: string) => {
  try {
    return await UploadService.getContent(key);
  } catch (e) {
    console.error(e);
  }
};
