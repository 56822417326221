import { IDispatchAction } from 'redux/reducers';
import {
  DEFAULT_ACTIVITIES_PER_PAGE,
  DEFAULT_PAGE,
  DEFAULT_REWARDS_PER_PAGE,
  HISTORY_PAGE_SIZE,
  HISTORY_RESET_PAGING,
  HISTORY_RESET_PAGING_ALL
} from 'redux/reducers/history/pagination/actions';

interface IPaging {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
}

export interface IHistoryPaginationState {
  activities: IPaging;
  rewards: IPaging;
  pastActivities: IPaging;
  pastRewards: IPaging;
}

const INITIAL_STATE: IHistoryPaginationState = {
  activities: {
    pageSize: DEFAULT_ACTIVITIES_PER_PAGE,
    pageNumber: DEFAULT_PAGE,
    totalPages: 0
  },
  rewards: {
    pageSize: DEFAULT_REWARDS_PER_PAGE,
    pageNumber: DEFAULT_PAGE,
    totalPages: 0
  },
  pastActivities: {
    pageSize: DEFAULT_ACTIVITIES_PER_PAGE,
    pageNumber: DEFAULT_PAGE,
    totalPages: 0
  },
  pastRewards: {
    pageSize: DEFAULT_REWARDS_PER_PAGE,
    pageNumber: DEFAULT_PAGE,
    totalPages: 0
  }
};

declare type HistoryPaginationState = Readonly<IHistoryPaginationState>;

export default (state: HistoryPaginationState = INITIAL_STATE, action: IDispatchAction) => {
  switch (action.type) {
    case HISTORY_PAGE_SIZE:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          ...action.payload
        }
      };
    case HISTORY_RESET_PAGING_ALL:
      return {
        ...INITIAL_STATE
      };
    case HISTORY_RESET_PAGING:
      return {
        ...state,
        [action.payload]: {
          ...INITIAL_STATE[action.payload]
        }
      };
    default:
      return state;
  }
};
