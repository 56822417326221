import { AppBar, Hidden, Link, Toolbar } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import logo from 'assets/img/ks_logo_header.png';
import styles from 'assets/jss/shared/components/menu/AppBarStyle';
import color from 'color';
import mainMenu from 'config/menu';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UserPointBalance from 'shared/components/menu/UserPointBalance';
import MessageBar from 'shared/components/message-bar/MessageBar';
import getTheme from 'shared/theme/custom';
import MainMenu from './MainMenu';
import PopupMenu from './PopupMenu';

interface IPrimaryAppBarProps {
  isAuthenticated: boolean;
  checkLogin?: any;
  userPoints: number;
  expiryAmount?: number;
  userFullName?: string;
  onLogout: () => any;
  showMessage: boolean;
  loadingFlags: boolean;
  authorizedFlags: string[];
  storeItems: number;
}

interface IProps extends IPrimaryAppBarProps, WithStyles<typeof styles>, RouteComponentProps {}

const PrimaryAppBar: React.FunctionComponent<IProps> = ({
  classes,
  isAuthenticated,
  location,
  history,
  userPoints,
  expiryAmount,
  userFullName,
  onLogout,
  showMessage,
  authorizedFlags,
  loadingFlags,
  storeItems
}) => {
  const menu = {
    profile: mainMenu.profile.map(x => {
      if (x.key === 'profile') {
        x.title = userFullName || '';
      } else if (x.key === 'sign-out') {
        x.onClick = onLogout;
      }

      x.isActive = x.route === location.pathname;
      return x;
    }),
    sideMenu: mainMenu.sideMenu.map(x => {
      x.isActive = x.route === location.pathname;
      return x;
    }),
    mobileSideMenu: mainMenu.mobileSideMenu.map(x => {
      x.isActive = !!x.route && location.pathname.includes(x.route);
      return x;
    }),
    mainMenu: mainMenu.mainMenu.map(x => {
      x.isActive = !!x.route && location.pathname.includes(x.route);
      return x;
    })
  };
  const renderMenuItems = (): React.ReactNode => {
    return (
      <MainMenu isAuthenticated={isAuthenticated} mainMenu={menu.mainMenu} authorizedFlags={authorizedFlags} storeItems={storeItems} />
    );
  };

  const renderSideMenuItems = (): React.ReactNode => {
    return (
      <React.Fragment>
        <Hidden smDown={true}>
          <PopupMenu
            title="Side Menu"
            icon="icon-menu"
            menuName="side-menu"
            isAuthenticated={isAuthenticated}
            popupMenu={menu.sideMenu}
            authorizedFlags={authorizedFlags}
          />
        </Hidden>
        <Hidden mdUp={true}>
          <PopupMenu
            authorizedFlags={authorizedFlags}
            title="Side Menu"
            icon="icon-menu"
            menuName="side-menu"
            isAuthenticated={isAuthenticated}
            popupMenu={menu.mobileSideMenu}
          />
        </Hidden>
      </React.Fragment>
    );
  };

  const navigateToHome = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/');
  };

  const renderProfileMenuItems = (): React.ReactNode => {
    return (
      <PopupMenu
        title="Profile Menu"
        icon="icon-user"
        menuName="profile-menu"
        isAuthenticated={isAuthenticated}
        popupMenu={menu.profile}
        authorizedFlags={authorizedFlags}
      />
    );
  };
  const isLuckyLounge = window.location.pathname.includes('lucky-lounge');
  const theme = isLuckyLounge ? getTheme('sweepstakesTheme') : getTheme();
  const backgroundColor = isLuckyLounge ? theme.palette.primary.dark : theme.palette.primary.main;
  const profileBackground = isAuthenticated ? 'unset' : isLuckyLounge ? color(backgroundColor).darken(0.4).rgb().string() : '#2c408c';
  return (
    <div className={classes.root}>
      <AppBar role="navigation" position="sticky" style={{ background: backgroundColor }} className={classes.header}>
        <Toolbar className={classes.toolBar}>
          <a role="main" className={classes.skipLink} href="#main" aria-label="Skip to main content" tabIndex={0}>
            Skip to main content
          </a>

          <div className={classes.innerWrapper}>
            {renderSideMenuItems()}

            <Link href="/" onClick={navigateToHome} title="Go to HOME Page" aria-label="Go to HOME Page" component={'button'}>
              <img alt={'Kansas Lottery Logo'} className={classes.logo} src={logo} />
            </Link>
            <div className={classes.grow} />
            {loadingFlags ? null : renderMenuItems()}

            <div className={classes.sectionDesktop} style={{ backgroundColor: profileBackground }}>
              {renderProfileMenuItems()}
            </div>
            <div className={classes.sectionMobile}>{renderProfileMenuItems()}</div>
          </div>
        </Toolbar>
      </AppBar>
      {isAuthenticated ? (
        <UserPointBalance points={userPoints} expiryAmount={!!expiryAmount ? expiryAmount : 0} isHomeScreen={location.pathname === '/'} />
      ) : null}
      {showMessage ? <MessageBar /> : null}
    </div>
  );
};

export default withStyles(styles)(withRouter(PrimaryAppBar));
