import * as yup from 'yup';

export const loginEmailSchema = yup
  .string()
  .required()
  .email();
export const loginPasswordSchema = yup.string().required();
export const loginRememberMeSchema = yup.boolean().notRequired();
export const loginConfirmStateOfArizonaSchema = yup.boolean().oneOf([true]);

export const loginSchema = yup.object().shape({
  email: loginEmailSchema,
  password: loginPasswordSchema,
  rememberMe: loginRememberMeSchema,
  confirmStateOfArizona: loginConfirmStateOfArizonaSchema
});
