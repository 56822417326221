import DeviceRegistration from '@pbl/pbl-react-core/lib/device/DeviceRegistration';
import { DeviceService } from '@pbl/pbl-react-core/lib/services';
import { FC, useEffect } from 'react';

export interface IDeviceLinkHandlerProps {
  isLoggedIn: boolean;
}

const DeviceLinkHandler: FC<IDeviceLinkHandlerProps> = ({isLoggedIn}) => {

  useEffect(() => {
    if(isLoggedIn){
      const device = DeviceRegistration.device
      if(!!device && !!device.deviceKey && !!device.deviceId) {
        DeviceService.linkDevice({
          deviceId: device.deviceId,
          deviceKey: device.deviceKey
        }).then().catch(e => console.warn(e));
      }
    }
  }, [isLoggedIn])

  return null
}

export default DeviceLinkHandler;
