import { GET_PAST_HISTORY_ACTIVITIES, GET_PAST_HISTORY_REWARDS, IKSLegacyState, KSLegacyStateType } from '@pbl/pbl-react-core/lib/models/kslegacy/types';
import { IDispatchAction } from '../index';

const INITIAL_STATE: IKSLegacyState = {
  pastHistoryRewards: [],
  pastHistoryActivities: []
};

export default (state: IKSLegacyState = INITIAL_STATE, action: IDispatchAction): KSLegacyStateType => {
  switch (action.type) {
    case GET_PAST_HISTORY_REWARDS:
      return {
        ...state,
        pastHistoryRewards: action.payload
      };
    case GET_PAST_HISTORY_ACTIVITIES:
      return {
        ...state,
        pastHistoryActivities: action.payload
      };
    default:
      return state;
  }
};
