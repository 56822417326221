import { MuiThemeProvider, withStyles, WithStyles } from '@material-ui/core/styles';
import { nowUtc } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import styles from 'assets/jss/modules/app/AppLayoutStyle';
import DeviceLinkHandler from 'components/Device/DeviceLinkHandler';
import constants, { RemoteConstants } from 'config/constants';
import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';
import _ from 'lodash';
import OutageScreen from 'modules/outage/outage-screen';
import moment from 'moment-timezone';
import React, { Fragment } from 'react';
import Confetti from 'react-confetti';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { getAllCountries } from 'redux/reducers/address/actions';
import { clearMessages } from 'redux/reducers/app/actions';
import { isAuthenticated, loginWithToken, logout } from 'redux/reducers/authentication/actions';
import { displayConfetti } from 'redux/reducers/confetti/actions';
import { getFeatureFlags } from 'redux/reducers/feature/actions';
import { fetchUserPointsWithExpiry } from 'redux/reducers/ledger/actions';
import { loadStorage } from 'redux/reducers/store/actions';
import indexRoutes from 'routes';
import Footer from 'shared/components/footer/Footer';
import AppBar from 'shared/components/menu/AppBar';
import CustomRoute from 'shared/components/routes/CustomRoute';
import getTheme from 'shared/theme/custom';
import { AuthService } from 'utils/auth-service';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
interface IAppLayoutState {
  showOutageBanner: boolean;
  outageConfig: {
    outageType?: 'PLANNED' | 'UNPLANNED';
    flag?: boolean;
    body?: string;
  };
}
interface IProps extends StateProps, RouteComponentProps, WithStyles<typeof styles>, DispatchProps {}
class AppLayout extends React.Component<IProps, IAppLayoutState> {
  private outageCheck: any | null = null;
  constructor(props) {
    super(props);
    this.state = {
      showOutageBanner: false,
      outageConfig: {
        flag: false,
        body: undefined,
        outageType: undefined
      }
    };
  }
  public static onRouteWillChange() {
    document.title = constants.DEFAULT_PAGE_TITLE;
  }
  public async componentWillUpdate(nextProps: Readonly<IProps>): Promise<void> {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      AppLayout.onRouteWillChange();

      this.props.clearMessages();
      await this.getData();
    }
  }
  public async componentDidUpdate(previousProps: Readonly<IProps>, _2: Readonly<{}>, _3?: any): Promise<void> {
    if (this.props.location.pathname !== previousProps.location.pathname) {
      const payload = await AuthService.checkSession();
      const useLocalStorageForLogin = firebase.remoteConfig().getBoolean(`USE_LOCAL_STORAGE_TO_LOGIN`);
      if (!!useLocalStorageForLogin && payload) {
        this.triggerLogin(payload);
      }
      await ReactGA.pageview(`${this.props.location.pathname}`);
    }
  }
  public updateOutageStatus = async () => {
    await RemoteConstants.sync();
    const outageConfigString = RemoteConstants.getString('OUTAGE_CONFIG');
    let showOutage = false;
    const outageConfig = JSON.parse(outageConfigString);

    if (outageConfig?.flag !== 'true') {
      this.setState({
        showOutageBanner: false,
        outageConfig: {
          flag: false,
          body: undefined,
          outageType: undefined
        }
      });
      return;
    }

    const now = nowUtc().unix();
    const startDate = moment.utc(outageConfig.startDate).unix();
    const isValidStartDate = now >= startDate;

    if (isValidStartDate) {
      if (!!outageConfig.endDate) {
        const endDate = moment.utc(outageConfig.endDate).unix();
        const isValidEndDate = now <= endDate;
        if (isValidEndDate) {
          showOutage = true;
        }
      } else {
        showOutage = true;
      }
    }
    this.setState({
      showOutageBanner: showOutage,
      outageConfig
    });
  };
  public async componentDidMount() {
    const useLocalStorageForLogin = RemoteConstants.getBoolean(`USE_LOCAL_STORAGE_TO_LOGIN`);
    this.outageCheck = setInterval(this.updateOutageStatus, 300000);
    const payload = await AuthService.checkSession();
    if (!!useLocalStorageForLogin && payload) {
      this.triggerLogin(payload);
    }
    await this.getData();
    await this.updateOutageStatus();
    await this.props.getAllCountries();
    await ReactGA.pageview(`${this.props.location.pathname}`);
  }
  public componentWillUnmount = async () => {
    if (!!this.outageCheck) {
      clearInterval(this.outageCheck);
    }
    this.outageCheck = null;
  };
  public triggerLogin = (payload: { accessToken: string; refreshToken?: string; userId: string }) => {
    const isValidPayload =
      !!payload && !!payload.accessToken?.trim().length && !!payload.accessToken?.trim().length && !!payload.userId?.trim().length;
    if (isValidPayload && (!this.props.isLoggedIn || payload.userId !== this.props.userId)) {
      this.props.loginWithToken(
        payload.accessToken,
        payload.userId,
        payload.refreshToken,
        () => {},
        () => {}
      );
    }
  };
  public getData = async (): Promise<void> => {
    await this.props.getFeatureFlags();
    await this.props.loadStorage();
    await this.props.isAuthenticated();
    if (this.props.isLoggedIn) {
      await this.props.fetchUserPointsWithExpiry();
      // exponea
      // @ts-ignore
      if (window.exponea && this.props.userId) {
        // @ts-ignore
        window.exponea.identify({ user_key: this.props.userId }, this.props.email ? { email: this.props.email } : null);
      }
    }
  };
  public render(): React.ReactNode {
    const { isLoggedIn, userPoints, expiryAmount, userFullName, authorizedFlags, loadingFlags, storeItems } = this.props;
    const theme = window.location.pathname.includes('lucky-lounge') ? getTheme('sweepstakesTheme') : getTheme();
    // @ts-ignore
    const imageStyles =
      // @ts-ignore
      theme.palette.background.image &&
      !window.location.pathname.includes('lucky-lounge/game') &&
      !window.location.pathname.includes('lucky-lounge/explore')
        ? {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            // @ts-ignore
            backgroundImage: `url(${theme.palette.background.image})`
          }
        : {};
    const widget = window.location.pathname.includes('games-quick-draw-widget') || window.location.pathname.includes('store/pay');
    if (widget) {
      return (
        <Fragment>
          <Switch>
            {indexRoutes.map((prop, key) => (
              <CustomRoute key={key} {...prop} />
            ))}
          </Switch>
        </Fragment>
      );
    }
    if (this.state.showOutageBanner) {
      return (
        <Fragment>
          <OutageScreen
            outageType={this.state.outageConfig.outageType as 'PLANNED' | 'UNPLANNED'}
            flag={this.state.showOutageBanner}
            body={this.state.outageConfig.body as string}
          />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <AppBar
          isAuthenticated={isLoggedIn}
          userPoints={userPoints}
          expiryAmount={expiryAmount}
          userFullName={userFullName}
          onLogout={this.onLogout}
          showMessage={!!this.props.showMessage}
          authorizedFlags={authorizedFlags}
          loadingFlags={loadingFlags}
          storeItems={storeItems}
        />
        {this.props.confetti.showConfetti && (
          <Confetti
            style={{ zIndex: 1301 }}
            tweenDuration={3000}
            numberOfPieces={1500}
            width={window.innerWidth}
            height={document.body.scrollHeight}
          />
        )}
        <DeviceLinkHandler isLoggedIn={this.props.isLoggedIn} />
        <MuiThemeProvider theme={theme}>
          <div
            id="pageScrollTarget"
            className={this.props.classes.root}
            style={{ background: theme.palette.background.default, ...imageStyles }}
          >
            <section className={this.props.classes.page} id={'main'} tabIndex={-1}>
              <Switch>
                {indexRoutes.map((prop, key) => (
                  <CustomRoute key={key} {...prop} />
                ))}
              </Switch>
            </section>
            <Footer isAuthenticated={isLoggedIn} />
          </div>
        </MuiThemeProvider>
        {this.props.spinning && <AppSpinner label={this.props.spinnerLabel} />}
      </Fragment>
    );
  }
  private onLogout = () => {
    this.props.logout();
    this.props.history.push('/');
  };
}
const mapStateToProps = ({
  app: { showMessage, spinning, label },
  ledger: { userBalance },
  authentication: { accessToken, account },
  feature: { authorizedFlags, loading },
  store: { selectedItems },
  confetti
}: IRootState) => ({
  showMessage,
  spinning,
  spinnerLabel: label,
  userPoints: userBalance?.balance,
  expiryAmount: userBalance?.expiryAmount,
  userFullName: account ? `${account.firstName} ${account.lastName}` : '',
  email: account ? account.email : undefined,
  userId: account?.login as string | undefined,
  isLoggedIn: Boolean(accessToken?.length && account && account.email),
  authorizedFlags,
  loadingFlags: loading,
  storeItems: _.sumBy(selectedItems, 'quantity'),
  confetti
});
const mapDispatchToProps = {
  fetchUserPointsWithExpiry,
  loginWithToken,
  logout,
  isAuthenticated,
  getAllCountries,
  getFeatureFlags,
  loadStorage,
  displayConfetti,
  clearMessages
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: false }
    // @ts-ignore
  )(withStyles(styles)(AppLayout))
);
