import constants from 'config/constants';
import parser from 'ua-parser-js';

const TIMEOUT = 60 * 1000;

const apiConfiguration = {
  baseURL: constants.BASE_URL,
  timeout: TIMEOUT,
  withCredentials: true
};

const addRequestTransform = (request: any) => {
  request.headers['X-XSRF-TOKEN'] = localStorage.getItem('XSRF-TOKEN');
  const userAgent = parser(navigator.userAgent);
  request.headers['client-platform'] = 'web app';
  request.headers['client-os'] = !!userAgent.os ? userAgent.os.name : '';
  request.headers['client-os-version'] = !!userAgent.os ? userAgent.os.version : '';
  request.headers['browser'] = !!userAgent.browser ? userAgent.browser.name : '';
  request.headers['browser-version'] = !!userAgent.browser ? userAgent.browser.version : '';
};

const addResponseTransform = (response: any) => {
  if (
    !!response &&
    !!response.headers &&
    (response.headers.hasOwnProperty('x-xsrf-token') || response.headers.hasOwnProperty('X-XSRF-TOKEN'))
  ) {
    const xsrf = response.headers['x-xsrf-token'] || response.headers['X-XSRF-TOKEN'];
    if (xsrf) {
      localStorage.setItem('XSRF-TOKEN', xsrf);
    }
  }
};

export { apiConfiguration, addRequestTransform, addResponseTransform };
