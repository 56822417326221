import { createStyles } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = () =>
  createStyles({
    root: {
      ...container,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginTop: '16px'
    }
  });

export default styles;
