import { createStyles, Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { formatMonthDayCommaYear } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { ICornerIconProps } from '@pbl/pbl-react-web-components/lib/card/CornerIcon';
import { assets } from '@pbl/pbl-react-web-components/lib/config/assets';
import NoResultsFound from '@pbl/pbl-react-web-components/lib/no-results/NoResultsFound';
import { ContentLoader, ListCard } from '@pbl/pbl-react-web-components/lib/package';
import { container } from 'assets/jss/material-kit-pro-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { getAllInformationOnlyTiles } from 'redux/reducers/information-only-tile/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import SectionHeading from '../home/components/SectionHeading';

const styles = (theme: Theme) =>
  createStyles({
    informationContainer: {
      ...container,
      display: 'flex',
      flexGrow: 1
    },
    informationTileContainer: {
      width: '100%',
      margin: theme.spacing(2)
    },
    informationHeader: {
      display: 'flex',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  });

const useStyles = makeStyles(styles);

const InformationOnlyListScreen: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const informationOnlyTileObj = useSelector((state: IRootState) => state.informationOnlyTile);

  React.useEffect(() => {
    dispatch(getAllInformationOnlyTiles());
  }, [dispatch]);

  function viewDetails(id: number) {
    return () => {
      history.push(`/information-list/${id}`);
    };
  }

  const cornerIcon: ICornerIconProps = {
    icon: undefined,
    trianglePosition: { top: 0, left: 0 },
    triangleType: 'topLeft',
    triangleBackgroundColor: theme.palette.primary.main,
    triangleSize: 88,
    contentColor: ''
  };
  cornerIcon.image = assets.internalAssets.starImg;

  return (
    <Grid className={classes.informationContainer} direction="column">
      <ScrollToTopOnMount />
      <Grid className={classes.informationHeader}>
        <SectionHeading title={t('home.informationTitle')} linkRoute="/information-list" color="rgba(0, 0, 0, 0.82)" />
      </Grid>

      {informationOnlyTileObj?.loading && informationOnlyTileObj?.informationOnlyTiles?.length < 1 && <ContentLoader />}
      {!informationOnlyTileObj?.loading && informationOnlyTileObj?.informationOnlyTiles?.length < 1 && (
        <NoResultsFound title={t('informationOnlyTile.noResults')} />
      )}
      <Grid container={true} spacing={4}>
        {informationOnlyTileObj.informationOnlyTiles.map(informationTile => (
          <Grid key={informationTile.id} item={true} spacing={1} xs={12} sm={6} md={4}>
            <ListCard
              image={informationTile.webImageUrl}
              text={informationTile.title}
              onClick={viewDetails(informationTile.id)}
              cornerIcon={cornerIcon}
              titleColor={'textSecondary'}
              title={informationTile.startDate ? formatMonthDayCommaYear(informationTile.startDate.toString()) : 'TBD'}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default InformationOnlyListScreen;
