import { CardMedia, Grid, Hidden, WithStyles, withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SectionHeading from './SectionHeading';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  onCardClicked: any;
}

const InformationOnlySection: React.FunctionComponent<IProps> = ({ classes, onCardClicked, t }) => (
  <Grid container={true} className={classNames(classes.section)}>
    <Grid container={true} direction="row" alignItems="center">
      <Grid item={true}>
        <SectionHeading title={t('home.informationTitle')} linkRoute="/information-list" />
      </Grid>
    </Grid>
    <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
      <Grid item={true} xs={12}>
        <Hidden xsDown={true}>
          <Link tabIndex={0} component="button" onClick={onCardClicked}>
            <CardMedia
              component="img"
              image={constants.INFORMATION_ONLY_WEB_IMAGE}
              className={classes.informationOnlyTileMedia}
              alt="Explore all special offers"
            />
          </Link>
        </Hidden>
        <Hidden smUp={true}>
          <Link tabIndex={0} component="button" onClick={onCardClicked}>
            <CardMedia
              component="img"
              image={constants.INFORMATION_ONLY_MOBILE_IMAGE}
              className={classes.informationOnlyTileMedia}
              alt="Explore all special offers"
            />
          </Link>
        </Hidden>
      </Grid>
    </Grid>
  </Grid>
);

export default withTranslation()(withStyles(styles)(InformationOnlySection));
