import {
  CHECK_EMAIL,
  ERROR_RESET,
  IS_VALID_INVITE_CODE,
  IUserRegistrationData,
  REGISTER_PLAYER,
  RESET,
  RESET_ADDITIONAL_INFORMATION,
  StepperSteps,
  UPDATE_INVITE_CODE_VISIBILITY,
  UPDATE_SHOW_CAPTCHA,
  UPDATE_STEPPER,
  UPDATE_USER_REGISTRATION_DATA_FIELDS,
  UserRegistrationDataFields
} from '@pbl/pbl-react-core/lib/models/user-registration/types';
import { RegistrationService } from '@pbl/pbl-react-core/lib/services';
import constants from 'config/constants';
import { preferences } from 'config/registration';
import { showMessageBar } from 'redux/reducers/app/actions';
import { DispatchMethod } from '..';

export const resetError = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: ERROR_RESET,
    payload: null
  });
};

export const updateStepper = (index: StepperSteps) => (dispatch: DispatchMethod) => {
  dispatch({
    type: UPDATE_STEPPER,
    payload: index
  });
};

export const updateUserRegistrationField = (fieldName: UserRegistrationDataFields, value: any) => async (dispatch: DispatchMethod) => {
  dispatch({
    type: UPDATE_USER_REGISTRATION_DATA_FIELDS,
    payload: { fieldName, value }
  });

  if (fieldName === 'inviteCode') {
    try {
      const checkInviteCodeResponse = await RegistrationService.checkInviteCode(String(value));

      dispatch({
        type: IS_VALID_INVITE_CODE,
        payload: checkInviteCodeResponse === null ? '' : checkInviteCodeResponse.payload.errorKey
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const registerPlayer =
  (userRegistrationData: IUserRegistrationData, authenticationV2: Boolean) => async (dispatch: DispatchMethod) => {
    try {
      const userRegistration = userRegistrationData.sendUpdates
        ? userRegistrationData.updateField('preferences', preferences)
        : userRegistrationData;
      await dispatch({
        type: REGISTER_PLAYER,
        payload: authenticationV2
          ? RegistrationService.registerV2(userRegistration, constants.LOTTERY_ID)
          : RegistrationService.register(userRegistration, constants.LOTTERY_ID)
      });
    } catch (e) {
      console.error(e);
    }
  };

export const checkIfEmailInUse = (userRegistrationData: IUserRegistrationData) => async (dispatch: any) => {
  try {
    await dispatch({
      type: CHECK_EMAIL,
      payload: RegistrationService.checkEmail(userRegistrationData.email)
    });
  } catch (e) {
    await dispatch(showMessageBar({ message: 'General error has occurred, please try again.', type: 'error', messageTimeout: 10000 }));
  }
};

export const reset = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: RESET
  });
};

export const updateInviteCodeVisibility = (visible: boolean) => (dispatch: DispatchMethod) => {
  dispatch({
    type: UPDATE_INVITE_CODE_VISIBILITY,
    payload: visible
  });
};

export const updateCaptchaVisibility = (visible: boolean) => (dispatch: DispatchMethod) => {
  dispatch({
    type: UPDATE_SHOW_CAPTCHA,
    payload: visible
  });
};

export const resetAdditionalInformation = () => async (dispatch: DispatchMethod) => {
  await dispatch({
    type: RESET_ADDITIONAL_INFORMATION
  });
};
