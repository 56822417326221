import { IResponse, ISurveyActivity } from '@pbl/pbl-react-core/lib/models/survey/pbl/types';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { getPlayerResponses, getSurvey } from 'redux/reducers/survey/action';
import { useIsLoggedIn } from '../../../hooks/useIsLoggedIn';

export const useSurveyDetails = (onError: () => void, activityKey?: string) => {
  const isLoggedIn = useIsLoggedIn();
  const [survey, setSurvey] = useState<ISurveyActivity>();
  const [playerResponses, setPlayerResponses] = useState<IResponse[]>();

  const fetchData = useCallback(async () => {
    if (activityKey) {
      const data = await getSurvey(activityKey);
      if (!data) {
        onError();
        return;
      }
      if (isLoggedIn) {
        const _responses = await getPlayerResponses(data.id);
        const responses: IResponse[] = [];
        if (_responses && _responses.length > 0) {
          _.forEach(_responses, item => {
            const found = responses.find(response => response.questionId === item.questionId);
            if (found) {
              found.options?.push(item.optionId as number);
            } else {
              responses.push({ questionId: item.questionId, responseText: item.responseText, options: [item.optionId] });
            }
          });
        }
        setPlayerResponses(responses);
      }

      setSurvey(data);
    }
  }, [activityKey, isLoggedIn, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { survey, playerResponses, fetchData };
};
